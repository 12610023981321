import Page from '../../../../components/Page';
import { Box, FormControlLabel, Grid, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import DownloadButton from '../../../../components/DownloadButton';
import WOHeaderTable from './WOHeaderTable';
import WOActionButton from './WOActionButton';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import WODetailsModal from '../../components/WODetailsModal';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { useMemo } from 'react';
import {
  getUnitOptions,
  getWorkorderOptions,
  getPriorityOptions,
  getStatusOptions,
  getWorkGroupOptions,
  getScheduleBacklogOptions,
  getPlanningCtrOptions,
  getSpecificationsOptions,
  getWorkTypeOptions,
  getOnlineStartOptions,
  getLocationOptions,
  getWorkorderFlagOptions,
} from './utils';
import { ScheduleContextProvider } from '../../hooks/useSchedule';
import { BookmarkMenu } from '../../bookmarks/BookmarkMenu';
import { FilterEntityType } from '../../bookmarks/hook/useBookmarks';
import isFeatureEnabled from '../../../../utils/feature-flags';
import AutocompleteFilterInput from '../../components/AutocompleteFilterInput';

const ReadySwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: green;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: green;
  }
`;
const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;
const WOHeaderPage = () => {
  const {
    workorders,
    selectedWorkorderId,
    woloading,
    exportCSV,
    filters,
    setFilters,
    filteredWorkorders,
    fetchWorkorders,
    getWorkorderById,
  } = useWorkOrders();
  const disableButtons = selectedWorkorderId === undefined;
  const backlogCount = filteredWorkorders.length;
  return (
    <Page title="WO Header">
      <WODetailsModalContextProvider workorder={getWorkorderById(selectedWorkorderId)}>
        <Box
          display="flex"
          justifyContent={'space-between'}
          marginBottom="20px"
          marginTop="10px"
          marginLeft="10px"
        >
          <Box
            width="20%"
            height="100px"
            minWidth={'120px'}
            borderRadius="5px"
            textAlign="center"
            boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
            marginRight="10px"
          >
            <Typography style={{ fontWeight: '500', fontSize: '45px' }}>{woloading ? '...' : backlogCount}</Typography>
            <Typography style={{ fontSize: '12px' }}>Current Backlog</Typography>
          </Box>
          <Box>
            <Box>
              <WOActionButton
                text="Overview"
                openTab="overview"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Assessing"
                openTab="assessing"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Supply Chain"
                openTab="sc"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Engineering"
                openTab="eng"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Actions"
                openTab="actions"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Notes"
                openTab="notes"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Schedule"
                openTab="schedule"
                disabled={disableButtons}
              />
              <WOActionButton
                text="Turnover"
                openTab="turnover"
                disabled={disableButtons}
              />
            </Box>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '10px', paddingRight: '15px' }}
        >
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Work Order"
              options={useMemo(() => getWorkorderOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, wonum: value });
              }}
              value={filters.wonum}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Unit"
              options={useMemo(() => getUnitOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, unit: value });
              }}
              value={filters.unit}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Priority"
              options={useMemo(() => getPriorityOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, wopriority: value });
              }}
              value={filters.wopriority}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Work Type"
              options={useMemo(() => getWorkTypeOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, worktype: value });
              }}
              value={filters.worktype}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Status"
              options={useMemo(() => getStatusOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, status: value });
              }}
              value={filters.status}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Lead Crew"
              options={useMemo(() => getWorkGroupOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, crewworkgroup: value });
              }}
              value={filters.crewworkgroup}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Schedule Backlog"
              options={useMemo(() => getScheduleBacklogOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpschedulebacklog: value });
              }}
              value={filters.bpschedulebacklog}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Planning Center"
              options={useMemo(() => getPlanningCtrOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpplanningctr: value });
              }}
              value={filters.bpplanningctr}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label={isFeatureEnabled('workorderFlags') ? 'Specifications' : 'Flags'}
              options={useMemo(() => getSpecificationsOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, specifications: value });
              }}
              value={filters.specifications}
              multiple
              selectAll
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Online Start"
              options={useMemo(
                () => getOnlineStartOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                [workorders]
              )}
              onChange={value => {
                setFilters({ ...filters, schedstart: value });
              }}
              value={filters.schedstart}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteFilterInput
              label="Location"
              options={useMemo(
                () => getLocationOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                [workorders]
              )}
              onChange={value => {
                setFilters({ ...filters, location: value });
              }}
              value={filters.location}
              multiple
              allowInvalidValuesInOptions
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            {isFeatureEnabled('workorderFlags') && (
              <AutocompleteFilterInput
                label="Flags"
                options={useMemo(() => getWorkorderFlagOptions(workorders), [workorders])}
                onChange={value => {
                  setFilters({ ...filters, workorderFlags: value });
                }}
                value={filters.workorderFlags}
                multiple
                allowInvalidValuesInOptions
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <ReadySwitch
                  checked={filters.readyOnly}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      waitingAssessing: !e.target.checked ? filters.waitingAssessing : false,
                      waitingEng: !e.target.checked ? filters.waitingEng : false,
                      waitingSc: !e.target.checked ? filters.waitingSc : false,
                      waitingOps: !e.target.checked ? filters.waitingOps : false,
                      waitingOther: !e.target.checked ? filters.waitingOther : false,
                      readyOnly: e.target.checked,
                    });
                  }}
                  name="readyOnly"
                />
              }
              label="Ready Only"
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.waitingAssessing}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      readyOnly: !e.target.checked ? filters.readyOnly : false,
                      waitingAssessing: e.target.checked,
                    });
                  }}
                  name="waitingAssessing"
                />
              }
              label="Waiting for Assess."
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.waitingEng}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      readyOnly: !e.target.checked ? filters.readyOnly : false,
                      waitingEng: e.target.checked,
                    });
                  }}
                  name="waitingEngg"
                />
              }
              label="Waiting for Eng."
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.waitingSc}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      readyOnly: !e.target.checked ? filters.readyOnly : false,
                      waitingSc: e.target.checked,
                    });
                  }}
                  name="waitingSc"
                />
              }
              label="Waiting for SC."
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.waitingOps}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      readyOnly: !e.target.checked ? filters.readyOnly : false,
                      waitingOps: e.target.checked,
                    });
                  }}
                  name="waitingOps"
                />
              }
              label="Waiting for Ops."
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.waitingOther}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      readyOnly: !e.target.checked ? filters.readyOnly : false,
                      waitingOther: e.target.checked,
                    });
                  }}
                  name="waitingOther"
                />
              }
              label="Waiting for Other."
            />
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={filters.highPriority}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      highPriority: e.target.checked,
                    });
                  }}
                  name="highPriority"
                />
              }
              label="High Priority"
            />
          </Grid>
        </Grid>

        <Box
          width={'100%'}
          justifyContent={'space-between'}
          display="flex"
          marginTop={'15px'}
        >
          {isFeatureEnabled('cwtBookmarks') && (
            <BookmarkMenu
              type={FilterEntityType.WorkorderFilter}
              filter={filters}
              setFilters={setFilters}
            />
          )}
          <Box>
            <Tooltip
              title="Refresh Backlog"
              placement="right"
            >
              <IconButton onClick={fetchWorkorders}>
                <RefreshBacklogIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Export CSV"
              placement="top"
            >
              <DownloadButton onClick={exportCSV} />
            </Tooltip>
          </Box>
        </Box>
        <ScheduleContextProvider allSchedules>
          <WOHeaderTable />
        </ScheduleContextProvider>
        <WODetailsModal />
      </WODetailsModalContextProvider>
    </Page>
  );
};

export default WOHeaderPage;
