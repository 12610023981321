import React, { useMemo } from 'react';
import styled from 'styled-components';
import UnstyledBodyRow from './BodyRow';
import BodyCell from './BodyCell';
import DraggableComponent from './DraggableComponent';
import { uniqueId } from 'lodash';

const BodyRow = styled(UnstyledBodyRow)`
  ${BodyCell}:first-child:before {
    position: absolute;
    z-index: 1;
    text-align: center;
    left: 14px;
    top: 14px;
    content: 'menu';
    color: black;

    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  &.dragging-is-disabled,
  &.control-disabled {
    user-select: none;
    cursor: unset;
    touch-action: none;
  }
  &.highlight-row ${BodyCell}:first-child {
    // background doesn't shift but border does
    background-image: linear-gradient(90deg, #ffa22d 0%, #ffa22d 4%, transparent 4%, transparent 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &.control-disabled ${BodyCell}:first-child:before {
    visibility: hidden;
  }
  &.dragging-is-disabled ${BodyCell}:first-child:before {
    opacity: 0.4;
    cursor: unset;
  }
`;
const DraggableRow = ({
  className,
  action,
  columns,
  rowNumber,
  renderPropBefore,
  renderPropAfter,
  isDragDisabled = false,
}) => {
  const cells = useMemo(
    () =>
      columns.map(column => (
        <BodyCell
          key={column.id + action.id + rowNumber + 'body-cell' + uniqueId()}
          action={action}
          column={column}
          renderPropBefore={renderPropBefore}
          renderPropAfter={renderPropAfter}
        />
      )),
    [columns, action]
  );

  return (
    <BodyRow
      key={action.id + rowNumber + 'body-row'}
      className={`${className}${isDragDisabled ? ' dragging-is-disabled' : ''}`}
      component={DraggableComponent(`draggable-id-${action.id}`, rowNumber, isDragDisabled)}
    >
      {cells}
    </BodyRow>
  );
};

export default DraggableRow;
