import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Control, FieldErrors, SubmitHandler, UseFormSetValue, useForm } from 'react-hook-form';
import { ScheduleEvent, ScheduleInputs, ScheduleItem, ScheduleStatus } from '../../../types/cwt/schedule';
import { add } from 'date-fns';
import { EventDropArg } from '@fullcalendar/core';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { FilterBaseType } from '../../../types/cwt/bookmarks';
import { WorkOrder } from '../../../types/cwt/workorder';

interface useScheduleValue {
  schedule: ScheduleItem[];
  events: ScheduleEvent[];
  statuses: ScheduleStatus[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fetchSchedule: () => Promise<void>;
  //filters
  filters: ScheduleFilters;
  setFilters: (filters: ScheduleFilters) => void;
  //schedule form
  control: Control<ScheduleInputs, any>;
  errors: FieldErrors<ScheduleInputs>;
  reset: () => void;
  setValue: UseFormSetValue<ScheduleInputs>;
  handleAddEditSchedule: () => Promise<void>;
  onDragDrop: (info: EventDropArg | EventResizeDoneArg) => Promise<void>;
  handleRemoveSchedule: () => Promise<void>;
}

interface Props {
  children: ReactNode;
  allSchedules?: boolean;
  workorder?: WorkOrder;
}
const ScheduleContext = createContext<useScheduleValue | undefined>(undefined);
interface ScheduleFilters extends FilterBaseType {
  wonum: string[];
  unit: string[];
  wopriority: string[];
  worktype: string[];
  crewworkgroup: string[];
  location: string[];
}
export const ScheduleContextProvider = ({ children, allSchedules, workorder }: Props) => {
  const [schedule, setSchedule] = useState<ScheduleItem[]>([]);
  const [events, setEvents] = useState<ScheduleEvent[]>([]);
  const [statuses, setStatuses] = useState<ScheduleStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<ScheduleFilters>({
    wonum: [],
    unit: [],
    wopriority: [],
    worktype: [],
    crewworkgroup: [],
    location: [],
  });
  const defaultLeadCrew = workorder?.crewworkgroup;

  const {
    control,
    reset,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
  } = useForm<ScheduleInputs>();
  const fetchSchedule = async () => {
    reset();
    const [schedule, statuses] = await Promise.all([
      Api.cwt.getEntireSchedule(allSchedules ? undefined : workorder?.id),
      Api.cwt.getAllScheduleStatuses(),
    ]);
    const scheduleEvents = schedule.map((item: ScheduleItem) => {
      return {
        id: item.id.toString(),
        title: item.title,
        start: new Date(item.start),
        end: add(new Date(item.end), { days: 1 }),
        allDay: true,
        workorderId: item.workorderId,
        wonum: item.wonum,
        crewId: item.crewId,
        statusId: item.statusId,
        scheduleNotes: item.scheduleNotes,
        color: item.color,
        location: item.location,
      };
    });
    if (workorder) {
      if (schedule.length > 0) {
        setValue('id', schedule[0].id);
        setValue('start', new Date(schedule[0].start));
        setValue('end', new Date(schedule[0].end));
        setValue('crewId', schedule[0].crewId);
        setValue('statusId', schedule[0].statusId.toString());
        setValue('scheduleNotes', schedule[0].scheduleNotes);
        setValue('completionNotes', schedule[0].completionNotes);
        setValue('workorderId', schedule[0].workorderId);
        setValue('location', schedule[0].location);
      } else {
        setValue('id', undefined);
        setValue('statusId', '1');
        setValue('scheduleNotes', '');
        setValue('completionNotes', '');
        setValue('workorderId', workorder.id);
        setValue('location', '');
        if (defaultLeadCrew) setValue('crewId', defaultLeadCrew);
      }
    }

    setSchedule(schedule);
    setEvents(scheduleEvents);
    setStatuses(statuses);
    setLoading(false);
  };
  useEffect(() => {
    const getSchedule = async () => {
      fetchSchedule();
    };

    void getSchedule();
  }, [workorder, filters]);

  const onAddEditSchedule: SubmitHandler<ScheduleInputs> = async ({ ...data }) => {
    try {
      setLoading(true);
      await (getValues('id') ? Api.cwt.updateSchedule({ ...data }) : Api.cwt.createSchedule(data));
      await fetchSchedule();
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveSchedule = async () => {
    try {
      setLoading(true);
      const id = getValues('id');
      if (!id) throw Error('Could not remove schedule');
      await Api.cwt.removeSchedule(id);
      await fetchSchedule();
    } catch (e) {
      console.error(e);
    }
  };

  const onDragDrop = async (info: EventDropArg | EventResizeDoneArg) => {
    try {
      if (!info.event.start || !info.event.end) throw Error('Invalid event');
      setValue('start', info.event.start);
      setValue('end', add(info.event.end, { days: -1 }));
      setValue('workorderId', info.event.extendedProps.workorderId);
      setValue('crewId', info.event.extendedProps.crewId);
      setValue('statusId', info.event.extendedProps.statusId);
      setValue('scheduleNotes', info.event.extendedProps.scheduleNotes);
      setValue('location', info.event.extendedProps.location);
      if (info.event.id) {
        setValue('id', Number(info.event.id));
      }
      handleSubmit(onAddEditSchedule)();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <ScheduleContext.Provider
      value={{
        schedule,
        events,
        statuses,
        loading,
        setLoading,
        fetchSchedule,
        filters,
        setFilters,
        control,
        errors,
        reset,
        setValue,
        handleAddEditSchedule: handleSubmit(onAddEditSchedule),
        onDragDrop,
        handleRemoveSchedule,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (context === undefined) {
    throw new Error('useSchedule must be used within a ScheduleContextProvider');
  }
  return context;
};
