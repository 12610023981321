import React from 'react';
import styled from 'styled-components';
import oldEchoLogo from '../../assets/ECHO_logo_old.svg';
import echoLogo from '../../assets/ECHO_logo.svg';
import isFeatureEnabled from '../../utils/feature-flags';

const LogoImage = styled.img.attrs({
  src: isFeatureEnabled('navImprovementUI') ? echoLogo : oldEchoLogo,
})``;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0;

  ${LogoImage} {
    margin-right: 5px;
  }

  h1 {
    margin: 0;
    font: ${({ theme }) => theme.fonts.normal22};
    color: ${({ theme }) => theme.colours.greys.lightGrey7};
    letter-spacing: 0;
    text-align: center;
  }
`;

const EchoLogo = props => (
  <Container {...props}>
    <LogoImage />
  </Container>
);

export default EchoLogo;
