import { useWorkOrderFlags } from '../../../hooks/useWorkOrderFlags';
import Select from '../../../../../components/Select';
import { WorkOrder } from '../../../../../types/cwt/workorder';
import styled from 'styled-components';

interface Props {
  workorder: WorkOrder;
}

const HeaderFlagSelect = styled(Select)`
  .MuiSelect-root {
    margin: 0;
    min-width: 100px;
    max-width: 200px;
  }
`;

const WOFlags = ({ workorder }: Props) => {
  const { workorderFlags, selectedWorkorderFlags, updateWorkorderFlags, updateSelectedWorkorderFlags } =
    useWorkOrderFlags();

  const onEditValueChange = ({ data }: any) => {
    updateSelectedWorkorderFlags(data);
  };

  const onClose = () => {
    updateWorkorderFlags(workorder.id);
  };

  return (
    <HeaderFlagSelect
      options={workorderFlags.map(flag => ({ label: flag.label, value: flag.id }))}
      multiple
      onChange={(x: any) => {
        onEditValueChange({ data: x.target.value });
      }}
      onClose={() => {
        onClose();
      }}
      value={selectedWorkorderFlags}
    />
  );
};

export default WOFlags;
