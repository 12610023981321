import { useState } from 'react';
import styled from 'styled-components';
import OkayCancelDialog from '../OkayCancelDialog';
import UnstyledActionTable, {
  ActionTableWithVisibilityContext as UnStyledActionTableWithVisibilityContext,
} from '../ActionTable';
import ActionDialog from '../ActionDialog';
import { ElementIndicatorsProvider } from '../../hooks/element-indicators';
import { Grid, Input, TextField } from '@material-ui/core';
import { type ShipChipHook } from '../../pages/live-reports/tabs/ship-chip/hooks/useShipsChips';
import { MyElement } from '../../types/my';
import LoadingContainer from '../LoadingContainer';
import isFeatureEnabled from '../../utils/feature-flags';

const actionTableCss = `
  max-height: calc(100vh - 170px);
  border: none;
`;
const ActionTable = styled(UnstyledActionTable)`
  ${actionTableCss}
`;
const ActionTableWithVisibilityContext = styled(UnStyledActionTableWithVisibilityContext)`
  ${actionTableCss}
`;

interface Props
  extends Omit<
    ShipChipHook,
    'clearStatusAction' | 'setElement' | 'setState' | 'source' | 'setSource' | 'comment' | 'showNewActionDialog'
  > {
  element: MyElement | null;
  relatedRisk?: number | null;
  isActionVisibilityTable?: boolean;
}

const ShipChipTable = ({
  actions,
  clearDeleteAction,
  clearEditAction,
  clearNewAction,
  confirmActionStatusToggle,
  confirmDeleteActionToggle,
  deleteAction,
  deleteActionStatus,
  editAction,
  element,
  handleOnCancel,
  handleOnOkay,
  newAction,
  isLoading,
  relatedRisk = null,
  reorderActions,
  saveNewAction,
  setComment,
  showEditActionDialog,
  state,
  statusAction,
  updateAction,
  isActionVisibilityTable = false,
}: Props) => {
  const [isYesDelete, setIsYesDelete] = useState(false);

  return (
    <>
      {isActionVisibilityTable && isFeatureEnabled('riskActionHiddenToggle') && (
        <ActionTableWithVisibilityContext
          reorderable={state === 'ongoing'}
          onChangeStatus={confirmActionStatusToggle}
          onDelete={confirmDeleteActionToggle}
          onEdit={state === 'ongoing' ? showEditActionDialog : undefined}
          reorderRows={reorderActions}
          element={element}
          isLoading={isLoading}
        />
      )}
      {(!isActionVisibilityTable || !isFeatureEnabled('riskActionHiddenToggle')) && (
        <LoadingContainer loading={isLoading}>
          {actions.length === 0 ? (
            'No actions found'
          ) : (
            <ActionTable
              actions={actions}
              state={state}
              reorderable={state === 'ongoing'}
              onChangeStatus={confirmActionStatusToggle}
              onDelete={confirmDeleteActionToggle}
              onEdit={state === 'ongoing' ? showEditActionDialog : undefined}
              reorderRows={reorderActions}
              element={element}
            />
          )}
        </LoadingContainer>
      )}
      {element && (editAction || newAction) && (
        <ElementIndicatorsProvider elementId={element.elementId}>
          <ActionDialog
            relatedRisk={relatedRisk}
            action={editAction || newAction}
            onSave={editAction ? updateAction : saveNewAction}
            onCancel={editAction ? clearEditAction : clearNewAction}
          />
        </ElementIndicatorsProvider>
      )}
      {statusAction && (
        <OkayCancelDialog
          title={statusAction.state === 'complete' ? 'Re-open?' : 'Complete?'}
          okayLabel="Yes"
          onOkay={handleOnOkay}
          onCancel={handleOnCancel}
        >
          <Grid
            container
            item
            spacing={2}
          >
            <Grid
              item
              xs={8}
              md={11}
              lg={12}
            >
              {statusAction.state === 'complete'
                ? 'Are you sure you want to re-open this action?'
                : 'Are you sure you want to mark this action complete?'}
            </Grid>
            {statusAction.state !== 'complete' && (
              <Grid
                item
                xs={8}
                md={11}
                lg={12}
              >
                <TextField
                  label="Submission Comment"
                  fullWidth
                  onChange={e => setComment(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </OkayCancelDialog>
      )}
      {deleteAction && (
        <OkayCancelDialog
          title={'Delete Warning'}
          okayLabel={isYesDelete ? 'Delete' : 'Please enter Yes to delete'}
          onOkay={() => {
            if (isYesDelete) {
              deleteActionStatus();
              setIsYesDelete(false);
            }
          }}
          onCancel={clearDeleteAction}
        >
          <Grid
            container
            item
            spacing={2}
          >
            <Grid
              item
              xs={8}
              md={11}
              lg={12}
            >
              Are you sure you wish to delete this action?
            </Grid>
            <Grid
              item
              xs={8}
              md={11}
              lg={12}
            >
              <b>WARNING: It will be permanently removed across the entire ECHO application</b>
            </Grid>
            <Grid
              item
              xs={8}
              md={11}
              lg={12}
            >
              <Input
                placeholder="Type Yes to delete"
                onChange={e => setIsYesDelete(e.target.value.toLowerCase() === 'yes')}
              />
            </Grid>
          </Grid>
        </OkayCancelDialog>
      )}
    </>
  );
};

export default ShipChipTable;
