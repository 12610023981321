/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import styled from 'styled-components';
import UnstyledDrawer from '@material-ui/core/Drawer';
import { Route } from 'react-router-dom';
import UnstyledMenu from './Menu';
import UnstyledEchoLogo from '../default/EchoLogo';
import Messages from '../default/Messages';
import UnstyledHeader from './Header';
import { routes } from './menu-items';
import { UsersContextProvider } from '../../pages/cwt/hooks/useUsers';
import CWTErrorBoundary from '../../pages/cwt/components/CWTErrorBoundary';
import { WorkOrdersContextProvider } from '../../pages/cwt/hooks/useWorkOrders';
import { forRoles } from '../../auth/roles';
import { ActionsContextProvider } from '../../pages/cwt/hooks/useActions';
import { CrewsContextProvider } from '../../pages/cwt/hooks/useCrews';
import { TPlusOneContextProvider } from '../../pages/cwt/hooks/useTPlusOne';
import { WorkOrderFlagsContextProvider } from '../../pages/cwt/hooks/useWorkOrderFlags';

const EchoLogo = styled(UnstyledEchoLogo)``;
const Menu = styled(UnstyledMenu)``;
const Header = styled(UnstyledHeader)``;

const Main = styled.main``;

const DRAWER_WIDTH = '231px';

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${DRAWER_WIDTH});
  overflow-x: hidden;

  ${Header} {
    height: 32px;
  }

  ${Main} {
    margin-top: 3px;
  }
`;

const Container = styled.div`
  display: flex;

  ${Content} {
    margin: 15px 20px 20px 20px;
  }
`;

const Drawer = styled(UnstyledDrawer)`
  width: ${DRAWER_WIDTH};
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 231px;
    background-color: ${({ theme }) => theme.colours.menu.background};
  }

  ${EchoLogo} {
    margin: 41px auto 0px 40px;
  }

  .hr {
    margin: 32px 0px 0px 28px;
    border-bottom: ${({ theme }) => theme.borders.thin.solid5};
  }

  ${Menu} {
    margin-top: 25px;
  }
`;

const CWTLayout = () => {
  const routeItems = routes.map(route =>
    forRoles(
      ['CWTUser', 'CWTAdmin'],
      <Route
        key={route.path}
        path={route.path}
        component={route.component}
      />
    )
  );

  return (
    <Container>
      <Drawer
        variant="permanent"
        anchor="left"
      >
        <EchoLogo />
        <div className="hr" />
        <Menu />
      </Drawer>
      <Content>
        <CWTErrorBoundary>
          <UsersContextProvider>
            <WorkOrdersContextProvider>
              <TPlusOneContextProvider>
                <WorkOrderFlagsContextProvider>
                  <ActionsContextProvider allActions={true}>
                    <CrewsContextProvider>
                      <Header />
                      <Main>{routeItems}</Main>
                      <Messages />
                    </CrewsContextProvider>
                  </ActionsContextProvider>
                </WorkOrderFlagsContextProvider>
              </TPlusOneContextProvider>
            </WorkOrdersContextProvider>
          </UsersContextProvider>
        </CWTErrorBoundary>
      </Content>
    </Container>
  );
};

export default CWTLayout;
