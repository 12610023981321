import { useMemo } from 'react';
import styled from 'styled-components';
import UnstyledTableBody from '@material-ui/core/TableBody';
import ActionTableRoot from './ActionTableRoot';
import UnstyledDraggableRow from './DraggableRow';
import DroppableComponent from './DroppableComponent';

const DraggableRow = styled(UnstyledDraggableRow)`
  &.hidden-row {
    /*
      hidden due to complexity around action-vibility & reordering.  Need
      maintain the DOM so the indexing works.  At the very least anything that
      is hidden-row is converted to return null should test reordering to ensure
      it sends the values to the API correctly (currently stored procedure only
      writes correctly some of the time)
    */
    display: none;
  }
`;

const TableBody = styled(UnstyledTableBody)`
  ${DraggableRow}.programmatic-even-nth-cell-style,
  ${DraggableRow}.auto-nth-cell-style:nth-child(1n) {
    background-color: ${({ theme }) => theme.colours.white};
  }
  ${DraggableRow}.programmatic-even-nth-cell-style .border-2px-as-background-color,
  ${DraggableRow}.auto-nth-cell-style:nth-child(1n) .border-2px-as-background-color {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colours.white};
  }
  ${DraggableRow}.programmatic-odd-nth-cell-style,
  ${DraggableRow}.auto-nth-cell-style:nth-child(2n) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
  ${DraggableRow}.programmatic-odd-nth-cell-style .border-2px-as-background-color,
  ${DraggableRow}.auto-nth-cell-style:nth-child(2n) .border-2px-as-background-color {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;
const ReorderableActionTable = ({ rowIterator = null, unstyled = false, className, actions, columns, reorderRows }) => {
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.source.index !== result.destination.index) {
      reorderRows(result.source.index, result.destination.index);
    }
  };

  const rows = useMemo(
    () =>
      actions.map((action, i) => {
        const {
          rowCssClass = '',
          isDragDisabled = false,
          renderPropBefore,
          renderPropAfter,
        } = typeof rowIterator === 'function' ? rowIterator({ actions, action, i }) : {};

        return (
          <DraggableRow
            key={action.id}
            className={`${rowCssClass ? `${rowCssClass}` : 'auto-nth-cell-style'}`}
            action={action}
            rowNumber={i}
            columns={columns}
            isDragDisabled={isDragDisabled}
            renderPropBefore={renderPropBefore}
            renderPropAfter={renderPropAfter}
          />
        );
      }),
    [actions.length, columns, rowIterator]
  );

  return (
    <ActionTableRoot
      className={className}
      columns={columns}
    >
      {unstyled ? (
        <UnstyledTableBody component={DroppableComponent(onDragEnd)}>{rows}</UnstyledTableBody>
      ) : (
        <TableBody component={DroppableComponent(onDragEnd)}>{rows}</TableBody>
      )}
    </ActionTableRoot>
  );
};

export default ReorderableActionTable;
