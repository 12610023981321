import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { WorkOrder, TPlusOneWorkOrderFilters, DelayReasons } from '../../../types/cwt/workorder';
import { useUsers } from './useUsers';
import { ActionFlag } from '../../../types/cwt/actions';
import { checkTPlusOneWorkOrderFilters, downloadBlob } from '../utils';

interface UseTPlusOneValue {
  workorders: Map<number, WorkOrder>;
  filteredWorkorders: WorkOrder[];
  selectedWorkorderId: number | undefined;
  filters: TPlusOneWorkOrderFilters;
  setFilters: (filters: TPlusOneWorkOrderFilters) => void;
  fetchWorkorders: () => void;
  handleHeaderTableSelection: (selected: TypeOnSelectionChangeArg) => void;
  setSelectedWorkorderId: (workorderId: number | undefined) => void;
  woloading: boolean;
  getWorkorderById: (id: number | undefined) => WorkOrder | undefined;
  getWorkorderByWonum: (wonum: string | undefined) => WorkOrder | undefined;
  fetchWorkorderById: (id: number | undefined) => Promise<WorkOrder | undefined>;
  flags: ActionFlag[];
  woCount: number;
  newDelayReason: string;
  setNewDelayReason: (reason: string) => void;
  removeDelayReason: (id: number) => Promise<void>;
  addDelayReason: (label: string) => Promise<void>;
  delayReasons: DelayReasons[];
  exportCSV: () => void;
  setGridRef: (gridRef: any) => void;
}

interface Props {
  children: ReactNode;
}
const TPlusOneContext = createContext<UseTPlusOneValue | undefined>(undefined);

export const TPlusOneContextProvider = ({ children }: Props) => {
  const [workorders, setWorkorders] = useState<Map<number, WorkOrder>>(new Map());
  const [filteredWorkorders, setFilteredWorkorders] = useState<WorkOrder[]>([]);
  const [selectedWorkorderId, setSelectedWorkorderId] = useState<number | undefined>(undefined);
  const [woloading, setWoLoading] = useState<boolean>(true);
  const [flags, setFlags] = useState<ActionFlag[]>([]);
  const [woCount, setWoCount] = useState<number>(0);
  const [newDelayReason, setNewDelayReason] = useState<string>('');
  const [delayReasons, setDelayReasons] = useState<DelayReasons[]>([]);
  const [delayReasonsLoading, setDelayReasonsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<TPlusOneWorkOrderFilters>({
    wonum: [],
    unit: [],
    wopriority: [],
    worktype: [],
    status: [],
    crewworkgroup: [],
    bpplanningctr: [],
    bpschedulebacklog: [],
    specifications: [],
    location: [],
    workorderFlags: [],
    compweek: [],
    //min and max life are arrays because we didn't want to allow undefined types in the filter
    //but we still want to allow the user to not set a min or max life
    //they will only ever have at most 1 item in the array.
    minLife: [],
    maxLife: [],
    delayReasons: [],
  });

  const handleSetFilteredWorkorders = (workorders: Map<number, WorkOrder>) => {
    const result = Array.from(workorders.values()).filter(wo => checkTPlusOneWorkOrderFilters(wo, filters));
    const selectedExists = result.find(workorder => workorder.id === selectedWorkorderId);
    if (!selectedExists) {
      setSelectedWorkorderId(result.length > 0 ? result[0].id : undefined);
    }
    setFilteredWorkorders(result);
  };

  const handleSetWorkorders = (workorders: Map<number, WorkOrder>) => {
    setWorkorders(workorders);
    handleSetFilteredWorkorders(workorders);
  };

  const handleHeaderTableSelection = useCallback(
    (selected: TypeOnSelectionChangeArg) => {
      if (selected) {
        setSelectedWorkorderId(Number(selected?.selected?.toString()));
      } else {
        setSelectedWorkorderId(undefined);
      }
    },
    [setSelectedWorkorderId]
  );

  const fetchWorkorders = () => {
    setWoLoading(true);
  };

  const { userSite } = useUsers();
  useEffect(() => {
    // Queue a refetch if site selection is changed
    fetchWorkorders();
  }, [userSite]);

  const getWorkorderById = (id: number | undefined) => {
    if (!id || !workorders) return undefined;
    return workorders.get(id);
  };

  const getWorkorderByWonum = (wonum: string | undefined) => {
    if (!wonum || !workorders) return undefined;
    return Array.from(workorders.values()).find(wo => wo.wonum === wonum);
  };

  const getAllFlags = async () => {
    const res = await Api.cwt.getAllActionFlags();
    setFlags(res);
  };

  const fetchWorkorderById = async (id: number | undefined) => {
    if (!id) return undefined;
    try {
      const res = await Api.cwt.getWorkorder(id);
      return res;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };
  useEffect(() => {
    handleSetFilteredWorkorders(workorders);
  }, [filters, workorders]);

  useEffect(() => {
    const getAllWorkorders = async () => {
      const res = await Api.cwt.getAllWorkorders({ status: 'complete' });
      handleSetWorkorders(new Map(res.map((wo: WorkOrder) => [wo.id, wo])));
      setWoCount(res.length);
      setWoLoading(false);
    };
    if (woloading) {
      void getAllWorkorders();
      void getAllFlags();
    }
  }, [woloading]);

  useEffect(() => {
    const getDelayReasons = async () => {
      const res = await Api.cwt.getAllDelayReasons();
      setDelayReasons(res);
      setDelayReasonsLoading(false);
    };
    if (delayReasonsLoading) {
      void getDelayReasons();
    }
  }, [delayReasonsLoading]);

  const removeDelayReason = async (id: number) => {
    await Api.cwt.disableDelayReason(id);
    setDelayReasonsLoading(true);
  };
  const addDelayReason = async (label: string) => {
    await Api.cwt.addDelayReason(label);
    setNewDelayReason('');
    setDelayReasonsLoading(true);
  };
  const [gridRef, setGridRef] = useState<any>(null);
  const exportCSV = () => {
    const header = gridRef.current.visibleColumns.map((column: any) => column.header).join(',');

    const rows = filteredWorkorders.map((row: any) => {
      return gridRef.current.visibleColumns
        .map((column: any) => {
          const value = row[column.id];
          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',');
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `workorders-${new Date().toISOString()}`);
  };
  return (
    <TPlusOneContext.Provider
      value={{
        workorders,
        filteredWorkorders,
        selectedWorkorderId,
        fetchWorkorders,
        filters,
        setFilters,
        handleHeaderTableSelection,
        setSelectedWorkorderId,
        woloading,
        getWorkorderById,
        getWorkorderByWonum,
        fetchWorkorderById,
        flags,
        woCount,
        newDelayReason,
        setNewDelayReason,
        removeDelayReason,
        addDelayReason,
        delayReasons,
        exportCSV,
        setGridRef,
      }}
    >
      {children}
    </TPlusOneContext.Provider>
  );
};

export const useTPlusOne = () => {
  const context = useContext(TPlusOneContext);
  if (context === undefined) {
    throw new Error('TPlusOneContext must be used within a TPlusOneContextProvider');
  }
  return context;
};
