"use strict";
// @ts-nocheck
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withAdjustedValues = exports.withUpdatedManualValue = exports.newScorecard = exports.emptyScorecard = void 0;
const R = __importStar(require("ramda"));
const manual_values_1 = require("./manual-values");
const calculated_values_1 = require("./calculated-values");
const overrides_1 = require("./overrides");
const total_scores_1 = require("./total-scores");
const scores_1 = require("./scores");
const indicators_1 = require("./indicators");
const units_1 = require("./units");
const sections_1 = require("./sections");
const buildInitialUnitValues = (units, { type: indicatorType, weight: indicatorWeight }) => R.reduce((unitValues, { id: unitId, weight: unitWeight }) => (Object.assign(Object.assign({}, unitValues), { [unitId]: indicatorType === 'calculated' ? (0, calculated_values_1.buildCalculatedScorecardValue)(0, indicatorWeight, unitWeight) : (0, manual_values_1.buildManualScorecardValue)('', 'green', '', indicatorWeight, unitWeight) })), {}, units);
const buildInitialValues = (units, indicators) => R.reduce((values, indicator) => (Object.assign(Object.assign({}, values), { [indicator.id]: buildInitialUnitValues(units, indicator) })), {}, indicators);
const emptyScorecard = (indicators, units, colourMap, totalScoreCurve, sections) => ({
    indicators: (0, indicators_1.buildIndicators)(indicators),
    indicatorMap: (0, indicators_1.buildIndicatorMap)(indicators),
    units: (0, units_1.buildUnits)(units),
    unitMap: (0, units_1.buildUnitMap)(units),
    colourMap,
    totalScoreCurve,
    sections: (0, sections_1.buildSections)(sections, indicators),
    values: buildInitialValues(units, indicators),
});
exports.emptyScorecard = emptyScorecard;
const newScorecard = (indicators, units, colourMap, totalScoreCurve, sections, counts, overrides, manualValues, totalUnitOverrides) => R.compose((0, total_scores_1.withTotalUnitScoreOverride)(totalUnitOverrides), total_scores_1.withTotalScores, scores_1.withScores, (0, manual_values_1.withManualValues)(manualValues), (0, overrides_1.withOverrides)(overrides), (0, calculated_values_1.withCounts)(counts))((0, exports.emptyScorecard)(indicators, units, colourMap, totalScoreCurve, sections));
exports.newScorecard = newScorecard;
const withUpdatedManualValue = (manualValue, scorecard) => R.compose(total_scores_1.withTotalScores, scores_1.withScores, (0, manual_values_1.withManualValue)(manualValue))(scorecard);
exports.withUpdatedManualValue = withUpdatedManualValue;
const withAdjustedValues = (adjustments, scorecard) => R.compose(total_scores_1.withTotalScores, scores_1.withScores, (0, overrides_1.withAdjustments)(adjustments))(scorecard);
exports.withAdjustedValues = withAdjustedValues;
