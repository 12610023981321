import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useTPlusOne } from '../../hooks/useTPlusOne';
import { renderActions, onRenderRow } from '../../wo/components/utils';
import styled from 'styled-components';
import { TPlusOneColumns } from './TPlusOneTableColumns';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';

const gridStyle = { minHeight: 600, minWidth: '100%' };

const HeaderGrid = styled(ReactDataGrid)`
  .InovuaReactDataGrid__row-cell-wrap:hover {
    background-color: transparent !important;
    border: unset !important;
    border: 2px solid gray !important;
  }

  .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid transparent !important;
  }
  .InovuaReactDataGrid__row-cell-wrap:active {
    transform: scale(0.997);
    cursor: pointer;
  }

  .InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
    border: 2px solid rgb(38, 36, 82) !important;
  }
  .InovuaReactDataGrid__row.InovuaReactDataGrid__row--selected > .InovuaReactDataGrid__row-cell-wrap {
  }
`;

const TPlusOneWorkorderTable = () => {
  const { filteredWorkorders, selectedWorkorderId, handleHeaderTableSelection, woloading, setGridRef } = useTPlusOne();
  const { handleOpen } = useWODetailsModal();
  const data = filteredWorkorders.map(wo => ({
    ...wo,
  }));

  const columns = [
    {
      name: 'actions',
      header: 'Actions',
      defaultWidth: 80,
      sortable: false,
      showColumnMenuTool: false,
      render: renderActions,
    },
    ...TPlusOneColumns,
  ];
  return (
    <HeaderGrid
      style={gridStyle}
      idProperty="id"
      enableSelection
      selected={selectedWorkorderId}
      onSelectionChange={handleHeaderTableSelection}
      onRowDoubleClick={() => handleOpen('tplusone')}
      rowStyle={onRenderRow}
      pagination
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={data}
      showCellBorders={false}
      loading={woloading}
      onReady={setGridRef}
    />
  );
};

export default TPlusOneWorkorderTable;
