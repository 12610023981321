import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Action, ActionGroup, ActionStatus, ActionFlag, FormActionInputs } from '../../../types/cwt/actions';
import Api from '../../../api';
import { Control, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { downloadBlob } from '../utils';
import { useUsers } from './useUsers';
import { format } from 'date-fns';
import { getUserId } from '../../../auth/utils';
import { FilterBaseType } from '../../../types/cwt/bookmarks';

interface useActionsValue {
  //Get/Edit
  actions: Action[];
  groups: ActionGroup[];
  statuses: ActionStatus[];
  flags: ActionFlag[];
  fetchActions: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setActions: (actions: Action[]) => void;
  filters: ActionsFilters;
  setFilters: (filters: ActionsFilters) => void;
  //Submit
  openAddEditActionDialog: boolean;
  setOpenAddEditActionDialog: (open: boolean) => void;
  handleOpenAddActionDialog: (tPlusOne?: boolean) => void;
  handleOpenEditActionDialog: (id: number) => void;
  addEdit: 'add' | 'edit';
  control: Control<FormActionInputs, any>;
  errors: FieldErrors<FormActionInputs>;
  handleSubmit: () => Promise<void>;
  reset: () => void;
  exportCSV: () => void;
  setGridRef: (gridRef: any) => void;
  filteredActionsWithWorkorder: Action[];
  removeActionFlag: (id: number | string) => Promise<void>;
  addActionFlag: (label: string) => Promise<void>;
  setNewActionFlag: (entry: string) => void;
  newActionFlag: string;
  removeActionGroup: (id: number | string) => Promise<void>;
  addActionGroup: (label: string) => Promise<void>;
  setNewActionGroup: (entry: string) => void;
  newActionGroup: string;
  removeActionStatus: (id: number | string) => Promise<void>;
  addActionStatus: (label: string) => Promise<void>;
  setNewActionStatus: (entry: string) => void;
  newActionStatus: string;
  sortByStatus: (value1: number, value2: number) => void;
}

interface Props {
  children: ReactNode;
  workorderId?: number;
  allActions?: boolean;
}
const ActionsContext = createContext<useActionsValue | undefined>(undefined);
interface ActionsFilters extends FilterBaseType {
  wonum: string[];
  unit: string[];
  wopriority: string[];
  worktype: string[];
  crewworkgroup: string[];
  groupId: string[];
  statusId: string[];
  flags: string[];
  owner: string[];
  highPriority: boolean;
  schedstart: string[];
  tPlusOne: boolean;
}
export const ActionsContextProvider = ({ children, allActions = false, workorderId }: Props) => {
  const [actions, setActions] = useState<Action[]>([]);
  const [groups, setGroups] = useState<ActionGroup[]>([]);
  const [statuses, setStatuses] = useState<ActionStatus[]>([]);
  const [flags, setFlags] = useState<ActionFlag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [newActionFlag, setNewActionFlag] = useState('');
  const [newActionGroup, setNewActionGroup] = useState('');
  const [newActionStatus, setNewActionStatus] = useState('');
  const { users, userSite } = useUsers();
  const currentUserId = getUserId();
  const [flagLoading, setFlagLoading] = useState<boolean>(true);
  const [groupLoading, setGroupLoading] = useState<boolean>(true);
  const [statusLoading, setStatusLoading] = useState<boolean>(true);

  const defaultFilters: ActionsFilters = {
    wonum: [],
    unit: [],
    wopriority: [],
    worktype: [],
    crewworkgroup: [],
    groupId: [],
    statusId: [],
    flags: [],
    owner: currentUserId ? [currentUserId] : [],
    highPriority: false,
    schedstart: [],
    tPlusOne: false,
  };
  const [filters, setFilters] = useState<ActionsFilters>(defaultFilters);
  const [gridRef, setGridRef] = useState<any>(null);
  const filteredActionsWithWorkorder = useMemo(
    () =>
      actions.filter((action: Action) => {
        return (
          (!filters.wonum.length || filters.wonum.includes(action.wonum)) &&
          (!filters.unit.length || filters.unit.includes(action.unit)) &&
          (!filters.wopriority.length || filters.wopriority.includes(action.wopriority?.toString())) &&
          (!filters.worktype.length || filters.worktype.includes(action.worktype)) &&
          (!filters.crewworkgroup.length || filters.crewworkgroup.includes(action.crewworkgroup)) &&
          (!filters.groupId.length || filters.groupId.includes(action.groupId.toString())) &&
          (!filters.statusId.length || filters.statusId.includes(action.statusId.toString())) &&
          (!filters.owner.length || filters.owner.includes(action.owner?.toString())) &&
          (!filters.flags.length || filters.flags.some((flag: string) => action.flags.includes(Number(flag)))) &&
          (filters.schedstart.length === 0 ||
            filters.schedstart.some(range => {
              const [start, end] = range.split(' to ').map(date => new Date(date));
              const woDate = new Date(action.schedstart);
              return woDate >= start && woDate <= end;
            })) &&
          (!filters.highPriority ||
            (filters.highPriority &&
              (action.bpplanningctr == 'OPB' || action.bpplanningctr == 'OPA') &&
              (action.wopriority == 1 ||
                action.wopriority == 2 ||
                action.worktype == 'CC' ||
                action.worktype == 'DC' ||
                action.worktype == 'CN' ||
                action.flags.includes(5) ||
                action.flags.includes(7) ||
                action.flags.includes(8)))) &&
          (!filters.tPlusOne || filters.tPlusOne === action.tPlusOne)
        );
      }),
    [actions, filters]
  );

  const exportCSV = async () => {
    const header = gridRef.current.visibleColumns
      .filter((column: any) => column.header !== 'History')
      .map((column: any) => column.header)
      .join(',');

    const rows = filteredActionsWithWorkorder.map((row: any) => {
      return gridRef.current.visibleColumns
        .filter((column: any) => column.header !== 'History')
        .map((column: any) => {
          const value = row[column.id];
          if (column.header === 'Owner') {
            return users.find(user => user.id === row[column.id])?.name;
          }
          if (column.header === 'TCD' || column.header === 'TBE') {
            return format(new Date(row[column.id]), 'ddMMMyyyy').toUpperCase();
          }

          if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          }
          if (column.header === 'Flags') {
            return row[column.id].map((flagId: number) => flags.find(flag => flag.id === flagId)?.label).join('/');
          }

          return value;
        })
        .join(',');
    });

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(blob, `actions-${new Date().toISOString()}`);
  };

  const fetchActions = async () => {
    setLoading(true);
    const res = await Api.cwt.getAllActions(allActions ? undefined : workorderId);
    setActions(res);
    setLoading(false);
  };

  useEffect(() => {
    const getAllActions = async () => {
      setLoading(true);
      const [actions, groups, statuses, flags] = await Promise.all([
        Api.cwt.getAllActions(undefined),
        Api.cwt.getAllActionGroups(),
        Api.cwt.getAllActionStatuses(),
        Api.cwt.getAllActionFlags(),
      ]);

      setActions(actions);
      setGroups(groups);
      setStatuses(statuses);
      setFlags(flags);

      setLoading(false);
    };
    if (allActions) {
      void getAllActions();
    }
  }, [allActions]);

  useEffect(() => {
    const getSelectedActions = async () => {
      setLoading(true);
      const [actions, groups, statuses, flags] = await Promise.all([
        Api.cwt.getAllActions(workorderId),
        Api.cwt.getAllActionGroups(),
        Api.cwt.getAllActionStatuses(),
        Api.cwt.getAllActionFlags(),
      ]);

      setActions(actions);
      setGroups(groups);
      setStatuses(statuses);
      setFlags(flags);

      setLoading(false);
    };

    if (!allActions) {
      void getSelectedActions();
    }
  }, [workorderId, allActions]);

  useEffect(() => {
    // Queue a refetch if site selection is changed
    fetchActions();
  }, [userSite]);

  useEffect(() => {
    const getActionFlags = async () => {
      const flags = await Api.cwt.getAllActionFlags();
      setFlags(flags);
      setFlagLoading(false);
    };

    void getActionFlags();
  }, [flagLoading]);

  useEffect(() => {
    const getActionGroups = async () => {
      const groups = await Api.cwt.getAllActionGroups();
      setGroups(groups);
      setGroupLoading(false);
    };

    void getActionGroups();
  }, [groupLoading]);

  useEffect(() => {
    const getActionStatus = async () => {
      const statuses = await Api.cwt.getAllActionStatuses();
      setStatuses(statuses);
      setStatusLoading(false);
    };

    void getActionStatus();
  }, [statusLoading]);

  //Submit
  const [openAddEditActionDialog, setOpenAddEditActionDialog] = useState<boolean>(false);
  const [addEdit, setAddEdit] = useState<'add' | 'edit'>('add');
  const handleOpenAddActionDialog = (tPlusOne = false) => {
    setAddEdit('add');
    reset({ id: undefined, workorderId: workorderId, statusId: 1, flags: [], tPlusOne });
    setOpenAddEditActionDialog(true);
  };
  const handleOpenEditActionDialog = (id: number) => {
    setAddEdit('edit');
    const action = actions.find(action => action.id === id);
    reset(action);
    setOpenAddEditActionDialog(true);
  };

  const removeActionFlag = async (flagId: number | string) => {
    await Api.cwt.removeActionFlag(flagId);
    setFlagLoading(true);
  };

  const addActionFlag = async (label: string) => {
    await Api.cwt.addActionFlag(label);
    setNewActionFlag('');
    setFlagLoading(true);
  };

  const removeActionGroup = async (groupId: number | string) => {
    await Api.cwt.removeActionGroup(groupId);
    setGroupLoading(true);
  };

  const addActionGroup = async (label: string) => {
    await Api.cwt.addActionGroup(label);
    setNewActionGroup('');
    setGroupLoading(true);
  };

  const removeActionStatus = async (groupId: number | string) => {
    await Api.cwt.removeActionStatus(groupId);
    setStatusLoading(true);
  };

  const addActionStatus = async (label: string) => {
    await Api.cwt.addActionStatus(label);
    setNewActionStatus('');
    setStatusLoading(true);
  };

  /**
   * Sort actions by status in the following order: New, Returned, In-Progress, Waiting for Others, Complete and putting manually added statuses at the end
   */
  const sortByStatus = (value1: number, value2: number) => {
    const statusOrder: { [key: number]: number } = {
      1: 0,
      4: 1,
      2: 2,
      5: 3,
      6: 3,
      7: 3,
      3: 4,
    };

    const getOrderIndex = (statusId: number) => {
      return statusId in statusOrder ? statusOrder[statusId] : Infinity;
    };

    const orderIndexA = getOrderIndex(value1);
    const orderIndexB = getOrderIndex(value2);

    return orderIndexA - orderIndexB;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormActionInputs>({
    defaultValues: { id: undefined, workorderId: workorderId, statusId: 1, flags: [], tPlusOne: false },
  });

  const onAddEditAction: SubmitHandler<FormActionInputs> = async ({ ...data }) => {
    setOpenAddEditActionDialog(false);
    if (data.id) {
      await Api.cwt.updateAction(data);
    } else await Api.cwt.createAction(data);
    reset();
  };
  const actionsContextValue = useMemo(
    () => ({
      //Get/Edit
      actions,
      groups,
      statuses,
      flags,
      fetchActions,
      loading,
      setLoading,
      setActions,
      filters,
      setFilters,
      //Submit
      openAddEditActionDialog,
      setOpenAddEditActionDialog,
      handleOpenAddActionDialog,
      handleOpenEditActionDialog,
      addEdit,
      control,
      errors,
      handleSubmit: handleSubmit(onAddEditAction),
      reset,
      exportCSV,
      setGridRef,
      filteredActionsWithWorkorder,
      removeActionFlag,
      addActionFlag,
      setNewActionFlag,
      newActionFlag,
      removeActionGroup,
      addActionGroup,
      setNewActionGroup,
      newActionGroup,
      removeActionStatus,
      addActionStatus,
      setNewActionStatus,
      newActionStatus,
      sortByStatus,
    }),
    [
      actions,
      groups,
      statuses,
      flags,
      fetchActions,
      loading,
      setLoading,
      setActions,
      filters,
      setFilters,
      openAddEditActionDialog,
      setOpenAddEditActionDialog,
      handleOpenAddActionDialog,
      handleOpenEditActionDialog,
      addEdit,
      control,
      errors,
      handleSubmit,
      reset,
      exportCSV,
      setGridRef,
      filteredActionsWithWorkorder,
      removeActionFlag,
      addActionFlag,
      setNewActionFlag,
      newActionFlag,
      removeActionGroup,
      addActionGroup,
      setNewActionGroup,
      newActionGroup,
      removeActionStatus,
      addActionStatus,
      setNewActionStatus,
      newActionStatus,
      sortByStatus,
    ]
  );

  return <ActionsContext.Provider value={actionsContextValue}>{children}</ActionsContext.Provider>;
};

export const useActions = () => {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('useActions must be used within a ActionsContextProvider');
  }
  return context;
};
