import { useEffect, useState } from 'react';
import { Box, Button, Menu, TextField, Typography, Icon, List, Snackbar, Grid, Checkbox } from '@material-ui/core';
import ActionButton from '../../../components/ActionButton';
import { FilterEntityType, useBookmarks } from './hook/useBookmarks';
import { Bookmark, FilterBaseType } from '../../../types/cwt/bookmarks';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import OkayCancelDialog from '../../../components/OkayCancelDialog';
interface BookmarkMenuProps<T> {
  filter: T;
  setFilters: (filter: T) => void;
  type: FilterEntityType;
}

const BookMarksList = styled(List)`
  position: relative;
  overflow: auto;
  max-height: 200px;
`;

const StyledMenu = styled(Menu)`
  elevation: 0;
  &.MuiMenu-paper {
    border: 1px solid black;
  }
  .MuiMenu-list {
    padding: 0;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  transition: transform 0.2s, color 0.2s;
  color: black;

  &:hover {
    transform: scale(0.8);
    cursor: pointer;
  }
`;

const BookmarkActionButton = styled(ActionButton)`
  &.MuiButton-text {
    word-wrap: break-word; /* Use word-wrap for older browser support */
    word-break: break-all; /* This should work for spans within the button */
  }
  white-space: normal;
  margin: 0;
`;

const MarginTop10pxTypography = styled(Typography)`
  margintop: 10px;
`;

const MarginTop5pxActionButton = styled(ActionButton)`
  margintop: 5px;
`;

const SavedFilterItem = (
  bookmark: Bookmark,
  handleSetFilter: (bookmark: Bookmark) => void,
  setSelectedDeleteBookmark: (id: Bookmark) => void,
  updateDefault: (id: number, value: boolean) => void
) => {
  return (
    <Grid
      key={bookmark.id}
      container
      direction="row"
      alignItems="center"
      style={{ width: '400px' }}
    >
      <Grid
        item
        xs={6}
      >
        <BookmarkActionButton
          name="bookmark"
          value={bookmark.id}
          fullWidth
          onClick={() => handleSetFilter(bookmark)}
        >
          {bookmark.name}
        </BookmarkActionButton>
      </Grid>
      <Grid
        item
        justifyContent="space-between"
        alignItems="center"
        container
        xs={6}
      >
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Checkbox
            checked={bookmark.isDefault}
            onChange={() => updateDefault(bookmark.id, !bookmark.isDefault)}
            color="primary"
          />
          {bookmark.isDefault && <Typography style={{ marginRight: '10px' }}>Default</Typography>}
          {!bookmark.isDefault && <Typography style={{ marginRight: '10px' }}>Set Default</Typography>}
        </Box>

        <StyledDeleteIcon
          data-testid="DeleteIcon"
          onClick={() => setSelectedDeleteBookmark(bookmark)}
        />
      </Grid>
    </Grid>
  );
};

export const BookmarkMenu = <T extends FilterBaseType>({ type, filter, setFilters }: BookmarkMenuProps<T>) => {
  const {
    anchorEl,
    newEntry,
    handleClick,
    handleClose,
    setNewEntry,
    bookmarks,
    handleSetFilter,
    selectedBookmarkId,
    errorMessage,
    saveBookmarkWithValidation,
    apiError,
    setApiError,
    deleteBookmark,
    selectedDeleteBookmark,
    setSelectedDeleteBookmark,
    handleDeleteClose,
    updateDefault,
    clearSelectedBookmark,
  } = useBookmarks(type, filter, setFilters);

  const [renderedBookmarks, setRenderedBookmarks] = useState(bookmarks);

  useEffect(() => {
    setRenderedBookmarks(bookmarks);
  }, [selectedBookmarkId, bookmarks]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleClick}
      >
        <Icon>bookmark</Icon>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          Saved Bookmarks
        </Button>
      </Box>
      <StyledMenu
        id="bookmark-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box
          p={2}
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant="h6"
            gutterBottom
          >
            Save Bookmark
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter Bookmark Name"
            fullWidth
            inputProps={{ maxLength: 100 }}
            value={newEntry || ''}
            onChange={e => setNewEntry(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <ActionButton
            color="primary"
            variant="text"
            style={{ marginTop: '10px' }}
            onClick={saveBookmarkWithValidation}
          >
            Save Bookmark
          </ActionButton>
          {renderedBookmarks.length !== 0 && (
            <>
              <MarginTop10pxTypography
                variant="h6"
                gutterBottom
              >
                Saved Bookmarks
              </MarginTop10pxTypography>

              <BookMarksList>
                {renderedBookmarks.map(bookmark =>
                  SavedFilterItem(bookmark, handleSetFilter, setSelectedDeleteBookmark, updateDefault)
                )}
              </BookMarksList>
              <MarginTop5pxActionButton
                color="primary"
                variant="text"
                onClick={clearSelectedBookmark}
              >
                Clear Filters
              </MarginTop5pxActionButton>
            </>
          )}
        </Box>
        {selectedDeleteBookmark && (
          <OkayCancelDialog
            title={`Confirm Deletion of Bookmark`}
            okayLabel="Delete"
            cancelLabel="Cancel"
            onOkay={async () => {
              await deleteBookmark(selectedDeleteBookmark.id);
            }}
            onCancel={handleDeleteClose}
          >
            Bookmark: {selectedDeleteBookmark.name}
          </OkayCancelDialog>
        )}
      </StyledMenu>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!apiError}
        autoHideDuration={3000}
        onClose={() => {
          setApiError('');
        }}
      >
        <Alert
          onClose={() => {
            setApiError('');
          }}
          severity="error"
        >
          {apiError}
        </Alert>
      </Snackbar>
    </Box>
  );
};
