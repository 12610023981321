import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AttachedWorkorderFlag, WorkOrder, WorkorderFlagDimensions } from '../../../types/cwt/workorder';
import isFeatureEnabled from '../../../utils/feature-flags';
import Api from '../../../api';
import { useWorkOrders } from './useWorkOrders';

interface UseWorkOrderFlagsValue {
  workorderFlags: WorkorderFlagDimensions[];
  setNewWorkflowFlag: (label: string) => void;
  newWorkflowFlag: string;
  addWorkorderFlag: (label: string) => Promise<void>;
  removeWorkorderFlag: (id: number) => Promise<void>;
  updateWorkorderFlags: (workorderId: number) => void;
  selectedWorkorderFlags: number[];
  updateSelectedWorkorderFlags: (selected: number[]) => void;
}

interface Props {
  children: ReactNode;
}

const WorkOrderFlagsContext = createContext<UseWorkOrderFlagsValue | undefined>(undefined);

export const WorkOrderFlagsContextProvider = ({ children }: Props) => {
  const [newWorkflowFlag, setNewWorkflowFlag] = useState('');
  const [workorderFlags, setWorkorderFlags] = useState<WorkorderFlagDimensions[]>([]);
  const [loadingWorkorderFlags, setLoadingWorkorderFlags] = useState<boolean>(true);
  const [selectedWorkorderFlags, setSelectedWorkorderFlags] = useState<number[]>([]);
  const { getWorkorderById, selectedWorkorderId, inPlaceUpdateWorkorder } = useWorkOrders();

  useEffect(() => {
    const getWorkOrderFlags = async () => {
      await getAllWorkorderFlags();
      setLoadingWorkorderFlags(false);
    };
    if (loadingWorkorderFlags) {
      void getWorkOrderFlags();
    }
  }, [loadingWorkorderFlags]);

  useEffect(() => {
    if (isFeatureEnabled('workorderFlags')) {
      const workorder = getWorkorderById(selectedWorkorderId);
      if (workorder) {
        getWorkorderFlags(workorder);
      } else {
        setSelectedWorkorderFlags([]);
      }
    }
  }, [selectedWorkorderId]);

  const getAllWorkorderFlags = async () => {
    if (isFeatureEnabled('workorderFlags')) {
      const res = await Api.cwt.getAllWorkorderFlags();
      setWorkorderFlags(res);
    }
  };

  const addWorkorderFlag = async (label: string) => {
    if (isFeatureEnabled('workorderFlags')) {
      await Api.cwt.addWorkorderFlag(label);
      setNewWorkflowFlag('');
      setLoadingWorkorderFlags(true);
    }
  };

  const removeWorkorderFlag = async (id: number) => {
    if (isFeatureEnabled('workorderFlags')) {
      await Api.cwt.disableWorkorderFlag(id);
      setLoadingWorkorderFlags(true);
    }
  };

  const getWorkorderFlags = (workorderToRetriveFlags: WorkOrder) => {
    const flagsInSelectedWorkorder = workorderToRetriveFlags.workorderFlags.map(
      (flag: AttachedWorkorderFlag) => flag.flagId
    );
    setSelectedWorkorderFlags(flagsInSelectedWorkorder ?? []);
  };

  const updateSelectedWorkorderFlags = (selected: number[]) => {
    if (isFeatureEnabled('workorderFlags')) {
      setSelectedWorkorderFlags(selected);
    }
  };

  const updateWorkorderFlags = async (workorderId: number) => {
    if (isFeatureEnabled('workorderFlags')) {
      await Api.cwt.updateWorkorderFlags(workorderId, selectedWorkorderFlags);
      const WorkorderFlags = await Api.cwt.getWorkorderFlags(workorderId);
      inPlaceUpdateWorkorder(workorderId, WorkorderFlags);
    }
  };

  return (
    <WorkOrderFlagsContext.Provider
      value={{
        workorderFlags,
        newWorkflowFlag,
        setNewWorkflowFlag,
        addWorkorderFlag,
        removeWorkorderFlag,
        updateWorkorderFlags,
        selectedWorkorderFlags,
        updateSelectedWorkorderFlags,
      }}
    >
      {children}
    </WorkOrderFlagsContext.Provider>
  );
};

export const useWorkOrderFlags = () => {
  const context = useContext(WorkOrderFlagsContext);
  if (context === undefined) {
    throw new Error('useWorkOrderFlags must be used within a WorkOrdersFlagsContextProvider');
  }
  return context;
};
