import React from 'react';
import styled from 'styled-components';
import UnstyledTableBody from '@material-ui/core/TableBody';
import UnstyledRow from './Row';
import ActionTableRoot from './ActionTableRoot';

const Row = styled(UnstyledRow)`
  &.hidden-row {
    /*
      hidden due to complexity around action-vibility & reordering.  Need
      maintain the DOM so the indexing works.  At the very least anything that
      is hidden-row is converted to return null should test reordering to ensure
      it sends the values to the API correctly (currently stored procedure only
      writes correctly some of the time)
    */
    display: none; // Hide rows with this class
  }
`;

const TableBody = styled(UnstyledTableBody)`
  ${Row}.programmatic-even-nth-cell-style,
  ${Row}.auto-nth-cell-style:nth-child(1n) {
    background-color: ${({ theme }) => theme.colours.white};
  }
  ${Row}.programmatic-even-nth-cell-style .border-2px-as-background-color,
  ${Row}.auto-nth-cell-style:nth-child(1n) .border-2px-as-background-color {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colours.white};
  }
  ${Row}.programmatic-odd-nth-cell-style,
  ${Row}.auto-nth-cell-style:nth-child(2n) {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey8};
  }
  ${Row}.programmatic-odd-nth-cell-style .border-2px-as-background-color,
  ${Row}.auto-nth-cell-style:nth-child(2n) .border-2px-as-background-color {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colours.greys.lightGrey8};
  }
`;

const StaticActionTable = ({ className, columns, actions, rowIterator }) => {
  const rows = actions.map((action, i) => {
    const {
      rowCssClass = '',
      renderPropBefore,
      renderPropAfter,
    } = typeof rowIterator === 'function' ? rowIterator({ actions, action, i }) : {};
    return (
      <Row
        key={`static-row-for-action-${action.id}`}
        action={action}
        columns={columns}
        className={`${rowCssClass ? `${rowCssClass}` : 'auto-nth-cell-style'}`}
        renderPropBefore={renderPropBefore}
        renderPropAfter={renderPropAfter}
      />
    );
  });

  return (
    <ActionTableRoot
      className={className}
      columns={columns}
    >
      <TableBody>{rows}</TableBody>
    </ActionTableRoot>
  );
};

export default StaticActionTable;
