import styled from 'styled-components';
import DropdownAutocomplete from './DropdownAutocomplete';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useDashboardPage } from '../hooks/useDashboardPage';
import PowerBiReport from '../../../components/PowerBiReport';
import * as R from 'ramda';
import { IndicatorProvider } from '../../../hooks/indicator';
import UnstyledRecordsTableModule from '../../../components/ReviewRecordsModule';
import IndicatorSelector from './IndicatorSelector';
import IndicatorScoresTable from './IndicatorScoresTable';
import { getQuarter, getYear } from 'date-fns';
import UnstyledActionButton from '../../../components/ActionButton';
import { useState } from 'react';

const ElementBox = styled(Box)`
  margin-bottom: 20px;
  width: 300px;
`;

const HeaderBox = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colours.purples.purple1};
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  p {
    margin-left: 20px;
  }
`;

const RecordsBox = styled(Box)`
  border: ${({ theme }) => `1px solid ${theme.colours.greys.lightGrey3}`};
  border-radius: 4px;
  min-height: 100%;
`;

const RecordsTableModule = styled(UnstyledRecordsTableModule)`
  p.MuiTypography-root {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const RecordsReviewBox = styled(Box)`
  max-height: 350px;
  overflow-y: scroll;
`;

const TablesWrapperBox = styled(Box)`
  max-height: 400px;
  overflow-y: hidden;
  margin-bottom: 20px;
`;

const ActionButton = styled(UnstyledActionButton)`
  margin-left: 20px;
  max-height: 40px;
`;

const DashboardContent = () => {
  const {
    elements,
    selectedElement,
    handleSetSelectedElement,
    activeView,
    loadingElements,
    indicator,
    showFunctionalFailures,
    showOutstandingCorrectiveActions,
  } = useDashboardPage();

  const reportId = R.pathOr('', ['echoConfig', 'peraPowerBiReportId'], window);
  const reportTitle = 'PERA PowerBI Report';

  const year = getYear(new Date());
  const quarter = getQuarter(new Date());

  const [showCurrentHealth, setShowCurrentHealth] = useState(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
      >
        {loadingElements && <CircularProgress size={20} />}
        {!loadingElements && (
          <>
            <ElementBox>
              <DropdownAutocomplete
                onChange={(val: string) => {
                  const elementId = Number(val);
                  const elementName = elements.find(({ value }) => elementId === value)?.label ?? '';
                  handleSetSelectedElement({ elementId, elementName });
                  setShowCurrentHealth(false);
                }}
                label="Element Name"
                options={elements}
                value={selectedElement?.elementId?.toString()}
              />
            </ElementBox>
            <ActionButton
              color="primary"
              variant="text"
              onClick={() => setShowCurrentHealth(true)}
              disabled={showCurrentHealth}
            >
              Generate Current Health
            </ActionButton>
          </>
        )}
      </Box>

      {selectedElement && (
        <TablesWrapperBox>
          <Grid container>
            {showCurrentHealth && (
              <Grid
                item
                xs={3}
              >
                <RecordsBox>
                  <HeaderBox>
                    <Typography>CURRENT HEALTH</Typography>
                  </HeaderBox>
                  {selectedElement && (
                    <IndicatorScoresTable
                      selectedElement={selectedElement}
                      year={year}
                      quarter={quarter}
                    />
                  )}
                </RecordsBox>
              </Grid>
            )}
            <Grid
              item
              xs={showCurrentHealth ? 9 : 12}
            >
              <RecordsBox>
                <HeaderBox>
                  <Typography>RECORDS REVIEW</Typography>
                </HeaderBox>
                <Box>
                  <IndicatorSelector
                    activeView={activeView}
                    onShowFunctionalFailures={showFunctionalFailures}
                    onShowOutstandingCorrectiveActions={showOutstandingCorrectiveActions}
                  />
                  {indicator && selectedElement && (
                    <RecordsReviewBox>
                      <IndicatorProvider indicatorId={indicator.id}>
                        <RecordsTableModule
                          elementId={selectedElement.elementId}
                          quarter={quarter}
                          year={year}
                        />
                      </IndicatorProvider>
                    </RecordsReviewBox>
                  )}
                </Box>
              </RecordsBox>
            </Grid>
          </Grid>
        </TablesWrapperBox>
      )}
      <HeaderBox>
        <Typography>PREDICTIVE EQUIPMENT RELIABILITY ANALYTICS (PERA) SUMMARY</Typography>
      </HeaderBox>
      <PowerBiReport
        title={reportTitle}
        reportId={reportId}
        width="auto"
        height={638}
      />
    </>
  );
};

export default DashboardContent;
