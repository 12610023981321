import React from 'react';
import styled from 'styled-components';
import UnstyledListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UnstyledList from '@material-ui/core/List';
import UnstyledSubNavItem from './SubNavItem';
import UnstyledActionItem from './ActionItem';
import { matchPath } from 'react-router-dom';
import isFeatureEnabled from '../../utils/feature-flags';

const ListItem = styled(UnstyledListItem)``;

const SubNavItem = styled(UnstyledSubNavItem)``;

const ActionItem = styled(UnstyledActionItem)``;

const SubList = styled(UnstyledList)`
  padding: 0px;
  margin-bottom: 4px;

  ${SubNavItem}, ${ActionItem} {
    &.MuiListItem-root {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;

const entryToItem = (entry, i) => {
  switch (entry.type) {
    case 'nav': {
      return (
        <SubNavItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
          isSelected={
            isFeatureEnabled('fixMenuHighlight')
              ? entry.subPaths?.some(subPath => matchPath(location.pathname, subPath))
              : undefined
          }
        />
      );
    }
    case 'action': {
      return (
        <ActionItem
          key={`${i}:${entry.label}`}
          onClick={entry.action}
          iconComponent={entry.icon}
          label={entry.label}
          subMenu
        />
      );
    }
    default: {
      return (
        <ListItem key={`${i}:${entry.type}`}>
          <ListItemText>Type ({entry.type}) Unknown</ListItemText>
        </ListItem>
      );
    }
  }
};

const SubMenu = ({ entries }) => {
  const items = entries.map(entryToItem);

  return <SubList>{items}</SubList>;
};

export default SubMenu;
