import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scorecard } from '@repo/echo-scorecard';

import Api from '../api';
import * as scorecardActions from '../state/scorecard/actions';
import * as uiActions from '../state/ui/actions';
import { UI_MESSAGE } from '../utils/ui-messages';
import { ElementId } from '../types/app';

export interface TotalValueState {
  value: number;
  comment: string;
  colour?: string;
}

interface Props {
  elementId: ElementId;
  year: number;
  quarter: number;
  scorecard: Scorecard;
}

const useEditManualUnitTotalValue = ({ elementId, year, quarter, scorecard }: Props) => {
  const dispatch = useDispatch();

  const [manualUnitTotalValue, setManualUnitTotalValue] = useState<null | TotalValueState>(null);
  const [unitId, setUnit] = useState<string>('');
  const editManualUnitTotalValue = useCallback(
    (unit: string) => {
      const unitTotalValue = scorecard.totals.byUnit[unit];
      setManualUnitTotalValue({
        value: unitTotalValue?.score,
        colour: unitTotalValue?.colour,
        comment: unitTotalValue?.comment ? unitTotalValue?.comment : '',
      });
      setUnit(unit);
    },
    [scorecard]
  );

  const cancelEditManualUnitTotalValue = useCallback(() => {
    setManualUnitTotalValue(null);
    setUnit('');
  }, []);

  const saveManualUnitTotalValue = useCallback(
    async (value: number, colour?: string, comment?: string) => {
      try {
        await Api.overrideUnitTotalValue(elementId, year, quarter, unitId, value, colour, comment);
        setManualUnitTotalValue(null);
        setUnit('');
        dispatch(scorecardActions.loadScorecardRequest(elementId, year, quarter)); // TODO: request scorecard
      } catch (e: any) {
        dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
      }
    },
    [dispatch, elementId, year, quarter, unitId]
  );

  const clearManualUnitTotalValue = useCallback(async () => {
    try {
      await Api.clearOverrideUnitTotalValue(elementId, year, quarter, unitId);
      setManualUnitTotalValue(null);
      setUnit('');
      dispatch(scorecardActions.loadScorecardRequest(elementId, year, quarter));
    } catch (e: any) {
      dispatch(uiActions.error(e, UI_MESSAGE.SERVER_ERROR));
    }
  }, [dispatch, elementId, year, quarter, unitId]);

  return {
    manualUnitTotalValue,
    editManualUnitTotalValue,
    cancelEditManualUnitTotalValue,
    saveManualUnitTotalValue,
    clearManualUnitTotalValue,
  };
};

export default useEditManualUnitTotalValue;
