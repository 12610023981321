import { Facility, Unit } from '../utils/units';
import { User } from './user';

type IncludingNARadioInput = 'yes' | 'no' | 'notApplicable';
type RadioInput = 'yes' | 'no';

export type LabelOptions = {
  label: string;
  value: string;
};

export type Risk = Pick<RiskOwnerFormInputs, 'riskTitle'> & RiskManagementStatus;

export interface RiskManagementStatus {
  riskManagementId: number;
  riskTitle: string;
  status: string;
  elementId: string;
  statusDate: string;
  decisionType?: DecisionTypeRiskApprover;
  riskApproverName: string;
  prevDecisionType?: DecisionTypeRiskApprover;
  prevApproverName: string;
  originalRiskManagementForm: number;
  riskOwnerName: string;
  envContactName: string;
  isRiskOwner?: boolean;
  isRSE?: boolean;
  requestedRiskApprovers: string[];
  approverEdited?: boolean;
  riskOwnerUpdatedAt?: string;
  riskOwnerUpdatedBy?: string;
  riskOwnerUpdatedByName?: string;
  awaitingEscalation: boolean;
  awaitingEscalationTo: string;
  awaitingEscalationToName: string;
}

export type RiskCategories = {
  mcrRelatedRisk: boolean;
  environmentalSafety: boolean;
  industrialSafety: boolean;
  productionCommercialFinancial: boolean;
  radiologicalSafety: boolean;
  reactorSafety: boolean;
  regulatoryCompliance: boolean;
  reputationRisk: boolean;
  equipmentReliabilityGeneration: boolean;
};

export interface RiskEnvCategories {
  [key: string]: boolean;
}

export interface RiskSigEnvActivities {
  [key: string]: boolean;
}

export type InterestedParties = {
  cnsc: boolean;
  environmentRegulators: boolean;
  governmentOther: boolean;
  hydroOne: boolean;
  ieso: boolean;
  indigenousGroup: boolean;
  industryOther: boolean;
  media: boolean;
  opg: boolean;
  other: boolean;
  partners: boolean;
  publicParty: boolean;
};

export type ContingencyPlanningStatus = 'Not Started' | 'In Development' | 'Ready' | 'Not Applicable';

export type RiskOwnerFormInputs = {
  riskTitle: string;
  elementId: number;
  description: string;
  opportunityOrThreat: string;
  engineeringEquipmentPerformance: string;
  endOfLife: IncludingNARadioInput;
  overlifePotentialCommercialImpact: number;
  overlifeImpactDescription: string;
  bridgingStrategy: IncludingNARadioInput;
  bridgingStrategyDescription: string;
  spv: IncludingNARadioInput;
  spvEliminationOrMitigation: string;
  spvDescription: string;
  criticalStrategicSpares: IncludingNARadioInput;
  criticalDescription: string;
  obsolescenceIssue: IncludingNARadioInput;
  obsolescenceDescription: string;
  changesSinceLastQ: string;
  currentStatusRating: string;
  currentStatusComment: string;
  contingencyPlanningStatus: ContingencyPlanningStatus | '';
  contingencyPlanningDate: string | null;
  contingencyPlanDescription: string | null;
  businessObjectives: string;
  msmProgram: string[];
  plannedOutageReference: string[];
  climateChange: RadioInput;
  climateDescription: string;
  facility: Facility | '';
  units: Unit[];
  riskCategories: RiskCategories;
  riskImpactMcr: string;
  reputationDescription: string;
  responseType: string;
  businessPlanDescription: string;
  estimationDescription: string;
  otherInfoDescription: string;
  interestedParties: InterestedParties;
  rsaArcherRskRecordReference?: string;
  riskApprovers?: UserWithRoles[];
  recommendedDecisionType?: string;
  rejectionNotes?: string;
  healthScore?: number;
  healthScoreColor?: string;
  requestedRiskApprover: string;
};

export interface RiskEnvironmentGroupFormInputs {
  continuousMonitoring: boolean;
  environmentCategories: RiskEnvCategories;
  significantEnvironmentalActivities: RiskSigEnvActivities;
  envConsequenceDescription: string;
  envTeamContacts: string;
  consequence: number;
  probability: number;
  peerGroupOversight: 'Yes' | 'No' | 'N/A';
}

export type DecisionTypeRiskApprover =
  | 'acceptAndMonitor'
  | 'acceptAndClose'
  | 'rejectAndResubmit'
  | 'rejectAndClose'
  | 'editByApprover'
  | 'none';

export type RiskApproverFormInputs = {
  escalateIssue: RadioInput | null;
  escalateIssueRationale: string;
  decisionType: DecisionTypeRiskApprover | null;
  decisionTypeDescription: string | null;
  flagToCno: RadioInput | null;
  cnoDescription: string;
  cnoBridgingStrategy: string;
  cnoStatusUpdate: string;
  riskResponseSummary: string;
  escalateUsers: string[];
  escalateTo: string;
  recommendedDecisionType: DecisionTypeRiskApprover | null;
  recommendedDecisionTypeDescription: string;
};

export type RiskApproverTableFields = {
  newApproverForm: boolean;
} & Omit<RiskApproverFormInputs, 'recommendedDecisionType' | 'recommendedDecisionTypeDescription'>;

export type RiskEscalationRoles = 'sectionManager' | 'departmentManager' | 'divisionManager' | 'svp' | 'cno';

export type RiskEscalationFormInputs = {
  escalationRationale: string;
  riskDescription: string;
  riskBridgingStrategy: string;
  riskStatusUpdate: string;
} & Omit<
  RiskApproverFormInputs,
  | 'escalateIssue'
  | 'decisionType'
  | 'decisionTypeDescription'
  | 'flagToCno'
  | 'escalateUsers'
  | 'escalateIssueRationale' //-DEBUG FIX?
  | 'cnoDescription'
  | 'cnoBridgingStrategy'
  | 'cnoStatusUpdate'
>;

export type RiskEscalationTableFields = {
  riskManagementId: number;
  escalationWorkflowStep: number;
  escalateToRole: RiskEscalationRoles;
  createdBy: string;
  createdAt: string;
} & RiskEscalationFormInputs;

export interface ImpactProbabilityAssessment {
  year: number[];
  probability: number;
  environmentalSafety: number;
  industrialSafety: number;
  radiologicalSafety: number;
  reactorSafety: number;
  reputationalImpact: number;
  regulatoryCompliance: number;
  daysForcedOutageForProductionImpact: number;
  unitsForProductionImpact: number;
  commercialImpactForAllAffectedUnits: number;
  probabilityRate: number;
  worstCaseTotalCommercialImpact: number;
}

export interface ImpactProbabilityAssessmentDetail {
  commercialImpactForAllAffectedUnits: number;
  daysForcedOutageForProductionImpact: number;
  worstCaseTotalCommercialImpact: number;
  environmentalSafetyCost: number;
  industrialSafetyCost: number;
  probability: number;
  probabilityRate: string;
  radiologicalSafetyCost: number;
  reactorSafetyCost: number;
  regulatoryComplianceCost: number;
  reputationalImpactCost: number;
  unitsForProductionImpact: number;
  year: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  forcedOutageRate: number;
}
export enum USER_ROLES {
  APPROVER = 'approver',
  ENVIRONMENT_GROUP = 'env_group',
  ENV_GROUP_MANAGER = 'env_group_manager',
  MCR_CONTACT = 'mcr_contact',
  GLOBAL_CONTACT = 'global_contact',
}

export type UserWithRoles = User & {
  roles: USER_ROLES[];
};

export interface RiskManagementConstants {
  IMPACT_PROBABILITY: {
    FORCED_OUTAGE_RATE: Record<number, number>;
    POS_IMPACT_SCALE: ImpactScale[];
    NEG_IMPACT_SCALE: ImpactScale[];
    PROBABILITY_OPTIONS: ProbabilityOption[];
  } | null;
}
export interface ImpactScale {
  label: string;
  value: number;
  cost: number;
}

export interface ForcedOutageRate {
  [year: number]: number;
}

export interface ProbabilityOption {
  id: number;
  label: string;
  value: number;
  rate: string;
}
