import React, { useEffect, useState } from 'react';

import tableColumns from './table-columns';
import ReorderableActionTable from './ReorderableActionTable';
import StaticActionTable from './StaticActionTable';
import Api from '../../api';
import { useActionVisibility } from '../../context/action-visibility';
import LoadingContainer from '../LoadingContainer';
import isFeatureEnabled from '../../utils/feature-flags';
interface MinActionTableProps {
  reorderable?: any;
  state?: string;
  onChangeStatus?: any;
  onEdit?: any;
  onDelete?: any;
  element?: any;
  completionNotes?: boolean;
  isReadOnly?: boolean;
}

type ActionTableProps = {
  completionNotes?: boolean;
  actions: any[];
  reorderable?: boolean;
  reorderRows?: any;
  className?: string;
  rowIterator?: any;
  rowHeaderIterator?: any;
} & MinActionTableProps;
type ActionTableWithVisibilityContextProps = Omit<ActionTableProps, 'state' | 'actions'> & { isLoading?: boolean };
const ActionTable = ({
  className,
  completionNotes = true,
  element,
  onChangeStatus,
  onDelete,
  onEdit,
  reorderable,
  reorderRows,
  state = '',
  rowIterator,
  rowHeaderIterator,
  isReadOnly = false,
  ...props
}: ActionTableProps) => {
  const columnOptions = {
    showSHFR: element?.elementType !== 'program',
    state,
  };

  const [risks, setRisks] = useState<string[]>([]);

  useEffect(() => {
    const fetchRisks = async () => {
      const res = await Api.getAllRisks(element?.elementId);
      setRisks(res.risks.map(({ riskManagementId }: any) => riskManagementId.toString()));
    };
    if (element?.elementId) {
      fetchRisks();
    }
  }, [element?.elementId]);

  const allColumns = tableColumns({
    state,
    onEdit,
    onChangeStatus,
    onDelete,
    risks,
    columnOptions,
    rowHeaderIterator,
    rowIterator,
    isReadOnly,
    actions: props.actions,
  });

  const columns = completionNotes ? allColumns : allColumns.filter(column => column.label !== 'Completion Notes');

  return reorderable ? (
    <ReorderableActionTable
      columns={columns}
      reorderRows={reorderRows}
      className={className}
      rowIterator={isFeatureEnabled('riskActionHiddenToggle') ? rowIterator : undefined}
      {...props}
    />
  ) : (
    <StaticActionTable
      columns={columns}
      className={className}
      rowIterator={isFeatureEnabled('riskActionHiddenToggle') ? rowIterator : undefined}
      {...props}
    />
  );
};
const emptyOnEventHandler = () => null;
export const ActionTableWithVisibilityContext = (props: ActionTableWithVisibilityContextProps) => {
  const isLoading = !!props.isLoading;
  const {
    isReadOnly,
    actions,
    actionState,
    renderPropsEmptyResults: EmptyResults,
    renderPropSeeHidden: SeeHidden,
    renderPropsNewMessageNotification: NewMessageNotification,
    passiveLoadingControl,
    rowIterator,
    rowHeaderIterator,
  } = useActionVisibility();
  if (!actionState) {
    return null;
  }

  return (
    <>
      {SeeHidden && <SeeHidden />}
      <LoadingContainer
        loading={typeof passiveLoadingControl === 'function' ? passiveLoadingControl(isLoading, actions) : isLoading}
      >
        {(actions || []).length === 0 ? (
          EmptyResults ? (
            <EmptyResults />
          ) : (
            'No actions found'
          )
        ) : (
          <ActionTable
            {...props}
            isReadOnly={isReadOnly}
            onChangeStatus={isReadOnly ? emptyOnEventHandler : props.onChangeStatus}
            onDelete={isReadOnly ? emptyOnEventHandler : props.onDelete}
            onEdit={isReadOnly ? emptyOnEventHandler : props.onEdit}
            rowIterator={rowIterator}
            rowHeaderIterator={rowHeaderIterator}
            actions={actions || []}
            state={actionState}
          />
        )}
        {NewMessageNotification && <NewMessageNotification />}
      </LoadingContainer>
    </>
  );
};
export default ActionTable;
