import { MyElement } from '../../../../types/my';
import ShipChipTable from '../../../../components/ShipChip/ShipChipTable';
import TopPanel from './components/TopPanel';
import { useShipsChips } from './hooks/useShipsChips';

interface Props {
  element: MyElement;
}

const ShipChipLayout: React.FC<Props> = ({ element }) => {
  const {
    actions,
    clearDeleteAction,
    clearEditAction,
    clearNewAction,
    confirmActionStatusToggle,
    confirmDeleteActionToggle,
    deleteAction,
    deleteActionStatus,
    editAction,
    handleOnCancel,
    handleOnOkay,
    isLoading,
    newAction,
    reorderActions,
    saveNewAction,
    setComment,
    setState,
    source,
    setSource,
    showEditActionDialog,
    showNewActionDialog,
    state,
    statusAction,
    updateAction,
  } = useShipsChips({ element });
  return (
    <>
      <TopPanel
        elementId={element.elementId}
        state={state}
        onChangeStatus={setState}
        source={source}
        onNew={showNewActionDialog}
        onChangeSource={setSource}
      />
      <ShipChipTable
        actions={actions}
        clearDeleteAction={clearDeleteAction}
        clearEditAction={clearEditAction}
        clearNewAction={clearNewAction}
        confirmActionStatusToggle={confirmActionStatusToggle}
        confirmDeleteActionToggle={confirmDeleteActionToggle}
        deleteAction={deleteAction}
        deleteActionStatus={deleteActionStatus}
        editAction={editAction}
        element={element}
        handleOnCancel={handleOnCancel}
        handleOnOkay={handleOnOkay}
        isLoading={isLoading}
        newAction={newAction}
        reorderActions={reorderActions}
        saveNewAction={saveNewAction}
        setComment={setComment}
        showEditActionDialog={showEditActionDialog}
        state={state}
        statusAction={statusAction}
        updateAction={updateAction}
      />
    </>
  );
};

export default ShipChipLayout;
