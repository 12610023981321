import React from 'react';
import styled from 'styled-components';
import UnstyledMenuItem from './MenuItem';

const MenuItem = styled(UnstyledMenuItem)`
  padding-left: 96px;
`;

const ActionItem = ({ className, onClick, iconComponent, label, subMenu }) => (
  <MenuItem
    className={className}
    onClick={onClick}
    subMenu={subMenu}
    iconComponent={iconComponent}
    label={label}
  />
);

export default ActionItem;
