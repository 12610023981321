/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { useCallback, useState } from 'react';
import styled from 'styled-components';
import UnstyledDrawer from '@material-ui/core/Drawer';
import { Route } from 'react-router-dom';
import UnstyledMenu from './Menu';
import UnstyledEchoLogo from './EchoLogo';
import UnstyledHeader from './Header';
import Messages from './Messages';
import { routes } from './menu-items';
import SideDrawer from './SideDrawer';
import { Fab } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import API from '../../api';
import isFeatureEnabled from '../../utils/feature-flags';

const OldEchoLogo = styled(UnstyledEchoLogo)`
  margin: 41px auto 0px 40px;
`;
const EchoLogo = styled(UnstyledEchoLogo)`
  margin: 42px 26px 14px 30px;
`;

const Menu = styled(UnstyledMenu)``;
const Header = styled(UnstyledHeader)``;

const Footer = styled.div`
  background-color: ${({ theme }) => theme.colours.menu.background};
`;

const Main = styled.main``;

const DRAWER_WIDTH = '231px';

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${DRAWER_WIDTH});
  overflow-x: hidden;

  ${Header} {
    height: 32px;
  }

  ${Main} {
    margin-top: 3px;
  }
`;

const Container = styled.div`
  display: flex;

  ${Content} {
    margin: 15px 20px 20px 20px;
  }
`;

const Drawer = styled(UnstyledDrawer)`
  width: ${DRAWER_WIDTH};
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: ${DRAWER_WIDTH};
    background-color: ${({ theme }) => theme.colours.menu.background};
  }

  .hr {
    margin: 32px 0px 0px 28px;
    border-bottom: ${({ theme }) => theme.borders.thin.solid5};
  }

  ${Menu} {
    margin-top: 25px;
  }

  ${Footer} {
    position: sticky;
    padding: 30px 0px;
    bottom: 0px;
    left: 0px;
  }
`;

const DefaultLayout = () => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);

  const routeItems = routes.map(route => (
    <Route
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

  const sendMaximoTest = useCallback(async () => {
    try {
      const response = await API.testMaximo();
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container>
      <Drawer
        variant="permanent"
        anchor="left"
      >
        {isFeatureEnabled('navImprovementUI') ? (
          <EchoLogo />
        ) : (
          <>
            <OldEchoLogo />
            <div className="hr" />
          </>
        )}
        <Menu />
        {isFeatureEnabled('maximoTest') && (
          <Fab onClick={sendMaximoTest}>
            <Send />
          </Fab>
        )}
      </Drawer>
      <Content>
        <Header onIconClick={() => setOpenDrawer(!isOpenDrawer)} />
        <Main>
          {routeItems}
          <SideDrawer open={isOpenDrawer} />
        </Main>
      </Content>
      <Messages />
    </Container>
  );
};

export default DefaultLayout;
