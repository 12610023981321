import { createContext, useContext } from 'react';
import type { ActionVisibility, LocationState, RequireInL, RequireInR, RequireInT } from './types';
import type { ActionState, ElementAction } from '../../types/actions';
import type { ReorderActionsRequestParams } from '../../state/actions/actions';

export type ActionVisibilityContextInitalizer = ActionVisibility<
  ElementAction & RequireInT,
  LocationState<ActionState> & RequireInL<ActionState>,
  ReorderActionsRequestParams & RequireInR,
  ActionState
>;
export const defaultContext = {
  isReadOnly: true,
  passiveLoadingControl: (isLoading: boolean) => isLoading,
  renderPropsEmptyResults: () => null,
  renderPropsNewMessageNotification: () => null,
  renderPropSeeHidden: () => null,
  renderPropVisibilityIcon: () => null,
  renderPropLoadingProgress: () => null,
  renderPropInformationBox: () => null,
  ongoingActions: null,
  completedActions: null,
  reorderRequest: null,
  visibilityUpdating: null,
  actions: null,
  staleData: null,
  isShowingShowingNewMessage: false,
  isShowingHiddenActions: false,
  isReorderingLocked: false,
  isLoading: true,
  isError: false,
  updateUiState: () => undefined,
  uiState: {},
};

export const ActionVisibilityContext = createContext<ActionVisibilityContextInitalizer>(defaultContext);
export const useActionVisibility = () => {
  const context = useContext(ActionVisibilityContext);
  if (!context) {
    throw new Error('useActionVisibility must be used inside an ActionVisibilityProvider');
  }
  return context as ActionVisibilityContextInitalizer;
};
