import React from 'react';
import styled from 'styled-components';
import FilterButton from '../FilterButton';
import UnstyledActionButton from '../ActionButton';
import { ActionSource } from '../../types/actions';
import UnstyledDropdown from '../Dropdown';
import { ButtonGroup as UnstyledButtonGroup } from '@material-ui/core';
import type { ActionState } from '../../types/actions';
import isFeatureEnabled from '../../utils/feature-flags';

const ActionButton = styled(UnstyledActionButton)``;
const Dropdown = styled(UnstyledDropdown)``;
const ButtonGroup = styled(UnstyledButtonGroup)``;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;

  ${ButtonGroup} {
    height: 33px;
  }
  ${ActionButton} {
    height: 33px;
  }
  ${Dropdown} {
    width: 200px;
  }
`;

export interface ActionFilterPanelProps {
  className?: string;
  state: ActionState;
  onChangeStatus(status: ActionState): void;
  source: ActionSource;
  onChangeSource: (source: ActionSource) => void;
  onAddNew?: () => void;
  isShowingHiddenActions?: boolean;
}

const ActionFilterPanel = ({
  className,
  state,
  source,
  onChangeStatus,
  onChangeSource,
  onAddNew,
  isShowingHiddenActions,
}: ActionFilterPanelProps) => {
  const handleClickStatus = (newStatus: ActionState) => () => {
    onChangeStatus(newStatus);
  };
  let isShowOngoing = state === 'ongoing';
  let isShowCompleted = state === 'complete';
  if (isFeatureEnabled('riskActionHiddenToggle') && typeof isShowingHiddenActions !== 'undefined') {
    isShowOngoing = isShowingHiddenActions || state === 'ongoing';
    isShowCompleted = isShowingHiddenActions || state === 'complete';
  }
  return (
    <Container className={className}>
      {isFeatureEnabled('actionSource') && (
        <Dropdown
          label="System Source"
          labelPosition="top"
          options={[
            { id: 1, label: 'All', value: 'All' },
            { id: 2, label: 'Health', value: 'Health' },
            { id: 3, label: 'Risk', value: 'Risk' },
          ]}
          onChange={onChangeSource}
          value={source}
        />
      )}
      <ButtonGroup>
        <FilterButton
          onClick={handleClickStatus('ongoing')}
          isActive={isShowOngoing}
          disabled={isFeatureEnabled('riskActionHiddenToggle') && isShowingHiddenActions}
        >
          Ongoing Actions
        </FilterButton>
        <FilterButton
          onClick={handleClickStatus('complete')}
          isActive={isShowCompleted}
          disabled={isFeatureEnabled('riskActionHiddenToggle') && isShowingHiddenActions}
        >
          Completed Actions
        </FilterButton>
      </ButtonGroup>
      {onAddNew && (
        <ActionButton
          color="secondary"
          onClick={onAddNew}
        >
          Add Action
        </ActionButton>
      )}
    </Container>
  );
};

export default ActionFilterPanel;
