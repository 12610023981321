import { type ActionState } from '../../types/actions';
import * as actionTypes from '../action-types';
import errorAction from '../utils/error-action';

export const loadActionsRequest = (
  elementId: string | number,
  state: string,
  riskManagementId?: number,
  isAjaxRequestHandled?: boolean
) => ({
  type: actionTypes.ACTIONS_LOAD_ALL_REQUEST,
  payload: {
    ...(typeof isAjaxRequestHandled === 'boolean' ? { isAjaxRequestHandled } : {}),
    ...(riskManagementId ? { riskManagementId } : {}),
    elementId,
    state,
  },
});
type LoadActionsSuccessParams = { elementId: string; state: string; actions: unknown[]; riskManagementId?: number };
export const loadActionsSuccess = ({ elementId, state, actions, riskManagementId }: LoadActionsSuccessParams) => ({
  type: actionTypes.ACTIONS_LOAD_ALL_SUCCESS,
  payload: {
    ...(riskManagementId ? { riskManagementId } : {}),
    elementId,
    actions,
    state,
  },
});

export const loadActionsFailure = errorAction(actionTypes.ACTIONS_LOAD_ALL_FAILURE);

export const createActionRequest = (action: unknown) => ({
  type: actionTypes.ACTIONS_CREATE_REQUEST,
  payload: {
    action,
  },
});

export const createActionFailure = errorAction(actionTypes.ACTIONS_CREATE_FAILURE);

export const updateActionRequest = (action: unknown) => ({
  type: actionTypes.ACTIONS_UPDATE_REQUEST,
  payload: {
    action,
  },
});

export const updateActionSuccess = () => ({
  type: actionTypes.ACTIONS_UPDATE_SUCCESS,
  payload: {
    message: 'Action updated',
  },
});

export const updateActionFailure = errorAction(actionTypes.ACTIONS_UPDATE_FAILURE);
export type ReorderActionsRequestParams = {
  actionId: number;
  fromIndex: number;
  toIndex: number;
  state: ActionState;
  elementId: string;
  riskManagementId?: number;
};
export const reorderActionsRequest = ({
  actionId,
  fromIndex,
  toIndex,
  state,
  elementId,
  riskManagementId,
}: ReorderActionsRequestParams) => ({
  type: actionTypes.ACTIONS_REORDER_REQUEST,
  payload: {
    ...(riskManagementId ? { riskManagementId } : {}),
    actionId,
    fromIndex,
    toIndex,
    state,
    elementId,
  },
});

export const reorderActionsFailure = errorAction(actionTypes.ACTIONS_REORDER_FAILURE);

export const updateActionStatusRequest = (actionId: string, comment: string, state: string, elementId: number) => ({
  type: actionTypes.ACTIONS_UPDATE_STATUS_REQUEST,
  payload: {
    actionId,
    comment,
    state,
    elementId,
  },
});

export const actionClosed = () => ({
  type: actionTypes.ACTIONS_ACTION_CLOSED,
  payload: {
    message: 'Action marked complete',
  },
});

export const actionReopened = () => ({
  type: actionTypes.ACTIONS_ACTION_REOPENED,
  payload: {
    message: 'Action re-opened',
  },
});

export const actionDeleted = () => ({
  type: actionTypes.ACTIONS_ACTION_DELETED,
  payload: {
    message: 'Action Deleted',
  },
});

export const updateActionStatusFailure = errorAction(actionTypes.ACTIONS_UPDATE_STATUS_FAILURE);
