import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WorkOrder, WorkorderDelayReason } from '../../../../../../types/cwt/workorder';

import api from '../../../../../../api';

interface UseWorkorderDelayReasonsValue {
  workorderDelayReasons: WorkorderDelayReason[];
  onSave: (data: any) => void;
  onEditComplete: ({ data }: any) => void;
  onEditValueChange: ({ value, columnId, data }: any) => void;
  handleAddDelayReason: () => void;
  handleDeleteWorkorderDelayReason: (id: number) => void;
  canAddDelayReason: boolean;
}

interface Props {
  children: ReactNode;
  workorder: WorkOrder;
}

const WorkorderDelayReasonsContext = createContext<UseWorkorderDelayReasonsValue | undefined>(undefined);

export const WorkorderDelayReasonsContextProvider = ({ children, workorder }: Props) => {
  const [workorderDelayReasons, setWorkorderDelayReasons] = useState<WorkorderDelayReason[]>([]);

  const fetchDelayReasons = useCallback(async () => {
    const response = (await api.cwt.getAllWorkorderDelayReasons(workorder?.id ?? 0)) as WorkorderDelayReason[];
    const responseWithNew =
      response.length > 0
        ? response
        : [
            {
              id: 0,
              workorderId: workorder?.id ?? 0,
              delayReasonId: 0,
              notes: '',
            },
          ];

    setWorkorderDelayReasons(responseWithNew);
  }, [workorder]);

  useEffect(() => {
    fetchDelayReasons();
  }, [fetchDelayReasons]);

  const onSave = async (data: any) => {
    await api.cwt.createWorkorderDelayReason(data.data.workorderId, data.data.delayReasonId, data.data.notes);
    await fetchDelayReasons();
  };

  const onEditComplete = async ({ value, columnId, data }: any) => {
    const updatedDelayReasonIndex = workorderDelayReasons.findIndex((delayReason: any) => delayReason.id === data.id);
    if (updatedDelayReasonIndex === -1) {
      return;
    }
    const updatedDelayReason = { ...workorderDelayReasons[updatedDelayReasonIndex], [columnId]: value };
    if (updatedDelayReason.id === 0) {
      await api.cwt.createWorkorderDelayReason(
        updatedDelayReason.workorderId,
        updatedDelayReason.delayReasonId,
        updatedDelayReason.notes
      );
    } else if (updatedDelayReason.id !== 0) {
      await api.cwt.updateWorkorderDelayReason(
        updatedDelayReason.id,
        updatedDelayReason.delayReasonId,
        updatedDelayReason.notes
      );
    }
    await fetchDelayReasons();
  };

  const onEditValueChange = ({ value, columnId, data }: any) => {
    const updatedDelayReasonIndex = workorderDelayReasons.findIndex((delayReason: any) => delayReason.id === data.id);
    if (updatedDelayReasonIndex !== -1) {
      const updatedDelayReason = { ...workorderDelayReasons[updatedDelayReasonIndex], [columnId]: value };
      const updatedDelayReasonsArray = [...workorderDelayReasons];
      updatedDelayReasonsArray[updatedDelayReasonIndex] = updatedDelayReason;
      setWorkorderDelayReasons(updatedDelayReasonsArray);
    }
  };

  const handleAddDelayReason = () => {
    const updatedDelayReasonIndex = workorderDelayReasons.findIndex((delayReason: any) => delayReason.id === 0);
    if (updatedDelayReasonIndex !== -1) {
      return;
    }
    setWorkorderDelayReasons([
      {
        id: 0,
        workorderId: workorder.id,
        delayReasonId: 0,
        notes: '',
      },
      ...workorderDelayReasons,
    ]);
  };

  const handleDeleteWorkorderDelayReason = async (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    await api.cwt.deleteWorkorderDelayReason(id);
    await fetchDelayReasons();
  };

  const canAddDelayReason = useMemo(() => {
    return !(workorderDelayReasons.length > 0 && workorderDelayReasons.some(reason => reason.id === 0));
  }, [workorderDelayReasons]);

  return (
    <WorkorderDelayReasonsContext.Provider
      value={{
        workorderDelayReasons,
        onSave,
        onEditComplete,
        onEditValueChange,
        handleAddDelayReason,
        handleDeleteWorkorderDelayReason,
        canAddDelayReason,
      }}
    >
      {children}
    </WorkorderDelayReasonsContext.Provider>
  );
};

export const useWorkorderDelayReasons = () => {
  const context = useContext(WorkorderDelayReasonsContext);
  if (context === undefined) {
    throw new Error('useWorkorderDelayReasons must be used within a WorkorderDelayReasonsProvider');
  }
  return context;
};
