import { Bookmark, FilterBaseType } from '../../../types/cwt/bookmarks';
import { Path, Remote } from '../../types';

const getAllBookmarks =
  (path: Path, remote: Remote) =>
  (params: { type: string }): Promise<Bookmark[]> =>
    remote.get(path(`/`), { params });
const insertBookmark = (path: Path, remote: Remote) => (type: string, filter: FilterBaseType, name: string) =>
  remote.post(path(`/`), { type, filter, name });
const deleteBookmark = (path: Path, remote: Remote) => (params: { id: number }) => remote.del(path(`/`), { params });
const updateDefault = (path: Path, remote: Remote) => (id: number, type: string, isDefault: boolean) =>
  remote.put(path(`/default`), { id, type, isDefault });

const bookmarks = (path: Path, remote: Remote) => ({
  getAllBookmarks: getAllBookmarks(path, remote),
  insertBookmark: insertBookmark(path, remote),
  deleteBookmark: deleteBookmark(path, remote),
  updateDefault: updateDefault(path, remote),
});

export default bookmarks;
