import * as R from 'ramda';
import actionKey from './key';

// eslint-disable-next-line import/prefer-default-export
export const getActions = (state, elementId, actionState, riskManagementId) =>
  R.pathOr([], [actionKey(elementId, actionState, riskManagementId), 'actions'], state.actions);

export const getActionsIsLoading = (state, elementId, actionState, riskManagementId) =>
  R.pathOr(false, [actionKey(elementId, actionState, riskManagementId), 'isLoading'], state.actions);

export const getActionState = (state, elementId, actionState, riskManagementId) => ({
  error: R.path([actionKey(elementId, actionState, riskManagementId), 'error'], state.actions),
  actions: R.path([actionKey(elementId, actionState, riskManagementId), 'actions'], state.actions),
  isLoading: R.path([actionKey(elementId, actionState, riskManagementId), 'isLoading'], state.actions),
});
