import { useEffect, type SetStateAction, type Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import * as uiActions from '../../state/ui/actions';
import { type MyElement } from '../../types/my';
import helpers from './helpers';
import type { RequireInL, StaleDataState, UpdateRequestState } from './types';
const { reloadActionsFactory } = helpers;
type UseActionVisibilityEffects<T, L, A> = {
  updateRequestData: UpdateRequestState<T[]>;
  updateUiState: (params: Partial<L>) => void;
  setUpdateFromRequest: Dispatch<SetStateAction<UpdateRequestState<T[]>>>;
  reloadCompletedActions: ReturnType<typeof reloadActionsFactory<T, A>>;
  reloadOngoingActions: ReturnType<typeof reloadActionsFactory<T, A>>;
  setStaleData: Dispatch<SetStateAction<StaleDataState<T[]>>>;
  riskManagementId?: number | null;
  elementId?: MyElement['elementId'] | null;
  actionState: A;
  isShowingHiddenActions: boolean;
  isReadOnly?: boolean;
};
export default <T, L, A>({
  updateRequestData,
  updateUiState,
  setUpdateFromRequest,
  reloadCompletedActions,
  reloadOngoingActions,
  setStaleData,
  riskManagementId,
  elementId,
  actionState,
  isShowingHiddenActions,
  isReadOnly,
}: UseActionVisibilityEffects<T, L, A>) => {
  const dispatch = useDispatch();
  useEffect(() => {
    updateUiState({ isReadOnly, riskManagementId, elementId } as Partial<L & RequireInL<A>>);
  }, [updateUiState, riskManagementId, elementId, isReadOnly]);

  useEffect(() => {
    // not ready to render
    if (!riskManagementId || !elementId) {
      return;
    }
    // both true means it's both been handled - saves rerender-loop
    if (updateRequestData.handledData.ongoing && updateRequestData.handledData.complete) {
      return;
    }

    // if this use effect is called then the state-setters were updated - if there was a 3rd action state accounting
    // for each promise will be required but because the set stale data is called it probably means we don't need to
    // track each variation of promise
    const requestOngoing = () =>
      reloadOngoingActions({ riskManagementId, elementId }).then((ongoing: T[]) => {
        setStaleData((prev: any) => ({
          ...prev,
          // rely on stale data over store
          actions: { ongoing, complete: prev.actions.complete },
        }));
        return ongoing;
      });
    const requestComplete = () =>
      reloadCompletedActions({ riskManagementId, elementId }).then((complete: T[]) => {
        setStaleData((prev: any) => ({
          ...prev,
          // rely on stale data over store
          actions: { ongoing: prev.actions.ongoing, complete },
        }));
        return complete;
      });
    const requestAll = (): Promise<[T[], T[]]> => {
      // throttle http intentionally -> expensive on DB may crash it
      const retrievePromise =
        actionState === 'ongoing' || isShowingHiddenActions
          ? requestOngoing().then((resultsOngoing: T[]) =>
              requestComplete().then((resultsComplete: T[]) => Promise.all([resultsOngoing, resultsComplete]))
            )
          : requestComplete().then((resultsComplete: T[]) =>
              requestOngoing().then((resultsOngoing: T[]) => Promise.all([resultsOngoing, resultsComplete]))
            );
      setUpdateFromRequest((prev: any) => ({
        ...prev,
        handledData: { ongoing: false, complete: false },
        retrievePromise,
        isLoading: true,
        isError: false,
      }));
      return retrievePromise;
    };
    // exisiting promise means use-effect dependancies was changed
    (updateRequestData.retrievePromise || requestAll())
      .then(() => {
        setStaleData((prev: any) => ({
          ...prev,
          // drop stale data rely on store
          actions: { ongoing: null, complete: null },
        }));
        setUpdateFromRequest((prev: any) => ({
          ...prev,
          handledData: { ongoing: true, complete: true },
          retrievePromise: null,
          isLoading: false,
          isError: false,
        }));
      })
      .catch((e: any) => {
        setUpdateFromRequest((prev: any) => ({
          ...prev,
          handledData: { ongoing: true, complete: true },
          retrievePromise: null,
          isLoading: false,
          isError: true,
        }));
        console.error('Failed to Load Initial Actions\n', e);
        dispatch(uiActions.error(e, 'There was an error trying to load the Associated Risk-Actions'));
      });
  }, [
    updateRequestData,
    setUpdateFromRequest,
    reloadCompletedActions,
    reloadOngoingActions,
    setStaleData,
    riskManagementId,
    elementId,
    actionState,
    isShowingHiddenActions,
  ]);
};
