import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Api from '../../../api';
import { Hold } from '../../../types/cwt/engineering';
import { useWODetailsModal } from './useWODetailsModal';
interface useEngineeringValue {
  holds: Hold[];
  loading: boolean;
}

interface Props {
  children: ReactNode;
}
const EngineeringContext = createContext<useEngineeringValue | undefined>(undefined);

export const EngineeringContextProvider = ({ children }: Props) => {
  const [holds, setHolds] = useState<Hold[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { workorder } = useWODetailsModal();

  useEffect(() => {
    const getEngHolds = async () => {
      if (!workorder) return;
      const res = await Api.cwt.getWorkorderEngHolds(workorder.id);
      setHolds(res);
      setLoading(false);
    };

    if (loading) {
      void getEngHolds();
    }
  }, [loading]);
  return (
    <EngineeringContext.Provider
      value={{
        holds,
        loading,
      }}
    >
      {children}
    </EngineeringContext.Provider>
  );
};

export const useEngineering = () => {
  const context = useContext(EngineeringContext);
  if (context === undefined) {
    throw new Error('useEngineering must be used within a EngineeringContextProvider');
  }
  return context;
};
