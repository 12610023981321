import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { type Scorecard } from '@repo/echo-scorecard';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScorecardTable from '../Scorecard/ScorecardTable';
import useScorecard from '../../hooks/scorecard';
import { ScorecardSectionsProvider } from '../../hooks/scorecard-sections';
import UnstyledTotalScore from '../Scorecard/TotalScore';
import useEditManualTotalValue from '../../hooks/edit-manual-total-value';
import TotalValueOverrideDialog from '../Scorecard/TotalValueOverrideDialog';
import useEditManualUnitTotalValue from '../../hooks/edit-manual-unit-total-value';
import ManualValueDialog, { type ManualValue } from '../Scorecard/ManualValueDialog';
import useOverrideValue from '../../hooks/override-value';
import IndicatorDetailsDialog from '../Scorecard/IndicatorDetailsDialog';
import useEditManualIndicatorValue from '../../hooks/edit-manual-indicator-value';
import { Report } from '../../hooks/report';
import ManualProgramIndicatorValueDialog from '../Scorecard/ManualProgramIndicatorValueDialog';
import { ScorecardErrorText as ErrorMessage } from '../ErrorText';

interface Props {
  className: string;
  report: Report;
}

const TotalScore = styled(UnstyledTotalScore)``;

const LoadingOverlay = styled(CircularProgress)``;
const BackgroundLoadingOverlay = styled.div``;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  ${TotalScore} {
    align-self: flex-end;
    width: 200px;
  }
`;
type ScorecardContainerInnerProps = Props & {
  scorecard: Scorecard;
};
const ScorecardContainerInner = ({ className, report, scorecard }: ScorecardContainerInnerProps) => {
  const { elementId, year, quarter, elementType, facilityId } = report;
  const overrideTotal: any = !R.isEmpty(report)
    ? {
        score: report?.overriddenScore ? report.overriddenScore : R.path(['totals', 'score'], scorecard),
        colour: report?.overriddenColour ? report.overriddenColour : R.path(['totals', 'colour'], scorecard),
        comment: report.overrideComment,
      }
    : null;
  const { overrideValue, editOverrideValue, cancelEditOverrideValue } = useOverrideValue(
    elementId,
    parseInt(year, 10),
    parseInt(quarter, 10),
    scorecard
  );
  const { manualIndicatorValue, editManualIndicatorValue, cancelEditManualIndicatorValue } =
    useEditManualIndicatorValue(elementId, year, quarter, scorecard);
  const { manualTotalValue, editManualTotalValue, cancelEditManualTotalValue } = useEditManualTotalValue({
    reportId: report.id,
    scorecard,
    overrideTotal,
  });
  const { manualUnitTotalValue, editManualUnitTotalValue, cancelEditManualUnitTotalValue } =
    useEditManualUnitTotalValue({
      elementId,
      year: parseInt(year, 10),
      quarter: parseInt(quarter, 10),
      scorecard,
    });
  return (
    <>
      {scorecard && (
        <Container>
          <TotalScore
            report={report}
            scorecard={scorecard}
            onClick={editManualTotalValue}
          />
          <ScorecardTable
            className={className}
            scorecard={scorecard}
            editable={true}
            onCalculatedScoreClicked={editOverrideValue}
            onManualScoreClicked={editManualIndicatorValue}
            onUnitTotalScoreClicked={editManualUnitTotalValue}
          />
        </Container>
      )}
      {overrideValue && (
        <IndicatorDetailsDialog
          readonly
          elementType={elementType}
          facilityId={facilityId}
          overrideValue={overrideValue}
          elementId={elementId}
          year={parseInt(year, 10)}
          quarter={parseInt(quarter, 10)}
          onClear={() => null}
          onCancel={cancelEditOverrideValue}
          onSave={() => null}
        />
      )}
      {!R.isNil(manualTotalValue) && (
        <TotalValueOverrideDialog
          overrideValue={manualTotalValue}
          readonly
          onSave={() => null}
          onCancel={cancelEditManualTotalValue}
        />
      )}
      {manualIndicatorValue && (report.elementType === 'program' || elementType === 'program') && (
        <ManualProgramIndicatorValueDialog
          readonly
          elementId={report.elementId}
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={() => null}
        />
      )}
      {manualUnitTotalValue && (
        <ManualValueDialog
          title="Total Unit Value Override"
          readonly
          manualValue={manualUnitTotalValue as ManualValue}
          onSave={() => null}
          onCancel={cancelEditManualUnitTotalValue}
        />
      )}
      {manualIndicatorValue && (report.elementType !== 'program' || elementType !== 'program') && (
        <ManualValueDialog
          readonly
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={() => null}
        />
      )}
    </>
  );
};
const ScorecardContainer = (props: Props) => {
  const { report } = props;
  const { elementId, elementName, year, quarter } = report;
  const {
    scorecardDisplayable,
    scorecard: scorecardActual,
    scorecardErrorMessage,
    staleData: scordcardStaleDate,
    isLoading,
    isBackgroundLoading,
    BackgroundLoadingMessage,
  } = useScorecard({
    elementName,
    elementId,
    year: parseInt(year, 10),
    quarter: parseInt(quarter, 10),
  });
  const scorecard = scorecardDisplayable;

  return (
    <ScorecardSectionsProvider elementId={elementId}>
      {isBackgroundLoading && (
        <BackgroundLoadingOverlay>
          <BackgroundLoadingMessage />
        </BackgroundLoadingOverlay>
      )}
      {!isLoading ? (
        <ErrorMessage
          scorecardDisplayable={scorecard}
          scorecard={scorecardActual}
          staleData={scordcardStaleDate}
          scorecardErrorMessage={scorecardErrorMessage}
          noDataMessage="Please try to view a Scorecard again or 'reload' the page from the browser."
        />
      ) : null}
      {isLoading && <LoadingOverlay size={20} />}
      {scorecard && (
        <ScorecardContainerInner
          {...props}
          scorecard={scorecard}
        />
      )}
    </ScorecardSectionsProvider>
  );
};

export default ScorecardContainer;
