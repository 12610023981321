import { ReactNode, createContext, useContext, useState } from 'react';
import { WorkOrder } from '../../../types/cwt/workorder';

interface useWODetailsModalValue {
  open: boolean;
  handleOpen: (openTab: string) => void;
  handleClose: () => void;
  activeTab: string;
  handleSetActiveTab: (tab: string) => void;
  workorder: WorkOrder | undefined;
  tplus1: boolean;
}

interface Props {
  children: ReactNode;
  workorder?: WorkOrder;
  onClose?: () => void;
  tplus1?: boolean;
}
const WODetailsModalContext = createContext<useWODetailsModalValue | undefined>(undefined);

export const WODetailsModalContextProvider = ({ children, workorder, onClose, tplus1 = false }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('overview');
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  const handleOpen = (openTab: string) => {
    setOpen(true);
    handleSetActiveTab(openTab);
  };

  const handleSetActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <WODetailsModalContext.Provider
      value={{ open, handleOpen, handleClose, activeTab, handleSetActiveTab, workorder, tplus1 }}
    >
      {children}
    </WODetailsModalContext.Provider>
  );
};

export const useWODetailsModal = () => {
  const context = useContext(WODetailsModalContext);
  if (context === undefined) {
    throw new Error('useWODetailsModal must be used within a WODetailsModalContextProvider');
  }
  return context;
};
