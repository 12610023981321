import styled from 'styled-components';
import * as R from 'ramda';

import UnstyledFormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import UnstyledPlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import UnstyledDateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import UnstyledAutoRenewIcon from '@material-ui/icons/Autorenew';
import UnstyledFindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import UnstyledTableChartIcon from '@material-ui/icons/TableChart';
import UnstyledChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import UnstyledSupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import UnstyledAssignmentIcon from '@material-ui/icons/Assignment';
import UnstyledAssessmentIcon from '@material-ui/icons/Assessment';

import SvgIcon from '@material-ui/core/SvgIcon';

import WorkOrderPage from '../../pages/cwt/wo';
import WorkRequestPage from '../../pages/cwt/wr';
import SchedulePage from '../../pages/cwt/schedule';
import TurnoverPage from '../../pages/cwt/turnover';
import ActionsHeader from '../../pages/cwt/actions';
import AssessingPage from '../../pages/cwt/assessing';
import EngineeringPage from '../../pages/cwt/engineering';
import ChangeTrackingPage from '../../pages/cwt/change-tracking';
import AdminPage from '../../pages/cwt/admin';

import isFeatureEnabled from '../../utils/feature-flags';
import { forRole } from '../../auth/roles';
import TPlusOnePage from '../../pages/cwt/t+1';

// TODO-RS: Extract shared component
const FormatListBulletedOutlinedIcon = styled(UnstyledFormatListBulletedOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const PlaylistAddIcon = styled(UnstyledPlaylistAddIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const DateRangeOutlined = styled(UnstyledDateRangeOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const AutoRenewIcon = styled(UnstyledAutoRenewIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const TableChartIcon = styled(UnstyledTableChartIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const FindInPageIcon = styled(UnstyledFindInPageOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const ChangeHistoryIcon = styled(UnstyledChangeHistoryIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const SupervisorAccountOutlinedIcon = styled(UnstyledSupervisorAccountOutlinedIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const AssignmentIcon = styled(UnstyledAssignmentIcon)`
  color: ${({ theme }) => theme.colours.white};
`;
const AssessmentIcon = styled(UnstyledAssessmentIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const navItem = (label: string, to: string, icon?: typeof SvgIcon) => ({
  type: 'nav',
  label,
  to,
  icon,
});

const actionItem = (label: string, action: any) => ({
  type: 'action',
  label,
  action,
});

const subMenu = (label: string, to: string, icon?: typeof SvgIcon, children?: any) => ({
  type: 'subMenu',
  label,
  to,
  icon,
  children,
});

const WO_PATH = '/cwt/wo';
const WR_PATH = '/cwt/wr';
const WO_ACTIONS_PATH = '/cwt/actions';
const SCHEDULE_PATH = '/cwt/schedule';
const TURNOVER_PATH = '/cwt/turnover';
const WO_ASSESSING_PATH = '/cwt/assessing';
const ENGINEERING_PATH = '/cwt/engineering';
const CHANGE_LOG_PATH = '/cwt/change-tracking';
const ADMIN_PATH = '/cwt/admin';
const ADMIN_NEW_WORKORDER_PATH = '/cwt/admin/add-new-workorder';
const ADMIN_DROPDOWN_MAINTENANCE_PATH = '/cwt/admin/dropdown-maintenance';
const T_PLUS_ONE_PATH = '/cwt/t+1';

export const menuItems = (onSignOut: any) =>
  [
    navItem('WO Header', WO_PATH, FormatListBulletedOutlinedIcon),
    isFeatureEnabled('cwtWorkRequestScreening') ? navItem('WR Screening', WR_PATH, AssignmentIcon) : null,
    navItem('Actions', WO_ACTIONS_PATH, PlaylistAddIcon),
    isFeatureEnabled('CWT_Assessing') ? navItem('Assessing', WO_ASSESSING_PATH, FindInPageIcon) : null,
    navItem('Engineering', ENGINEERING_PATH, TableChartIcon),
    navItem('Schedule', SCHEDULE_PATH, DateRangeOutlined),
    navItem('Turnover', TURNOVER_PATH, AutoRenewIcon),
    isFeatureEnabled('cwt_tplus1') ? navItem('T + 1', T_PLUS_ONE_PATH, AssessmentIcon) : null,
    isFeatureEnabled('CWTchangeTracking') ? navItem('Change Tracking', CHANGE_LOG_PATH, ChangeHistoryIcon) : null,
    ...forRole(
      'CWTAdmin',
      isFeatureEnabled('CWTAdminPanel')
        ? subMenu('Admin', ADMIN_PATH, SupervisorAccountOutlinedIcon, [
            navItem('Add New WO', ADMIN_NEW_WORKORDER_PATH),
            navItem('Dropdown Maintenance', ADMIN_DROPDOWN_MAINTENANCE_PATH),
          ])
        : null
    ),
    actionItem('Sign-out', onSignOut),
  ].filter(x => !R.isNil(x));

const route = (path: any, component: any) => ({
  path,
  component,
});

export const routes = [
  route(WO_ACTIONS_PATH, ActionsHeader),
  route(WO_PATH, WorkOrderPage),
  route(WR_PATH, WorkRequestPage),
  route(SCHEDULE_PATH, SchedulePage),
  route(TURNOVER_PATH, TurnoverPage),
  route(WO_ASSESSING_PATH, AssessingPage),
  route(ENGINEERING_PATH, EngineeringPage),
  route(T_PLUS_ONE_PATH, TPlusOnePage),
  route(CHANGE_LOG_PATH, ChangeTrackingPage),
  route(ADMIN_PATH, AdminPage),
];
