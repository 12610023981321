import { useMemo, useCallback, useEffect, useState } from 'react';
import { Control, FieldErrors, useForm } from 'react-hook-form';
import useRiskManagement from '../../../../../../context/risk-management';
import useConditionalConfirm from '../../../../../../hooks/conditional-confirm';
import {
  ImpactProbabilityAssessment as ImpactProbabilityAssessment,
  ImpactScale,
  ProbabilityOption,
} from '../../../../../../types/risk-management';
import Api from '../../../../../../api';
import * as uiActions from '../../../../../../state/ui/actions';
import { useDispatch } from 'react-redux';

const ONE_MILLION = 1000000;

interface Props {
  formState: 'edit' | 'add' | 'view' | 'none';
  editRow: number;
  watchOpportunityOrThreat: string;
  onCancel: (value: any) => void;
  onSave: () => Promise<void>;
}

export interface ImpactProbabilityAssessmentHook {
  control: Control<ImpactProbabilityAssessment>;
  errors: FieldErrors<ImpactProbabilityAssessment>;
  handleSubmit: any;
  confirming: boolean;
  confirmCancel: () => void;
  onConfirmCancel: () => void;
  onCancelConfirm: () => void;
  impactAssessmentCosts: {
    totalSafetyImpact: number;
    environmentalSafetyCost: number;
    industrialSafetyCost: number;
    radiologicalSafetyCost: number;
    reactorSafetyCost: number;
    reputationalImpactCost: number;
    regulatoryComplianceCost: number;
  };
  impactCategoryCosts: {
    totalForcedOutageImpacts: any;
    totalCommercialImpacts: any;
    totalSafetyCommercialImpacts: any;
    netImpacts: any;
  };
  YEARS: { label: string; value: number }[];
  PROBABILITY_OPTIONS: ProbabilityOption[];
  IMPACT_SCALE: ImpactScale[];
  FORCED_OUTAGE_RATES: any;
  watchYears: number[];
}

const MAP_TO_INITIAL_VALUES = [
  'commercialImpactForAllAffectedUnits',
  'daysForcedOutageForProductionImpact',
  'worstCaseTotalCommercialImpact',
  'environmentalSafety',
  'industrialSafety',
  'probability',
  'probabilityRate',
  'radiologicalSafety',
  'reactorSafety',
  'regulatoryCompliance',
  'reputationalImpact',
  'unitsForProductionImpact',
  'year',
];

export const useImpactProbabilityAssessment = ({
  formState,
  editRow,
  watchOpportunityOrThreat,
  onCancel,
  onSave,
}: Props): ImpactProbabilityAssessmentHook => {
  const dispatch = useDispatch();
  const [firstEdit, setFirstEdit] = useState(true);
  const { constants, data } = useRiskManagement();
  const { riskId } = data;
  const { IMPACT_PROBABILITY } = constants;

  const FORCED_OUTAGE_RATE = IMPACT_PROBABILITY?.FORCED_OUTAGE_RATE || {};
  const POS_IMPACT_SCALE = IMPACT_PROBABILITY?.POS_IMPACT_SCALE || [];
  const NEG_IMPACT_SCALE = IMPACT_PROBABILITY?.NEG_IMPACT_SCALE || [];
  const PROBABILITY_OPTIONS = IMPACT_PROBABILITY?.PROBABILITY_OPTIONS || [];

  const YEARS = Object.keys(FORCED_OUTAGE_RATE)
    .filter(year => new Date().getFullYear() - 1 < parseInt(year, 10))
    .map(year => ({ label: year, value: parseInt(year, 10) }));

  const IMPACT_SCALE = useMemo(
    () => (watchOpportunityOrThreat === 'opportunity' ? POS_IMPACT_SCALE : NEG_IMPACT_SCALE),
    [watchOpportunityOrThreat]
  );

  const handleCancel = () => {
    onCancel(null);
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm<ImpactProbabilityAssessment>({
    defaultValues: {
      year: [YEARS[0].value],
      probabilityRate: 0,
    },
  });

  const submitForm = async (riskId: number, data: ImpactProbabilityAssessment) => {
    try {
      if (formState === 'edit' && editRow !== null) {
        await Api.updateImpactProbabilityAssessment(riskId, editRow, {
          ...data,
          year: data.year[0],
          probabilityRate: data.probabilityRate / 100,
        });
      } else {
        await Api.postImpactProbabilityAssessment(riskId, {
          ...data,
          probabilityRate: data.probabilityRate / 100,
        });
      }
      await onSave();
      dispatch(uiActions.genericMessage('Impact and Probability Assessment Saved'));
    } catch (e: any) {
      dispatch(
        uiActions.error(e, 'Could not submit Impact and Probability Assessment. Please check the year and try again.')
      );
    }
  };

  const submit = handleSubmit(async data => {
    await submitForm(riskId, data);
  });

  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(handleCancel, isDirty);

  useEffect(() => {
    if (!isDirty) return;
    if (!watch('probability')) return;
    const found = PROBABILITY_OPTIONS.find(option => option.value === watch('probability'));
    if (!found) return;
    if (formState === 'edit' && firstEdit) {
      setFirstEdit(false);
      return;
    }
    setValue('probabilityRate', parseFloat(found.rate) * 100);
  }, [watch('probability'), isDirty]);

  const impactAssessmentCosts = useMemo(() => {
    const environmentalSafetyCost =
      IMPACT_SCALE.find(option => option.value === watch('environmentalSafety'))?.cost || 0;
    const industrialSafetyCost = IMPACT_SCALE.find(option => option.value === watch('industrialSafety'))?.cost || 0;
    const radiologicalSafetyCost = IMPACT_SCALE.find(option => option.value === watch('radiologicalSafety'))?.cost || 0;
    const reactorSafetyCost = IMPACT_SCALE.find(option => option.value === watch('reactorSafety'))?.cost || 0;
    const reputationalImpactCost = IMPACT_SCALE.find(option => option.value === watch('reputationalImpact'))?.cost || 0;
    const regulatoryComplianceCost =
      IMPACT_SCALE.find(option => option.value === watch('regulatoryCompliance'))?.cost || 0;
    const totalSafetyImpact =
      environmentalSafetyCost +
      industrialSafetyCost +
      radiologicalSafetyCost +
      reactorSafetyCost +
      reputationalImpactCost +
      regulatoryComplianceCost;
    return {
      totalSafetyImpact: totalSafetyImpact,
      environmentalSafetyCost: environmentalSafetyCost,
      industrialSafetyCost: industrialSafetyCost,
      radiologicalSafetyCost: radiologicalSafetyCost,
      reactorSafetyCost: reactorSafetyCost,
      reputationalImpactCost: reputationalImpactCost,
      regulatoryComplianceCost: regulatoryComplianceCost,
    };
  }, [
    watch('probabilityRate'),
    watch('environmentalSafety'),
    watch('industrialSafety'),
    watch('radiologicalSafety'),
    watch('reactorSafety'),
    watch('reputationalImpact'),
    watch('regulatoryCompliance'),
  ]);

  const impactCategoryCosts = useMemo(() => {
    const years = watch('year');

    if (!years || years.length === 0) {
      return {
        totalForcedOutageImpacts: {},
        totalCommercialImpacts: {},
        totalSafetyCommercialImpacts: {},
        netImpacts: {},
      };
    }
    const daysForcedOutageForProductionImpact = watch('daysForcedOutageForProductionImpact');
    const unitsForProductionImpact = watch('unitsForProductionImpact');
    const commercialImpactForAllAffectedUnits = watch('commercialImpactForAllAffectedUnits');
    const totalForcedOutageImpacts = years.reduce((acc, year) => {
      const FORCED_OUTAGE_RATE_FOR_YEAR = FORCED_OUTAGE_RATE[year];
      const forcedOutageImpact =
        daysForcedOutageForProductionImpact * unitsForProductionImpact * FORCED_OUTAGE_RATE_FOR_YEAR;
      return {
        ...acc,
        [year]: forcedOutageImpact,
      };
    }, {} as any);
    const totalCommercialImpacts = Object.keys(totalForcedOutageImpacts).reduce((acc, year) => {
      const totalCommercialImpact = totalForcedOutageImpacts[year] + commercialImpactForAllAffectedUnits;
      return {
        ...acc,
        [year]: totalCommercialImpact,
      };
    }, {} as any);
    const totalSafetyCommercialImpacts = Object.keys(totalForcedOutageImpacts).reduce((acc, year) => {
      const totalSafetyCommercialImpact =
        totalCommercialImpacts[year] + impactAssessmentCosts.totalSafetyImpact / ONE_MILLION;
      return {
        ...acc,
        [year]: totalSafetyCommercialImpact,
      };
    }, {} as any);
    const netImpacts = Object.keys(totalForcedOutageImpacts).reduce((acc, year) => {
      const netImpact = totalSafetyCommercialImpacts[year] * (watch('probabilityRate') / 100);
      return {
        ...acc,
        [year]: netImpact,
      };
    }, {} as any);

    return {
      totalForcedOutageImpacts: totalForcedOutageImpacts || {},
      totalCommercialImpacts: totalCommercialImpacts || {},
      totalSafetyCommercialImpacts: totalSafetyCommercialImpacts || {},
      netImpacts: netImpacts || 0,
    };
  }, [
    watch('daysForcedOutageForProductionImpact'),
    watch('unitsForProductionImpact'),
    watch('commercialImpactForAllAffectedUnits'),
    watch('probabilityRate'),
    watch('year'),
  ]);

  const getImpactProbabilityAssessment = useCallback(async () => {
    try {
      const data: any = await Api.getImpactProbabilityAssessment(riskId, editRow);
      MAP_TO_INITIAL_VALUES.forEach(key => {
        if (key === 'probabilityRate') {
          setValue('probabilityRate', data['probabilityRate'] * 100);
        } else if (key === 'year') {
          setValue('year', [data['year']]);
        } else {
          setValue(key as keyof ImpactProbabilityAssessment, data[key]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [editRow]);

  useEffect(() => {
    if ((formState === 'edit' || formState === 'view') && editRow !== null) {
      void getImpactProbabilityAssessment();
    }
  }, [editRow, formState]);

  const FORCED_OUTAGE_RATES = useMemo(() => {
    const years = watch('year');
    if (!years || years.length === 0) {
      return {};
    }
    return years.reduce((acc, year) => {
      const FORCED_OUTAGE_RATE_FOR_YEAR = FORCED_OUTAGE_RATE[year];
      return {
        ...acc,
        [year]: FORCED_OUTAGE_RATE_FOR_YEAR,
      };
    }, {} as any);
  }, [watch('year')]);

  return {
    control,
    handleSubmit: submit,
    errors,
    confirming,
    confirmCancel,
    onConfirmCancel,
    onCancelConfirm,
    impactAssessmentCosts,
    impactCategoryCosts,
    YEARS,
    PROBABILITY_OPTIONS,
    IMPACT_SCALE,
    FORCED_OUTAGE_RATES,
    watchYears: watch('year'),
  };
};
