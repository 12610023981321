"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withCounts = exports.getOverrideOrCalculatedValue = exports.getCalculatedScorecardValue = exports.getCalculatedScore = exports.getOverrideComment = exports.buildCalculatedScorecardValue = void 0;
const R = __importStar(require("ramda"));
const values_1 = require("./values");
const buildCalculatedScorecardValue = (score, indicatorWeight, unitWeight) => ({
    calculated: {
        score,
    },
    indicatorWeight,
    unitWeight,
});
exports.buildCalculatedScorecardValue = buildCalculatedScorecardValue;
const getOverrideComment = (calculatedValue) => R.pathOr(undefined, ['calculated', 'override', 'comment'], calculatedValue);
exports.getOverrideComment = getOverrideComment;
const getCalculatedScore = (calculatedValue) => calculatedValue.calculated.score;
exports.getCalculatedScore = getCalculatedScore;
const getCalculatedScorecardValue = (indicatorId, unitId, scorecard) => (0, values_1.getValue)(indicatorId, unitId, scorecard);
exports.getCalculatedScorecardValue = getCalculatedScorecardValue;
const getOverrideOrCalculatedValue = (calculatedValue) => {
    const defaultScore = R.path(['calculated', 'score'], calculatedValue);
    const overrideScore = R.pathOr(null, ['calculated', 'override', 'score'], calculatedValue);
    if (!R.isNil(overrideScore))
        return overrideScore;
    if (R.isNil(overrideScore) && !R.isNil(defaultScore))
        return defaultScore;
    return 0;
};
exports.getOverrideOrCalculatedValue = getOverrideOrCalculatedValue;
const withUpdatedScore = (score, calculatedScorecardValue) => (Object.assign(Object.assign({}, calculatedScorecardValue), { calculated: {
        score,
    } }));
const mergeCount = (scorecard, { indicatorId, unitId, score }) => (Object.assign(Object.assign({}, scorecard), { values: (0, values_1.withUpdatedValue)(indicatorId, unitId, withUpdatedScore(score, (0, exports.getCalculatedScorecardValue)(indicatorId, unitId, scorecard)), scorecard.values) }));
exports.withCounts = R.curry((counts, scorecard) => R.reduce(mergeCount, scorecard, counts));
