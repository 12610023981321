import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { useActions } from '../../hooks/useActions';
import { useUsers } from '../../hooks/useUsers';
import { renderMaximoLink, getRowContextMenuFunction, onRenderActionRow } from '../../wo/components/utils';
import { renderTCD, renderTBE } from '../../wo/components/HoverActions/utils';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
import CWTToolTip from '../../components/CWTToolTip';
import { ActionHistory } from './ActionsHistory';
import { getActionStatusColor } from '../utils';

const gridStyle = { minHeight: 600, minWidth: '100%', color: 'black' };

interface Props {
  setSelectedWorkorderId: (workorderId: number | undefined) => void;
  setIsTPlusOne: (isTplusOne: boolean | undefined) => void;
}

const ActionsTable = ({ setSelectedWorkorderId, setIsTPlusOne }: Props) => {
  const { loading, groups, statuses, flags, setGridRef, filteredActionsWithWorkorder, sortByStatus } = useActions();
  const { handleOpen } = useWODetailsModal();
  const { users } = useUsers();

  const onRowClick = (rowProps: TypeRowProps) => {
    setSelectedWorkorderId(rowProps.data.workorderId);
    setIsTPlusOne(rowProps.data.tPlusOne);
  };

  const onRowDoubleClick = () => {
    handleOpen('actions');
  };

  const columns = [
    {
      name: 'wonum',
      header: 'WO',
      defaultWidth: 80,

      render: ({ data }: any) => renderMaximoLink(data.workorderId, data.wonum),
    },
    {
      name: 'wopriority',
      header: 'Prior',
      type: 'number',
      defaultWidth: 70,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'worktype',
      header: 'Type',
      defaultWidth: 70,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'location',
      header: 'Location',
      defaultWidth: 100,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'wodescription',
      header: 'WO Description',
      defaultWidth: 120,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'crewworkgroup',
      header: 'Crew',
      defaultWidth: 90,
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'description',
      header: 'Description',
      defaultFlex: 1,
      minWidth: 200,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'statusId',
      header: 'Status',
      defaultWidth: 120,
      defaultFlex: 1,
      style: ({ data }: any) => {
        return { backgroundColor: getActionStatusColor(data.statusId) };
      },
      sort: sortByStatus,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => {
        return statuses.find(status => status.id === value)?.label;
      },
    },
    {
      name: 'groupId',
      header: 'Group',
      defaultWidth: 120,
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => {
        const id = groups.find(group => group.id === value)?.label;
        return id;
      },
    },
    {
      name: 'owner',
      header: 'Owner',
      defaultWidth: 150,
      enableColumnFilterContextMenu: false,
      render: ({ value }: any) => {
        const owner = users.find(user => user.id === value)?.name;
        return owner;
      },
    },
    {
      name: 'tbe',
      header: 'TBE',
      defaultWidth: 100,
      defaultFlex: 1,
      render: renderTBE,
    },
    {
      name: 'tcd',
      header: 'TCD',
      defaultWidth: 100,
      defaultFlex: 1,
      render: renderTCD,
    },
    {
      name: 'flags',
      header: 'Flags',
      defaultWidth: 130,
      defaultFlex: 1,
      enableColumnFilterContextMenu: true,
      render: ({ value }: any) => {
        return value.map((flagId: any) => flags.find(flag => flag.id === flagId)?.label).join(', ');
      },
    },
    {
      name: 'history',
      header: 'History',
      defaultWidth: 100,
      defaultFlex: 1,
      enableColumnFilterContextMenu: false,
      render: ({ data }: any) => <ActionHistory history={data.history} />,
    },
  ];

  return (
    <ReactDataGrid
      style={gridStyle}
      idProperty="id"
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={filteredActionsWithWorkorder}
      pagination
      loading={loading}
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      renderRowContextMenu={getRowContextMenuFunction(handleOpen, setSelectedWorkorderId, 'workorderId')}
      onReady={setGridRef}
      defaultSortInfo={{ name: 'statusId', dir: 1 }}
      rowStyle={onRenderActionRow}
    />
  );
};

export default ActionsTable;
