import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useTurnover } from '../../../hooks/useTurnover';
import { format } from 'date-fns';
import { useUsers } from '../../../hooks/useUsers';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import UnstyledEditRoundedIcon from '@material-ui/icons/EditRounded';
import styled from 'styled-components';
import CWTToolTip from '../../CWTToolTip';
import { useCrews } from '../../../hooks/useCrews';

const EditRoundedIcon = styled(UnstyledEditRoundedIcon)`
  color: ${({ theme }) => theme.colours.teals.teal1};
  cursor: pointer;
`;
const gridStyle = { minHeight: 600, minWidth: '100%' };

const TurnoverTable = () => {
  const { crews } = useCrews();
  const { turnover, loading, setGridRef, handleOpenEditTurnoverDialog } = useTurnover();
  const { getUser } = useUsers();
  const columns = [
    {
      name: 'turnoverDate',
      header: 'Date',
      defaultWidth: 100,
      render: ({ value }: any) => format(new Date(value), 'ddMMMyyyy').toUpperCase(),
    },
    {
      name: 'crewId',
      header: 'Crew',
      defaultWidth: 100,
      render: ({ value }: any) => value?.toUpperCase(),
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        dataSource: crews.map(crew => ({ id: crew.id, label: crew.id })),
      },
      enableColumnFilterContextMenu: false,
    },
    {
      name: 'turnoverNotes',
      header: 'Notes',
      defaultWidth: 100,
      defaultFlex: 1,
      render: ({ value }: any) => <CWTToolTip value={value}></CWTToolTip>,
    },
    {
      name: 'updatedBy',
      header: 'Last Modified By',
      defaultWidth: 150,
      render: ({ value }: any) => getUser(value)?.name,
    },
    {
      name: 'updatedAt',
      header: 'Last Modified',
      defaultWidth: 150,
      render: ({ value }: any) => format(new Date(value), 'ddMMMyyyy HH:mm').toUpperCase(),
    },
    {
      header: 'Edit',
      defaultWidth: 80,
      render: ({ data }: any) => <EditRoundedIcon onClick={() => handleOpenEditTurnoverDialog(data.id)} />,
    },
  ];

  const filterValue = [
    { name: 'crewId', operator: 'inlist', type: 'select', value: [] },
    { name: 'turnoverNotes', operator: 'contains', type: 'string', value: '' },
  ];
  return (
    <ReactDataGrid
      idProperty="id"
      style={{ ...gridStyle, padding: '1px' }}
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={turnover}
      loading={loading}
      defaultFilterValue={filterValue}
      onReady={setGridRef}
    />
  );
};

export default TurnoverTable;
