import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import * as actionActions from '../state/actions/actions';
import * as actionSelectors from '../state/actions/selectors';
import type { ActionSource, ActionState, ElementAction } from '../types/actions';
import isFeatureEnabled from '../utils/feature-flags';

interface ActionsReturn {
  actions: ElementAction[];
  isLoading: boolean;
}
type ActionsParams = {
  elementId: string | number;
  actionState: ActionState;
  actionSource?: ActionSource;
  riskManagementId?: number;
};

const useActions = ({
  elementId,
  actionState,
  actionSource = 'All',
  riskManagementId,
}: ActionsParams): ActionsReturn => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!R.isEmpty(elementId) && !R.isNil(elementId)) {
      if (riskManagementId && isFeatureEnabled('riskActionHiddenToggle')) {
        // when feature flag enabled & this param provided it's been handled
        return;
      }
      dispatch(actionActions.loadActionsRequest(elementId, actionState));
    }
  }, [dispatch, elementId, actionState, riskManagementId]);

  const allActions = useSelector(state =>
    actionSelectors.getActions(state, elementId, actionState, riskManagementId)
  ) as ElementAction[];
  const isLoading = useSelector(state =>
    actionSelectors.getActionsIsLoading(state, elementId, actionState, riskManagementId)
  );
  const actions = useMemo(
    () => allActions.filter(action => action.source === actionSource || actionSource === 'All'),
    [allActions, actionSource]
  );

  return {
    actions,
    isLoading,
  };
};

export default useActions;
