import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import UnstyledButton from '@material-ui/core/Button';
import EventNoteIcon from '@material-ui/icons/EventNote';
import UnstyledProfileImage from './ProfileImage';
import { dateToString, YYYYMMDDHHmmss } from '../../utils/date-format';
import OutlineButton from '../../components/OutlineButton';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { getUserName } from '../../auth/utils';
import isFeatureEnabled from '../../utils/feature-flags';

const ProfileImage = styled(UnstyledProfileImage)``;
const Typography = styled(UnstyledTypography)``;
const Button = styled(UnstyledButton)``;
const OldFeedback = styled(OutlineButton)`
  margin-right: 3%;
`;
const Feedback = styled(OutlineButton)`
  margin-right: 30px;
  color: ${({ theme }) => theme.colours.greys.darkGrey3};
  border: ${({ theme }) => theme.borders.thin.solid8};
  border-radius: 2px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colours.greys.lightGrey2};
    border: ${({ theme }) => theme.borders.thin.solid8};
  }
`;

const Container = styled.div`
  padding-right: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${ProfileImage} {
    margin-right: 7px;
  }

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium12};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-name-old {
    margin-right: 32px;
  }

  .header-name {
    margin-right: 30px;
  }

  .header-date-old {
    margin-right: 50px;
  }

  .header-date {
    margin-right: 30px;
  }

  .header-note-icon {
    margin-right: 30px;
    padding: 0;
    min-width: 0;
  }
`;

interface Props {
  className?: string;
  hideIcon?: boolean;
  onIconClick(): void;
}

const Header = ({ className, hideIcon, onIconClick }: Props) => (
  <AuthenticatedTemplate>
    <Container className={className}>
      {isFeatureEnabled('navImprovementUI') ? (
        <>
          <ProfileImage />
          <Typography className="header-name">{getUserName()}</Typography>
          {!hideIcon && (
            <Button
              className="header-note-icon"
              onClick={onIconClick}
            >
              <EventNoteIcon />
            </Button>
          )}
          <Feedback
            variant="outlined"
            color="primary"
            href={`mailto:echo@npxinnovation.ca?subject=ECHO Feedback from Bruce Power User. Ticket: #${YYYYMMDDHHmmss(
              new Date()
            )}`}
          >
            Feedback
          </Feedback>
          <Typography className="header-date">{dateToString(new Date())}</Typography>
        </>
      ) : (
        <>
          <OldFeedback
            variant="outlined"
            color="primary"
            href={`mailto:echo@npxinnovation.ca?subject=ECHO Feedback from Bruce Power User. Ticket: #${YYYYMMDDHHmmss(
              new Date()
            )}`}
          >
            Give Feedback
          </OldFeedback>
          <ProfileImage />
          <Typography className="header-name-old">{getUserName()}</Typography>
          <Typography className="header-date-old">{dateToString(new Date())}</Typography>
          {!hideIcon && (
            <Button onClick={onIconClick}>
              <EventNoteIcon />
            </Button>
          )}
        </>
      )}
    </Container>
  </AuthenticatedTemplate>
);

export default Header;
