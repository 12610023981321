import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import UnstyledCustomTextField from '../../../../../components/CustomTextField';
import ActionButton from '../../../../../components/ActionButton';

interface DropdownListProps {
  title: string;
  entries: { label: string; id: number; isManuallyAdded: boolean }[];
  onDelete: (id: number) => void;
  onAdd: (label: string) => void;
  newEntry: string;
  setNewEntry: (label: string) => void;
}

const TextField = styled(UnstyledCustomTextField)`
  letter-spacing: 0.5px;
  & .MuiInputBase-root {
    height: 4.6vh;
  }
  & .MuiFilledInput-root {
    border-radius: 0;
  }
  & .MuiFilledInput-inputMarginDense {
    padding-top: 10px;
    padding-bottom: 6px;
  }
`;

const StyledList = styled(List)`
  max-height: 55vh;
  overflow: auto;
`;

const DropdownList = ({ title, entries, onDelete, onAdd, newEntry, setNewEntry }: DropdownListProps) => {
  const isDisabled = !newEntry || entries.some(entry => entry.label === newEntry);
  return (
    <>
      <Typography style={{ fontWeight: 600, fontSize: '1rem' }}>{title}</Typography>
      <TextField
        variant="filled"
        placeholder="Type in new value here..."
        fullWidth
        value={newEntry || ''}
        onChange={e => setNewEntry(e.target.value)}
      />

      <ActionButton
        color="primary"
        variant="text"
        onClick={() => onAdd(newEntry)}
        fullWidth
        disabled={isDisabled}
      >
        Add New Value
      </ActionButton>

      <StyledList dense={true}>
        {entries.map(entry => (
          <ListItem key={entry.id}>
            <ListItemText primary={entry.label} />
            {entry.isManuallyAdded && (
              <IconButton
                edge="start"
                aria-label="delete"
                onClick={() => onDelete(entry.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListItem>
        ))}
      </StyledList>
    </>
  );
};

export default DropdownList;
