import React from 'react';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import UnstyledList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UnstyledForumIcon from '@material-ui/icons/Forum';
import isFeatureEnabled from '../../utils/feature-flags';
import UnstyledNavItem from './NavItem';
import UnstyledSubMenuItem from './SubMenuItem';
import ActionItem from './ActionItem';
import MenuItem from './MenuItem';

const NavItem = styled(UnstyledNavItem)``;
const SubMenuItem = styled(UnstyledSubMenuItem)``;

const ForumIcon = styled(UnstyledForumIcon)`
  color: ${({ theme }) => theme.colours.white};
`;

const ForumLink = styled(Link)`
  color: ${({ theme }) => theme.colours.white};
`;
const List = styled(UnstyledList)`
  color: ${({ theme }) => theme.colours.menu.text};
  padding-top: 0px;
`;

const entryToItem = (entry, i) => {
  switch (entry.type) {
    case 'subMenu': {
      return (
        <SubMenuItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
          entries={entry.children}
        />
      );
    }
    case 'nav': {
      return (
        <NavItem
          key={`${i}:${entry.to}`}
          to={entry.to}
          iconComponent={entry.icon}
          label={entry.label}
        />
      );
    }
    case 'action': {
      return (
        <ActionItem
          key={`${i}:${entry.label}`}
          onClick={entry.action}
          iconComponent={entry.icon}
          label={entry.label}
        />
      );
    }
    default: {
      return (
        <ListItem key={`${i}:${entry.type}`}>
          <ListItemText>Type ({entry.type}) Unknown</ListItemText>
        </ListItem>
      );
    }
  }
};

const ForumMenuItem = () => {
  return isFeatureEnabled('fixMenuHighlight') ? (
    <ForumLink
      href="https://www.npx-forum.ca"
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      <MenuItem
        iconComponent={ForumIcon}
        label="Forum"
      />
    </ForumLink>
  ) : (
    <div
      href="https://www.npx-forum.ca"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#ffffff', textDecoration: 'none', font: '500 14px Roboto' }}
    >
      <ForumLink>
        <MenuItem
          iconComponent={ForumIcon}
          label="Forum"
        />
      </ForumLink>
    </div>
  );
};

const MenuList = ({ entries }) => {
  const items = entries.map(entryToItem);

  return (
    <List component="nav">
      {items}
      <ForumMenuItem />
    </List>
  );
};

export default MenuList;
