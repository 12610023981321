import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import TableCell from '@material-ui/core/TableCell';
import CellText from './CellText';

const Container = styled(TableCell)`
  position: relative;
  border: none;
  overflow-wrap: break-word;
  padding-top: 14px;
  padding-bottom: 14px;
`;

const BodyCell = styled(
  ({
    className,
    renderPropBefore: ElementBefore = null,
    renderPropAfter: ElementAfter = null,
    action,
    column,
    children,
  }) => (
    <Container
      key={column.id + action.id + 'container'}
      className={className}
      action={action}
      column={column}
      align={column.align}
      style={{ maxWidth: column.maxWidth }}
    >
      {R.has('render', column) ? (
        column.render(action, column)
      ) : (
        <>
          {ElementBefore && (
            <ElementBefore
              action={action}
              column={column}
            />
          )}
          <CellText>
            {children || null}
            {action[column.id]}
          </CellText>

          {ElementAfter && (
            <ElementAfter
              action={action}
              column={column}
            />
          )}
        </>
      )}
    </Container>
  )
)``;

export default BodyCell;
