import { useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';

import { withUpdatedManualValue } from '@repo/echo-scorecard';
import { useDispatch } from 'react-redux';
import * as scorecardActions from '../state/scorecard/actions';

const useEditManualIndicatorValue = (elementId, year, quarter, scorecard) => {
  const dispatch = useDispatch();
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);
  const [selectedElementUnit, setSelectedElementUnit] = useState(null);

  const manualIndicatorValue = useMemo(() => {
    const indicator = R.pathOr(null, ['scores', selectedIndicatorId, selectedElementUnit], scorecard);
    return indicator
      ? {
          id: selectedIndicatorId,
          ...indicator,
        }
      : null;
  }, [scorecard, selectedIndicatorId, selectedElementUnit]);

  const editManualIndicatorValue = useCallback(
    (indicatorId, elementUnit) => {
      setSelectedIndicatorId(indicatorId);
      setSelectedElementUnit(elementUnit);
    },
    [setSelectedIndicatorId, setSelectedElementUnit]
  );

  const cancelEditManualIndicatorValue = useCallback(() => {
    setSelectedIndicatorId(null);
    setSelectedElementUnit(null);
  }, [setSelectedIndicatorId, setSelectedElementUnit]);

  const saveManualIndicatorValue = useCallback(
    (value, colour, comment) => {
      const manualValue = {
        indicatorId: selectedIndicatorId,
        unitId: selectedElementUnit,
        value,
        colour,
        comment,
      };
      const updatedScorecard = withUpdatedManualValue(manualValue, scorecard);
      dispatch(
        scorecardActions.setManualIndicatorValueRequest(
          elementId,
          year,
          quarter,
          selectedIndicatorId,
          selectedElementUnit,
          value,
          colour,
          comment,
          updatedScorecard
        )
      );
      setSelectedIndicatorId(null);
      setSelectedElementUnit(null);
    },
    [dispatch, elementId, year, quarter, scorecard, selectedIndicatorId, selectedElementUnit]
  );

  return {
    manualIndicatorValue,
    editManualIndicatorValue,
    cancelEditManualIndicatorValue,
    saveManualIndicatorValue,
  };
};

export default useEditManualIndicatorValue;
