import { NoteInput } from '../../../types/cwt/workorder';
import { Path, Remote } from '../../types';

const getAllWorkorders =
  (path: Path, remote: Remote) =>
  (params: { manuallyAdded?: boolean; status?: 'active' | 'complete' } = {}) =>
    remote.get(path(`/`), { params });
const removeManuallyAddedWorkorder = (path: Path, remote: Remote) => (id: string) => remote.del(path(`/${id}`));
const getWorkorder = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}`));
const getNewWorkorder = (path: Path, remote: Remote) => (wonum: string) => remote.get(path(`/${wonum}/verify`));
const insertNewWorkorder = (path: Path, remote: Remote) => (wonum: string) => remote.post(path(`/${wonum}`));
const getWorkorderNotes = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/notes`));
const createWorkorderNote = (path: Path, remote: Remote) => (data: NoteInput) =>
  remote.post(path(`/${data.workorderId}/notes`), data);
const updateWorkorderNote = (path: Path, remote: Remote) => (id: number, data: NoteInput) =>
  remote.put(path(`/${data.workorderId}/notes/${id}`), data);
const getWorkorderEngHolds = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/holds/eng`));
const getWorkorderSupplyChainHolds = (path: Path, remote: Remote) => (id: number) =>
  remote.get(path(`/${id}/holds/sc`));
const getWorkorderRelatedPms = (path: Path, remote: Remote) => (id: number) => remote.get(path(`/${id}/related-pms`));
const updateOverrides = (path: Path, remote: Remote) => (id: number, groupId: number, overrideValue: boolean | null) =>
  remote.put(path(`/${id}/overrides`), { workorderId: id, groupId: groupId, overrideValue: overrideValue });
const getAllWorkorderFlags = (path: Path, remote: Remote) => () => remote.get(path(`/flags`));
const addWorkorderFlag = (path: Path, remote: Remote) => (label: string) => remote.post(path(`/flags`), { label });
const disableWorkorderFlag = (path: Path, remote: Remote) => (id: number) => remote.patch(path(`/flags/${id}/disable`));
const updateWorkorderFlags = (path: Path, remote: Remote) => (workorderId: number, flagIds: number[]) =>
  remote.patch(path(`/flags/${workorderId}/workorder`), { flagIds });
const getWorkorderFlags = (path: Path, remote: Remote) => (workorderId: number) =>
  remote.get(path(`/flags/${workorderId}/workorder`));
const getAllDelayReasons = (path: Path, remote: Remote) => () => remote.get(path(`/delay-reasons`));
const addDelayReason = (path: Path, remote: Remote) => (label: string) =>
  remote.post(path(`/delay-reasons`), { label });
const disableDelayReason = (path: Path, remote: Remote) => (id: number) =>
  remote.patch(path(`/delay-reasons/${id}/disable`));
const createWorkorderDelayReason =
  (path: Path, remote: Remote) => (workorderId: number, delayReasonId: number, notes: string) =>
    remote.post(path(`/delay-reasons/${workorderId}/workorder`), { delayReasonId, notes });
const getAllWorkorderDelayReasons = (path: Path, remote: Remote) => (workorderId: number) =>
  remote.get(path(`/delay-reasons/${workorderId}/workorder`));
const updateWorkorderDelayReason = (path: Path, remote: Remote) => (id: number, delayReasonId: number, notes: string) =>
  remote.patch(path(`/delay-reasons/${id}/workorder`), { delayReasonId, notes });
const deleteWorkorderDelayReason = (path: Path, remote: Remote) => (id: number) =>
  remote.patch(path(`/delay-reasons/${id}/delete`));

const workorder = (path: Path, remote: Remote) => ({
  getAllWorkorders: getAllWorkorders(path, remote),
  getWorkorder: getWorkorder(path, remote),
  createWorkorderNote: createWorkorderNote(path, remote),
  updateWorkorderNote: updateWorkorderNote(path, remote),
  getWorkorderNotes: getWorkorderNotes(path, remote),
  getWorkorderEngHolds: getWorkorderEngHolds(path, remote),
  getWorkorderSupplyChainHolds: getWorkorderSupplyChainHolds(path, remote),
  getWorkorderRelatedPms: getWorkorderRelatedPms(path, remote),
  updateOverrides: updateOverrides(path, remote),
  getNewWorkorder: getNewWorkorder(path, remote),
  insertNewWorkorder: insertNewWorkorder(path, remote),
  removeManuallyAddedWorkorder: removeManuallyAddedWorkorder(path, remote),
  getAllWorkorderFlags: getAllWorkorderFlags(path, remote),
  addWorkorderFlag: addWorkorderFlag(path, remote),
  disableWorkorderFlag: disableWorkorderFlag(path, remote),
  updateWorkorderFlags: updateWorkorderFlags(path, remote),
  getWorkorderFlags: getWorkorderFlags(path, remote),
  getAllDelayReasons: getAllDelayReasons(path, remote),
  addDelayReason: addDelayReason(path, remote),
  disableDelayReason: disableDelayReason(path, remote),
  createWorkorderDelayReason: createWorkorderDelayReason(path, remote),
  getAllWorkorderDelayReasons: getAllWorkorderDelayReasons(path, remote),
  updateWorkorderDelayReason: updateWorkorderDelayReason(path, remote),
  deleteWorkorderDelayReason: deleteWorkorderDelayReason(path, remote),
});

export default workorder;
