import { useCallback, useState } from 'react';
import type {
  UpdateRequestState,
  ReorderState,
  RequireInL,
  RequireInR,
  RequireInT,
  StaleDataState,
  VisibilityState,
  UiState,
} from './types';
import { useDispatch } from 'react-redux';
import { type MyElement } from '../../types/my';
import { type ElementAction } from '../../types/actions';
import helpers from './helpers';
import useActionVisibilityEventHandlers from './action-event-handling';
import useActionVisibilityStaticValues from './hook-static-values';
import useActionVisibilityEffects from './use-effect-updates';
const { reloadActionsFactory, selectRiskActions, selectStaleActions, compareObject, updateUiStateFactory } = helpers;
type Params = { isReadOnly?: boolean; elementId?: MyElement['elementId'] | null; riskManagementId?: number | null };
const useActionVisibilityHooks = <T extends RequireInT & ElementAction, L extends RequireInL<A>, R, A>({
  isReadOnly = false,
  elementId,
  riskManagementId,
}: Params) => {
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState<UiState<A>>({
    isReadOnly,
    actionState: 'ongoing' as A,
    riskManagementId,
    elementId,
    isShowingHiddenActions: false,
  });

  const isShowingHiddenActions = !!uiState.isShowingHiddenActions;
  const actionState: A = uiState.actionState;
  const updateUiState = useCallback(updateUiStateFactory<L, A>(setUiState), [setUiState]);

  const [staleDataState, setStaleData] = useState<StaleDataState<T[]>>({
    actions: { ongoing: null, complete: null },
  });
  const [updateRequestData, setUpdateFromRequest] = useState<UpdateRequestState<T[]>>({
    handledData: { ongoing: false, complete: false },
    retrievePromise: null,
    isLoading: false,
    isError: false,
  });
  // NOTE: This could be expanded like visiblity to allow multiple actions same-time but mimicing existing functionality for now
  const [reorderRequest, setReorderRequest] = useState<ReorderState<T, R & RequireInR>>({
    actionPromise: null,
    isLoading: false,
    isError: false,
    payload: null,
  });
  const [visibilityUpdating, setVisibilityUpdating] = useState<VisibilityState<T>>({});

  const reloadOngoingActions = useCallback(reloadActionsFactory<T, A>({ actionState: 'ongoing' as A, dispatch }), [
    dispatch,
  ]);
  const reloadCompletedActions = useCallback(reloadActionsFactory<T, A>({ actionState: 'complete' as A, dispatch }), [
    dispatch,
  ]);
  useActionVisibilityEffects<T, L, A>({
    updateUiState,
    updateRequestData,
    setUpdateFromRequest,
    reloadCompletedActions,
    reloadOngoingActions,
    setStaleData,
    riskManagementId,
    elementId,
    actionState,
    isShowingHiddenActions,
  });
  const {
    isReorderingLocked,
    ongoingState,
    completedState,
    ongoingActions,
    completedActions,
    isShowingShowingNewMessage,
    isBackgroundLoading,
    isLoading,
    isError,
    hiddenItems,
    staleData,
    actions,
  } = useActionVisibilityStaticValues<T, R, A>({
    elementId,
    riskManagementId,
    reorderRequest,
    updateRequestData,
    visibilityUpdating,
    isShowingHiddenActions,
    actionState,
    staleDataState,
  });

  const passiveLoadingControl = useCallback(
    (isLoadingParam: boolean, payload?: any[] | null) => {
      // NOTE: May not be able to remove this because of updates from dialog's aka other
      // redux-state updates can happen!
      if (payload && isBackgroundLoading) {
        const stored = selectRiskActions<RequireInT & T, A>({
          isLoading,
          isError,
          isShowingHiddenActions,
          actionState,
          actions: { complete: completedActions, ongoing: ongoingActions },
          staleData: staleDataState.actions,
        });
        const stale = selectStaleActions<RequireInT & T, A>({
          isLoading,
          isError,
          isShowingHiddenActions,
          actionState,
          actions: { complete: completedActions, ongoing: ongoingActions },
          staleData: staleDataState.actions,
        });
        const storedActions = isShowingHiddenActions ? stored.hiddenItems : stored.actions;
        const staleActions = isShowingHiddenActions ? stale.hiddenItems : stale.actions;
        if (staleActions && compareObject(payload, staleActions)) {
          return false;
        }
        if (storedActions && compareObject(payload, storedActions)) {
          return false;
        }
      }
      return isLoadingParam;
    },
    [isBackgroundLoading, isShowingHiddenActions, isLoading, isError, actionState, staleDataState]
  );

  const {
    updatedActionOrder,
    onToggleShipChipRiskVisibility,
    onReorder,
    onReloadClick,
    onClickNewMessageClose,
    onToggleClick,
  } = useActionVisibilityEventHandlers<T, L, R, A>({
    visibilityUpdating,
    setVisibilityUpdating,
    actions,
    staleDataState,
    elementId,
    reorderRequest,
    setReorderRequest,
    ongoingActions,
    completedActions,
    isLoading,
    updateRequestData,
    updateUiState,
    riskManagementId,
    setStaleData,
    setUpdateFromRequest,
    actionState,
    isShowingHiddenActions,
  });

  return {
    updateRequestData,
    setUpdateFromRequest,
    reloadOngoingActions,
    reloadCompletedActions,
    reorderRequest,
    setReorderRequest,
    actionState,
    staleData,
    actions,
    staleDataState,
    ongoingState,
    completedState,
    hiddenItems,
    ongoingActions,
    completedActions,
    isShowingHiddenActions,
    isReorderingLocked,
    isError,
    isLoading,
    onReorder,
    onToggleShipChipRiskVisibility,
    updatedActionOrder,
    visibilityUpdating,
    setVisibilityUpdating,
    passiveLoadingControl,
    isBackgroundLoading,
    isShowingShowingNewMessage,
    onClickNewMessageClose,
    onReloadClick,
    onToggleClick,
    updateUiState,
    uiState,
  };
};
export default useActionVisibilityHooks;
