import { Grid } from '@material-ui/core';
import Page from '../../../../components/Page';
import { useActions } from '../../hooks/useActions';
import DropdownList from './components/DropdownList';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { useWorkOrderFlags } from '../../hooks/useWorkOrderFlags';
import { Alert } from '@material-ui/lab';
import Tab from '../../../../components/Tabs/Tab';
import styled from 'styled-components';
import Tabs from '../../../../components/Tabs/Tabs';
import { useMemo, useState } from 'react';
import TabPanel from '../../../../components/Tabs/TabPanel';
import { useTPlusOne } from '../../hooks/useTPlusOne';

export const ActionsTab = styled(Tab).attrs({
  label: 'Actions',
})`
  min-width: 109px;
`;
export const WorkOrdersTab = styled(Tab).attrs({
  label: 'Work Orders',
})`
  min-width: 109px;
`;
export const TPluseOneTab = styled(Tab).attrs({
  label: 'T+1',
})`
  min-width: 109px;
`;
const StyledTabPanel = styled(TabPanel)`
  height: 75vh;
`;

enum DROPDOWN_TAB {
  ACTIONS = 0,
  WORK_ORDERS = 1,
  TPLUSONE = 2,
}

const DropdownMaintenancePage = () => {
  const {
    flags,
    removeActionFlag,
    addActionFlag,
    newActionFlag,
    setNewActionFlag,
    removeActionGroup,
    newActionGroup,
    setNewActionGroup,
    addActionGroup,
    groups,
    statuses,
    removeActionStatus,
    newActionStatus,
    setNewActionStatus,
    addActionStatus,
  } = useActions();
  const { workorderFlags, newWorkflowFlag, setNewWorkflowFlag, addWorkorderFlag, removeWorkorderFlag } =
    useWorkOrderFlags();
  const { newDelayReason, setNewDelayReason, removeDelayReason, addDelayReason, delayReasons } = useTPlusOne();
  const [tabsState, setTabsState] = useState<number>(0);

  const delayReasonsList = useMemo(() => {
    return delayReasons.filter(reason => reason.isEnabled);
  }, [delayReasons]);
  return (
    <Page title="Modify Dropdowns">
      <Grid container>
        {isFeatureEnabled('workorderFlags') && (
          <Grid
            item
            xs={12}
          >
            <Alert
              variant="outlined"
              severity="warning"
              style={{ width: '50%', marginBottom: '10px' }}
            >
              Caution: Modifying these menus affects both Bruce A & Bruce B users
            </Alert>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Tabs
            value={tabsState}
            onChange={(_, newValue) => {
              return setTabsState(newValue);
            }}
          >
            <ActionsTab
              key={'actions-tab'}
              value={DROPDOWN_TAB.ACTIONS}
            />
            {isFeatureEnabled('workorderFlags') && (
              <WorkOrdersTab
                key={'work-orders-tab'}
                value={DROPDOWN_TAB.WORK_ORDERS}
              />
            )}
            {isFeatureEnabled('cwt_tplus1') && (
              <TPluseOneTab
                key={'t-plus-one-tab'}
                value={DROPDOWN_TAB.TPLUSONE}
              />
            )}
          </Tabs>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <StyledTabPanel
            value={DROPDOWN_TAB.ACTIONS}
            currentTab={tabsState}
          >
            <Grid
              container
              item
              xs={12}
              spacing={2}
            >
              <Grid
                item
                xs={3}
              >
                <DropdownList
                  title="Group"
                  onDelete={removeActionGroup}
                  entries={groups}
                  onAdd={addActionGroup}
                  newEntry={newActionGroup}
                  setNewEntry={setNewActionGroup}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <DropdownList
                  title="Flags"
                  onDelete={removeActionFlag}
                  entries={flags}
                  onAdd={addActionFlag}
                  newEntry={newActionFlag}
                  setNewEntry={setNewActionFlag}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <DropdownList
                  title="Status"
                  onDelete={removeActionStatus}
                  entries={statuses}
                  onAdd={addActionStatus}
                  newEntry={newActionStatus}
                  setNewEntry={setNewActionStatus}
                />
              </Grid>
            </Grid>
          </StyledTabPanel>

          {isFeatureEnabled('workorderFlags') && (
            <StyledTabPanel
              value={DROPDOWN_TAB.WORK_ORDERS}
              currentTab={tabsState}
            >
              <Grid
                container
                item
                xs={12}
                spacing={2}
              >
                <Grid
                  item
                  xs={3}
                >
                  <DropdownList
                    title="Flags"
                    onDelete={removeWorkorderFlag}
                    entries={workorderFlags}
                    onAdd={addWorkorderFlag}
                    newEntry={newWorkflowFlag}
                    setNewEntry={setNewWorkflowFlag}
                  />
                </Grid>
              </Grid>
            </StyledTabPanel>
          )}
          {isFeatureEnabled('cwt_tplus1') && (
            <StyledTabPanel
              value={DROPDOWN_TAB.TPLUSONE}
              currentTab={tabsState}
            >
              <Grid
                container
                item
                xs={12}
                spacing={2}
              >
                <Grid
                  item
                  xs={3}
                >
                  <DropdownList
                    title="Delay Reasons"
                    onDelete={removeDelayReason}
                    entries={delayReasonsList}
                    onAdd={addDelayReason}
                    newEntry={newDelayReason}
                    setNewEntry={setNewDelayReason}
                  />
                </Grid>
              </Grid>
            </StyledTabPanel>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default DropdownMaintenancePage;
