import React from 'react';
import NavButton from './NavButton';

/**
 * Top-level navigation menu item
 *
 * This component should not be used for items within a submenu
 */
const NavItem = ({ className, to, iconComponent, label, isSelected }) => (
  <NavButton
    className={className}
    to={to}
    iconComponent={iconComponent}
    label={label}
    isSelected={isSelected}
    // For top-level nav items, any child path should match
    exact={false}
  />
);

export default NavItem;
