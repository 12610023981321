import { useState } from 'react';
import { ScheduleContextProvider } from '../hooks/useSchedule';
import Schedule from './components/Schedule';

const SchedulePage = () => {
  const [displayCalendar, setDisplayCalendar] = useState<boolean>(true);
  return (
    <ScheduleContextProvider allSchedules={true}>
      <Schedule
        displayCalendar={displayCalendar}
        setDisplayCalendar={setDisplayCalendar}
      />
    </ScheduleContextProvider>
  );
};
export default SchedulePage;
