import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import styled from 'styled-components';
import UnstyledActionButton from '../../../../components/ActionButton';
import CustomTextField from '../../../../components/CustomTextField';
import Page from '../../../../components/Page';
import useAddNewWorkorder from '../../hooks/useAddNewWorkorder';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import WOTable from './components/WOTable';

const ErrorMessage = styled(Typography)`
  font: ${({ theme }) => theme.fonts.bold14};
  color: ${({ theme }) => theme.colours.reds.red1};
  margin-left: 0.5vw;
`;

const FindWOTextField = styled(CustomTextField)`
  margin-right: 1vw;
  margin-bottom: 1vh;
  letter-spacing: 0.5px;
  & .MuiInputBase-root {
    height: 4.6vh;
    width: 20vw;
  }
  & .MuiFilledInput-root {
    border-radius: 0;
  }
  & .MuiFilledInput-inputMarginDense {
    padding-top: 10px;
    padding-bottom: 6px;
  }
`;

const ActionButton = styled(UnstyledActionButton)`
  height: 4.9vh;
  width: 10vw;
  margin-right: 0.5vw;
`;

const AddDeleteWorkOrderButton = styled(ActionButton)`
  width: 20vw;
`;

const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const AddNewWorkorderPage = () => {
  const {
    workOrderNumber,
    fetchWorkOrder,
    workOrder,
    setWorkOrderNumber,
    addNewWorkOrder,
    errorMessage,
    loading,
    isBacklog,
    setIsBacklog,
    removeManualWorkorder,
    setSelectedWorkOrderId,
    selectedWorkOrderId,
    manualWorkOrders,
  } = useAddNewWorkorder();

  return (
    <Page title="Add New WO">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          container
          alignItems="center"
        >
          <Grid container>
            <Typography>Find WO</Typography>
          </Grid>
          <Grid item>
            <FindWOTextField
              variant="filled"
              placeholder="Type WO number..."
              value={workOrderNumber || ''}
              onChange={e => setWorkOrderNumber(e.target.value)}
            />
          </Grid>
          <Grid item>
            <ActionButton
              color="primary"
              variant="text"
              onClick={fetchWorkOrder}
            >
              Find WO
            </ActionButton>
          </Grid>
          {errorMessage && (
            <>
              <Grid item>
                <CancelOutlinedIcon />
              </Grid>
              <Grid item>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Grid>
            </>
          )}
          {workOrder && (
            <Grid item>
              <CheckCircleOutlineOutlinedIcon />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
        >
          <AddDeleteWorkOrderButton
            color="primary"
            variant="text"
            disabled={!workOrder}
            onClick={addNewWorkOrder}
          >
            Add to Corrective Backlog
          </AddDeleteWorkOrderButton>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
        >
          <Grid
            item
            xs={10}
          >
            <FormControlLabel
              control={
                <WaitSwitch
                  checked={isBacklog}
                  onChange={e => setIsBacklog(e.target.checked)}
                  name="inBacklog"
                />
              }
              label="In Backlog"
            />
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent="flex-end"
          >
            <AddDeleteWorkOrderButton
              className="error"
              onClick={removeManualWorkorder}
              disabled={!selectedWorkOrderId}
              style={{ marginBottom: '5px' }}
            >
              Remove WO From Tracker
            </AddDeleteWorkOrderButton>
          </Grid>
        </Grid>
      </Grid>
      <WOTable
        workOrders={manualWorkOrders}
        loading={loading}
        setSelectedWorkOrderId={setSelectedWorkOrderId}
      />
    </Page>
  );
};

export default AddNewWorkorderPage;
