import { type SetStateAction, type Dispatch as StateDispatch } from 'react';
import { type Dispatch } from 'redux';
import api from '../../api';
import { type MyElement } from '../../types/my';
import * as actionActions from '../../state/actions/actions';
import type { RequireInL, RequireInT, UiState } from './types';

type SelectRiskActionsPayloadParams<T, A> = {
  isLoading: boolean;
  isError: boolean;
  isShowingHiddenActions: boolean;
  actionState?: A;
  staleData: { ongoing: null | (T & RequireInT)[]; complete: null | (T & RequireInT)[] };
  actions: { ongoing: null | (T & RequireInT)[]; complete: null | (T & RequireInT)[] };
};
const filterVisibleActions = <T extends RequireInT>(actions?: (T & RequireInT)[] | null) =>
  actions && actions.length > 0 ? actions.filter(({ isHidden }) => isHidden) : [];
const selectStaleActions = <T extends RequireInT, A>(payload: SelectRiskActionsPayloadParams<T, A>) => {
  const { isShowingHiddenActions, actionState = 'ongoing', staleData: staleDataActions } = payload;
  const staleData = actionState === 'ongoing' ? staleDataActions.ongoing : staleDataActions.complete;
  const hiddenItems = [
    ...filterVisibleActions(staleDataActions.ongoing),
    ...filterVisibleActions(staleDataActions.complete),
  ];

  return { actions: isShowingHiddenActions ? hiddenItems : staleData, hiddenItems };
};
const selectRiskActions = <T extends RequireInT, A>(payload: SelectRiskActionsPayloadParams<T, A>) => {
  const {
    isLoading,
    isError,
    isShowingHiddenActions,
    actionState = 'ongoing',
    actions: { ongoing: ongoingActions, complete: completeActions },
  } = payload;
  if (!isError && isLoading) {
    return helpers.selectStaleActions({ ...payload, actionState });
  }
  const actions = actionState === 'ongoing' ? ongoingActions : completeActions;
  const hiddenItems = [...filterVisibleActions(ongoingActions), ...filterVisibleActions(completeActions)];

  return { actions: isShowingHiddenActions ? hiddenItems : actions, hiddenItems };
};
const compareObject = (objectA: any, objectB: any) => {
  if (objectA === objectB) {
    return true;
  }
  // if boolean will fail to match if the above wasn't true
  if (typeof objectA === 'boolean' || typeof objectB === 'boolean') {
    return false;
  }
  if (Array.isArray(objectA) && Array.isArray(objectB) && objectA.length === 0 && objectB.length === 0) {
    return true;
  }
  try {
    return JSON.stringify(objectA) === JSON.stringify(objectB);
  } catch (e: any) {
    console.error('Attempted to compare two objects that could not be JSON parsed');
  }
  return false;
};
const reloadActionsFactory =
  <T, A>({ actionState, dispatch }: { actionState?: A | null; dispatch: Dispatch }) =>
  async ({
    riskManagementId,
    elementId,
  }: {
    riskManagementId?: number | null;
    elementId?: MyElement['elementId'] | null;
  }): Promise<T[]> => {
    // guard from re-render setting base values
    if (
      !actionState ||
      typeof riskManagementId !== 'number' ||
      (typeof elementId !== 'number' && typeof elementId !== 'string')
    ) {
      return [];
    }
    // support redux actions
    dispatch(actionActions.loadActionsRequest(elementId, actionState as string, riskManagementId, true));
    try {
      const actions: T[] = await api.getActions(elementId, actionState, riskManagementId);
      dispatch(
        actionActions.loadActionsSuccess({
          elementId: `${elementId}`,
          state: `${actionState}`,
          actions: actions as unknown[],
          riskManagementId,
        })
      );
      return actions;
    } catch (e: any) {
      dispatch(actionActions.loadActionsFailure(e, { elementId, state: actionState, riskManagementId }));
      throw e;
    }
  };

const updateUiStateFactory =
  <L, A>(setUiState: StateDispatch<SetStateAction<UiState<A>>>) =>
  (params: Partial<L & RequireInL<A>>) => {
    setUiState((prev: any) =>
      ('isReadOnly' in params && prev.isReadOnly !== params.isReadOnly) ||
      ('actionState' in params && prev.actionState !== params.actionState) ||
      ('riskManagementId' in params && prev.riskManagementId !== params.riskManagementId) ||
      ('elementId' in params && prev.elementId !== params.elementId) ||
      ('isShowingHiddenActions' in params && prev.isShowingHiddenActions !== params.isShowingHiddenActions)
        ? {
            ...prev,
            ...params,
          }
        : prev
    );
  };
const helpers = {
  selectStaleActions,
  selectRiskActions,
  compareObject,
  reloadActionsFactory,
  updateUiStateFactory,
};
export default helpers;
