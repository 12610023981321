import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../../../api';
import { Bookmark, FilterBaseType } from '../../../../types/cwt/bookmarks';
import { useUsers } from '../../hooks/useUsers';

export enum FilterEntityType {
  WorkorderFilter = 'WorkorderFilter',
  ActionFilter = 'ActionFilter',
  ScheduleFilter = 'ScheduleFilter',
  TPlusOneWorkorderFilter = 'TPlusOneWorkOrderFilter',
}

export const useBookmarks = <T extends FilterBaseType>(
  type: FilterEntityType,
  filter: T,
  setFilters: (filter: T) => void
) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [newEntry, setNewEntry] = useState('');
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [load, setLoad] = useState(true);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [expectedFilterParams, setExpectedFilterParams] = useState<{ key: keyof T; type: string }[]>([]);
  const [apiError, setApiError] = useState<string | null>(null);
  const [selectedDeleteBookmark, setSelectedDeleteBookmark] = useState<Bookmark | null>(null);
  const { canApplyBookmark } = useUsers();

  const getFilterParams = useCallback(() => {
    const paramsList = Object.keys(filter as object) as (keyof typeof filter)[];
    // since types do not exist in JS at runtime we need to use the default filter passed in to the hook on load to get the expected types
    // after this is done we can update the filter with the selected bookmark and validate against the expected filter params
    const details = paramsList.map(key => {
      const value = filter[key];
      if (Array.isArray(value)) {
        return { key, type: 'array' };
      } else if (value && typeof value === 'boolean') {
        return { key, type: 'boolean' };
      } else {
        return { key, type: typeof value };
      }
    });
    setExpectedFilterParams(details);
  }, []);

  useEffect(() => {
    getFilterParams();
  }, [getFilterParams]);

  useEffect(() => {
    const getBookmarks = async () => {
      try {
        const response = await api.cwt.getAllBookmarks({ type });

        setBookmarks(response);
      } catch (e) {
        setApiError('Error loading bookmarks');
        setBookmarks([]);
        console.error(e);
      }
      setLoad(false);
    };
    if (load) {
      getBookmarks();
    }
  }, [load]);

  useEffect(() => {
    if (expectedFilterParams.length > 0 && bookmarks.length > 0) {
      const defaultBookmark = bookmarks.find(bookmark => bookmark.isDefault);
      if (defaultBookmark && canApplyBookmark(type)) {
        handleSetFilter(defaultBookmark);
      }
    }
  }, [expectedFilterParams, bookmarks]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNewEntry('');
    setErrorMessage(null);
  };
  const handleDeleteClose = () => {
    setSelectedDeleteBookmark(null);
  };

  const saveBookmark = async () => {
    try {
      const savedBookmarkId = await api.cwt.insertBookmark(type, filter, newEntry);
      setSelectedBookmarkId(savedBookmarkId.id);
      setLoad(true);
    } catch (e) {
      setApiError('Error saving bookmark');
      setSelectedBookmarkId(0);
      console.error(e);
    }
    setNewEntry('');
  };

  const validateFilter = (filter: T) => {
    // this reducer checks against the saved runtime filter and ensures that the set object has all expected keys
    // any unexpected keys are removed and keys with improper types are set to default values
    const validatedObject = expectedFilterParams.reduce((accum, param) => {
      const value = filter[param.key];
      if (param.type === 'array') {
        accum[param.key as string] = Array.isArray(value) ? value : [];
      } else if (param.type === 'boolean') {
        accum[param.key as string] = typeof value === 'boolean' ? value : false;
      }
      return accum;
    }, {} as FilterBaseType);
    return validatedObject;
  };

  const handleSetFilter = (bookmark: Bookmark) => {
    const validatedObject = validateFilter(bookmark.filterState as T);

    setFilters(validatedObject as T);
    setSelectedBookmarkId(bookmark.id);
  };

  const saveBookmarkWithValidation = () => {
    if (!newEntry) {
      setErrorMessage('Bookmark name cannot be empty.');
      return;
    }
    if (bookmarks.some(bookmark => bookmark.name.trim() === newEntry.trim())) {
      setErrorMessage('Bookmark name already exists.');
      return;
    }
    setErrorMessage(null);
    saveBookmark();
  };

  const deleteBookmark = async (id: number) => {
    try {
      await api.cwt.deleteBookmark({ id });
    } catch (e) {
      setApiError('Error deleting bookmark');
      console.error(e);
    }
    setSelectedDeleteBookmark(null);
    setLoad(true);
  };

  const updateDefault = async (id: number, isDefault: boolean) => {
    try {
      await api.cwt.updateDefault(id, type, isDefault);
    } catch (e) {
      setApiError('Error updating default bookmark');
      console.error(e);
    }
    setLoad(true);
  };

  const defaultFilterSate = useMemo(() => {
    const defaultFilter = expectedFilterParams.reduce((accum, param) => {
      if (param.type === 'array') {
        accum[param.key as string] = [];
      } else if (param.type === 'boolean') {
        accum[param.key as string] = false;
      }
      return accum;
    }, {} as FilterBaseType);
    return defaultFilter;
  }, [expectedFilterParams]);

  const clearSelectedBookmark = () => {
    setSelectedBookmarkId(undefined);
    setFilters(defaultFilterSate as T);
  };

  return {
    anchorEl,
    newEntry,
    handleClick,
    handleClose,
    setNewEntry,
    saveBookmark,
    bookmarks,
    handleSetFilter,
    selectedBookmarkId,
    errorMessage,
    saveBookmarkWithValidation,
    apiError,
    setApiError,
    deleteBookmark,
    clearSelectedBookmark,
    updateDefault,
    selectedDeleteBookmark,
    setSelectedDeleteBookmark,
    handleDeleteClose,
  };
};
