import { useCallback, useEffect, useState } from 'react';
import useActionState from '../../../../../hooks/action-state';
import useActions from '../../../../../hooks/actions';
import useEditAction from '../../../../../hooks/edit-action';
import useNewAction from '../../../../../hooks/new-action';
import useReorderActions from '../../../../../hooks/reorder-actions';
import { type MyElement } from '../../../../../types/my';
import type { ActionSource, ActionState, ElementAction } from '../../../../../types/actions';
import isFeatureEnabled from '../../../../../utils/feature-flags';

export interface ShipChipHook {
  actions: any[];
  clearDeleteAction: () => void;
  clearEditAction: () => void;
  clearNewAction: () => void;
  clearStatusAction: () => void;
  comment: string;
  confirmActionStatusToggle: (actionId: any) => void;
  confirmDeleteActionToggle: (actionId: any) => void;
  deleteAction: boolean;
  deleteActionStatus: any;
  editAction: any;
  handleOnCancel: () => void;
  handleOnOkay: () => void;
  isLoading: boolean;
  newAction: any;
  reorderActions: (fromIndex: number, toIndex: number) => void;
  saveNewAction: (action: any) => void;
  setComment: (comment: string) => void;
  setState: (actionState: ActionState) => void;
  source: ActionSource;
  setSource: (source: ActionSource) => void;
  showEditActionDialog: () => void;
  showNewActionDialog: () => void;
  state: ActionState;
  statusAction: any;
  updateAction: (action: any) => void;
}
type UseShipsChipsParams = {
  element: MyElement | null;
  actions?: null | ElementAction[];
  riskManagementId?: null | number;
  onReorder?: null | ((payload?: any) => Promise<void>);
  updateUiState?: (params: any) => void;
};
export const useShipsChips = ({
  element,
  updateUiState,
  onReorder,
  actions: actionsParam,
  riskManagementId,
}: UseShipsChipsParams): ShipChipHook => {
  const [actionState, setActionState] = useState<ActionState>('ongoing');
  const [source, setSource] = useState<ActionSource>('All');
  const [comment, setComment] = useState('');
  const { actions, isLoading } = useActions({
    elementId: element?.elementId ?? '',
    actionState,
    actionSource: source,
    ...(riskManagementId ? { riskManagementId } : {}),
  });
  const setState = useCallback(
    (stateInput: ActionState) => {
      setActionState(stateInput);
      if (isFeatureEnabled('riskActionHiddenToggle') && updateUiState) {
        updateUiState({ actionState: stateInput });
      }
    },
    [setActionState, updateUiState]
  );
  useEffect(() => {
    updateUiState && updateUiState({ actionState });
  }, [updateUiState, actionState]);

  const reorderActions = useReorderActions({ actions: actionsParam || actions, state: actionState, onReorder });
  const { editAction, showEditActionDialog, clearEditAction, updateAction } = useEditAction(actions);
  const { newAction, showNewActionDialog, clearNewAction, saveNewAction } = useNewAction(element as MyElement);
  const {
    statusAction,
    confirmActionStatusToggle,
    clearStatusAction,
    toggleActionStatus,
    deleteActionStatus,
    confirmDeleteActionToggle,
    deleteAction,
    clearDeleteAction,
  } = useActionState(actions);

  const handleOnOkay = () => {
    toggleActionStatus(comment);
    setComment('');
  };

  const handleOnCancel = () => {
    clearStatusAction();
    setComment('');
  };

  return {
    actions: actionsParam || actions,
    clearDeleteAction,
    clearEditAction,
    clearNewAction,
    clearStatusAction,
    comment,
    confirmActionStatusToggle,
    confirmDeleteActionToggle,
    deleteAction,
    deleteActionStatus,
    editAction,
    handleOnCancel,
    handleOnOkay,
    isLoading,
    newAction,
    reorderActions,
    saveNewAction,
    setComment,
    setState,
    source,
    setSource,
    showEditActionDialog,
    showNewActionDialog,
    state: actionState,
    statusAction,
    updateAction,
  };
};
