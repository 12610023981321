import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { type Scorecard } from '@repo/echo-scorecard';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import useScorecard from '../../hooks/scorecard';
import useForecast from '../../hooks/forecast';
import { AllColourValuesProvider } from '../../hooks/all-colour-values';
import ForecastChart from '../ForecastChart';
import useActions from '../../hooks/actions';
import useElementActuals, { ActualsProvider } from '../../hooks/useElementActuals';
import { Report } from '../../hooks/report';
import { ScorecardErrorText as ErrorMessage } from '../ErrorText';
import isFeatureEnabled from '../../utils/feature-flags';
import { formatDistance } from 'date-fns';

interface Props {
  actions: any[];
  scorecard: Scorecard;
}
const LoadingOverlay = styled(CircularProgress)``;
const BackgroundLoadingOverlay = styled.div``;

const InnerContainer = ({ actions, scorecard }: Props) => {
  const historicData = useElementActuals();

  const forecastEvents = useForecast(actions, scorecard);
  const forecastData = R.map(
    ({ date, scorecard: forecastScorecard, actions: forecastActions }) => ({
      date,
      score: forecastScorecard.totals.score,
      actions: forecastActions,
    }),
    forecastEvents
  );

  return (
    <ForecastChart
      historicData={historicData}
      forecastData={forecastData}
    />
  );
};

interface ContainerProps {
  report: Report;
}

const ForecastContainer = ({ report }: ContainerProps) => {
  const {
    scorecardDisplayable: scorecard,
    scorecard: scorecardActual,
    scorecardErrorMessage,
    staleData: scordcardStaleDate,
    isLoading,
    lastUpdatedAt,
    isBackgroundLoading,
  } = useScorecard({
    elementName: report.elementName,
    elementId: report.elementId,
    year: parseInt(report.year, 10),
    quarter: parseInt(report.quarter, 10),
  });
  const { actions } = useActions({ elementId: report.elementId, actionState: 'ongoing' });

  return (
    <AllColourValuesProvider>
      {isFeatureEnabled('echoClientCaching') && isBackgroundLoading && (
        <BackgroundLoadingOverlay>
          <Typography>
            The Scorecard Data displayed is a cached version retrieved
            {` ${formatDistance(lastUpdatedAt, new Date(), { addSuffix: true })}`}
          </Typography>
        </BackgroundLoadingOverlay>
      )}
      {!isLoading ? (
        <ErrorMessage
          scorecardDisplayable={scorecard}
          scorecard={scorecardActual}
          staleData={scordcardStaleDate}
          scorecardErrorMessage={scorecardErrorMessage}
          noDataMessage="Please try to view a Forecast again or 'reload' the page from the browser."
        />
      ) : null}
      {isLoading && <LoadingOverlay size={20} />}
      {scorecard ? (
        <ActualsProvider
          scorecard={scorecard}
          elementId={report.elementId}
        >
          <InnerContainer
            actions={actions}
            scorecard={scorecard}
          />
        </ActualsProvider>
      ) : null}
    </AllColourValuesProvider>
  );
};

export default ForecastContainer;
