"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withTotalUnitScoreOverride = exports.withTotalScores = void 0;
const R = __importStar(require("ramda"));
const scores_1 = require("./scores");
const colour_curves_1 = require("./colour-curves");
const units_1 = require("./units");
const withTotalScores = (scorecard) => {
    const totalUnitScores = R.reduce((acc, unitId) => (Object.assign(Object.assign({}, acc), { [unitId]: R.sum(R.map((indicatorId) => (0, scores_1.getWeightedIndicatorScore)(indicatorId, unitId, scorecard), scorecard.indicators)) })), {}, scorecard.units);
    const score = R.reduce((acc, [key, value]) => acc + ((0, units_1.getUnitWeight)(key, scorecard) / 100) * value, 0, Object.entries(totalUnitScores));
    return Object.assign(Object.assign({}, scorecard), { totals: {
            score,
            colour: (0, colour_curves_1.colourFromCurve)(scorecard.totalScoreCurve, score),
            byUnit: R.reduce((acc, unitId) => (Object.assign(Object.assign({}, acc), { [unitId]: {
                    score: totalUnitScores[unitId],
                    colour: (0, colour_curves_1.colourFromCurve)(scorecard.totalScoreCurve, totalUnitScores[unitId]),
                } })), {}, scorecard.units),
        } });
};
exports.withTotalScores = withTotalScores;
exports.withTotalUnitScoreOverride = R.curry((unitTotalOverride, scorecard) => {
    if (R.isEmpty(unitTotalOverride) || R.isNil(unitTotalOverride))
        return scorecard;
    const byUnit = Object.assign({}, scorecard.totals.byUnit);
    unitTotalOverride
        .filter(({ override }) => Boolean(override))
        .forEach(({ colour, score, comment, override, unitId }) => {
        byUnit[unitId] = Object.assign(Object.assign({}, byUnit[unitId]), { colour,
            score,
            comment,
            override });
        return null;
    });
    // Note: why does the above reduce function not work here....
    const score = R.compose(R.sum, R.map(([key, value]) => ((0, units_1.getUnitWeight)(key, scorecard) / 100) * value.score), Object.entries)(byUnit);
    const colour = (0, colour_curves_1.colourFromCurve)(scorecard.totalScoreCurve, score);
    return Object.assign(Object.assign({}, scorecard), { totals: {
            score,
            colour,
            byUnit,
        } });
});
