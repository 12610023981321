import React from 'react';
import { type MyElement } from '../../types/my';
import type { ElementAction, ActionState } from '../../types/actions';
import { type ReorderActionsRequestParams } from '../../state/actions/actions';
import type { RequireInT, RequireInR, RequireInL, LocationState } from './types';
import { InformationBox, useActionVisibilityElements } from './elements';
import useActionVisibilityHooks from './hook';
import { defaultContext, ActionVisibilityContext } from './context';

type Props = {
  isReadOnly?: boolean;
  riskManagementId?: number | null;
  elementId?: MyElement['elementId'] | null;
} & React.PropsWithChildren;
const ActionVisibilityProvider = <
  T extends RequireInT & ElementAction,
  L extends RequireInL<A> & LocationState<A>,
  R extends RequireInR & ReorderActionsRequestParams,
  A extends ActionState
>(
  props: Props
) => {
  const { isReadOnly = false, elementId, riskManagementId } = props;
  const {
    reorderRequest,
    actionState,
    staleData,
    actions,
    staleDataState,
    updateRequestData,
    hiddenItems,
    ongoingActions,
    completedActions,
    isShowingHiddenActions,
    isReorderingLocked,
    isError,
    isLoading,
    onReorder,
    onToggleShipChipRiskVisibility,
    visibilityUpdating,
    passiveLoadingControl,
    isBackgroundLoading,
    isShowingShowingNewMessage,
    onClickNewMessageClose,
    onReloadClick,
    onToggleClick,
    updateUiState,
    uiState,
  } = useActionVisibilityHooks<T, L, R, A>({
    isReadOnly,
    elementId,
    riskManagementId,
  });
  const {
    rowIterator,
    rowHeaderIterator,
    EmptyResults,
    ActionVisibilityIcon,
    NewMessageNotification,
    ActionLoadingStatus,
    SeeHiddenLink,
  } = useActionVisibilityElements<T, A, R>({
    isReadOnly,
    elementId,
    riskManagementId,
    isBackgroundLoading,
    onReloadClick,
    isShowingHiddenActions,
    ongoingActions,
    completedActions,
    visibilityUpdating,
    hiddenItems,
    isShowingShowingNewMessage,
    onClickNewMessageClose,
    onToggleShipChipRiskVisibility,
    actions,
    actionState,
    updateRequestData,
    onToggleClick,
    isReorderingLocked,
    reorderRequest,
    staleDataState,
  });

  if (!elementId || !riskManagementId || !actionState) {
    const actionStateValue: A | null =
      actionState === 'ongoing' || actionState === 'complete' ? (actionState as A) : null;
    const value = {
      ...defaultContext,
      isReadOnly,
      actionState: actionStateValue,
      elementId: typeof elementId !== 'undefined' ? elementId : null,
      riskManagementId: typeof riskManagementId !== 'undefined' ? riskManagementId : null,
      updateUiState,
      uiState,
    };

    return <ActionVisibilityContext.Provider value={value}>{props.children}</ActionVisibilityContext.Provider>;
  }
  const value = {
    isReadOnly,
    actionState,
    elementId,
    riskManagementId,
    rowIterator,
    rowHeaderIterator,
    onReorder,
    renderPropsEmptyResults: EmptyResults,
    renderPropsNewMessageNotification: NewMessageNotification,
    renderPropSeeHidden: SeeHiddenLink,
    renderPropVisibilityIcon: ActionVisibilityIcon,
    renderPropLoadingProgress: ActionLoadingStatus,
    renderPropInformationBox: InformationBox,
    ongoingActions,
    completedActions,
    reorderRequest,
    visibilityUpdating,
    actions: actions || [],
    staleData,
    passiveLoadingControl,
    isShowingShowingNewMessage,
    isShowingHiddenActions,
    isReorderingLocked,
    isLoading,
    isError,
    updateUiState,
    uiState,
  };

  return <ActionVisibilityContext.Provider value={value}>{props.children}</ActionVisibilityContext.Provider>;
};
export default ActionVisibilityProvider;
export { default as useActionVisibilityHooks } from './hook';
export { useActionVisibility, ActionVisibilityContext } from './context';
