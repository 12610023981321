import React from 'react';
import styled from 'styled-components';
import UnstyledBodyRow from './BodyRow';
import BodyCell from './BodyCell';

const BodyRow = styled(UnstyledBodyRow)``;

const Row = ({ className, columns, action, renderPropBefore, renderPropAfter }) => {
  const cells = columns.map(column => (
    <BodyCell
      key={column.id}
      action={action}
      column={column}
      renderPropBefore={renderPropBefore}
      renderPropAfter={renderPropAfter}
    />
  ));

  return <BodyRow className={className}>{cells}</BodyRow>;
};

export default Row;
