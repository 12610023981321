/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MuiFormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

interface Props {
  className?: string;
  label?: string;
  LabelComponent?: typeof InputLabel;
  allowEmpty?: boolean;
  emptyLabel?: string;
  options: any;
  id?: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  value?: any;
  onChange?: (e?: any) => void;
  onClose?: (e?: any) => void;
  renderItem?: typeof defaultRenderItem;
  renderValue?: (value: any) => string;
  multiple?: boolean;
  disableUnderline?: boolean;
  'data-testid'?: string;
  MenuProps?: any;
}

const FormControl = styled(MuiFormControl)`
  .MuiSelect-root {
    margin-top: 16px;
  }
`;

const defaultRenderItem = (item: { value: string; label: string }) => (
  <MenuItem
    key={item.value}
    value={item.value}
  >
    {item.label}
  </MenuItem>
);

const Select = ({
  label,
  LabelComponent = InputLabel,
  allowEmpty,
  emptyLabel = 'None',
  options,
  id,
  name,
  disabled = false,
  error,
  helperText,
  fullWidth,
  multiple,
  renderValue,
  disableUnderline,
  renderItem = defaultRenderItem,
  'data-testid': dataTestId,
  value,
  ...props
}: Props) => {
  const labelId = `${name}-label`;

  const items = options.map(renderItem);

  return (
    <FormControl
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {label && <LabelComponent id={labelId}>{label}</LabelComponent>}
      <MuiSelect
        labelId={labelId}
        id={id}
        name={name}
        error={error}
        multiple={multiple}
        inputProps={{ 'data-testid': dataTestId ?? label }}
        renderValue={renderValue}
        disableUnderline={disableUnderline}
        value={value ?? ''}
        {...props}
      >
        {allowEmpty && <MenuItem value="">{emptyLabel}</MenuItem>}
        {items}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
