import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { useSchedule } from '../../hooks/useSchedule';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { ScheduleEvent } from '../../../../types/cwt/schedule';
import { useEffect, useMemo, useRef } from 'react';
import { generateMaximoLink } from '../../utils';
import { useWODetailsModal } from '../../hooks/useWODetailsModal';
interface Props {
  setDisplayCalendar: (displayCalendar: boolean) => void;
}
const ScheduleCalendar = ({ setDisplayCalendar }: Props) => {
  const { events, onDragDrop, filters, fetchSchedule } = useSchedule();
  const { workorders, setSelectedWorkorderId } = useWorkOrders();
  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { handleOpen } = useWODetailsModal();

  const handleEventClick = (info: any) => {
    if (clickTimeout.current !== null) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      // Open a new tab and navigate to a new link
      window.open(generateMaximoLink(info.event.extendedProps.workorderId, 'pluswotr'), '_blank');
    } else {
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
        setDisplayCalendar(false);
        setSelectedWorkorderId(info.event.extendedProps.workorderId);
        handleOpen('schedule');
      }, 250); // wait for 250ms before treating as a single click
    }
  };
  useEffect(() => {
    fetchSchedule();
  }, [workorders, filters]);
  const filteredEvents = useMemo(() => {
    const scheduleWithWorkorder = events.map((event: ScheduleEvent) => {
      const workorder = workorders.get(event.workorderId);
      return {
        ...event,
        wonum: workorder?.wonum,
        unit: workorder?.unit,
        wopriority: workorder?.wopriority,
        worktype: workorder?.worktype,
      };
    });

    const filteredSchedule = scheduleWithWorkorder.filter((event: any) => {
      return (
        (!filters.wonum.length || filters.wonum.includes(event.wonum)) &&
        (!filters.unit.length || filters.unit.includes(event.unit)) &&
        (!filters.wopriority.length || filters.wopriority.includes(event.wopriority?.toString())) &&
        (!filters.worktype.length || filters.worktype.includes(event.worktype)) &&
        (!filters.crewworkgroup.length || filters.crewworkgroup.includes(event.crewId)) &&
        (!filters.location.length || filters.location.includes(event.location))
      );
    });
    return events.filter(event => filteredSchedule.map(schedule => schedule.id).includes(event.id));
  }, [events, filters, workorders]);
  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
      initialView="dayGridMonth"
      height="auto"
      events={filteredEvents}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
      }}
      editable={true}
      eventDrop={onDragDrop}
      eventResize={onDragDrop}
      eventClick={handleEventClick}
    />
  );
};

export default ScheduleCalendar;
