import Page from '../../../components/Page';

import TPlusOneHeader from './components/TPlusOneHeader';
const TPlusOnePage = () => {
  return (
    <Page title="T+1">
      <TPlusOneHeader />
    </Page>
  );
};
export default TPlusOnePage;
