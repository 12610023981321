import React from 'react';
import NavButton from './NavButton';

const SubNavItem = ({ className, to, iconComponent, label, isSelected }) => (
  <NavButton
    className={className}
    to={to}
    iconComponent={iconComponent}
    label={label}
    subMenu
    exact
    isSelected={isSelected}
  />
);

export default SubNavItem;
