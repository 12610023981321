"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnitWeight = exports.getUnit = exports.buildUnitMap = exports.buildUnits = void 0;
const R = __importStar(require("ramda"));
const buildUnits = (units) => R.map(R.prop('id'), units);
exports.buildUnits = buildUnits;
const buildUnitMap = (units) => R.reduce((acc, unit) => (Object.assign(Object.assign({}, acc), { [unit.id]: unit })), {}, units);
exports.buildUnitMap = buildUnitMap;
const getUnit = (unitId, scorecard) => scorecard.unitMap[unitId];
exports.getUnit = getUnit;
const getAllUnits = (scorecard) => R.map((unitId) => scorecard.unitMap[unitId], scorecard.units);
const getUnitWeight = (unitId, scorecard) => {
    const thisUnit = (0, exports.getUnit)(unitId, scorecard);
    const allUnits = getAllUnits(scorecard);
    const mcrUnit = R.find(R.propEq('mcr', true), allUnits);
    if (R.isNil(mcrUnit)) {
        return thisUnit.weight;
    }
    const unitCount = R.length(allUnits);
    const redistributedWeight = mcrUnit.weight / (unitCount - 1); // this will throw if there's only a single unit - not sure if that's even possible
    const mcrUnitWeights = R.reduce((acc, unit) => (Object.assign(Object.assign({}, acc), { [unit.id]: unit.mcr ? 0 : unit.weight + redistributedWeight })), {}, allUnits);
    return mcrUnitWeights[unitId];
};
exports.getUnitWeight = getUnitWeight;
