import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuItem from './MenuItem';

const NavButton = ({ className, to, onClick, iconComponent, label, subMenu, isSelected, exact = true }) => (
  <MenuItem
    className={className}
    component={NavLink}
    exact={exact}
    to={to}
    onClick={onClick}
    subMenu={subMenu}
    iconComponent={iconComponent}
    label={label}
    isSelected={isSelected}
  />
);

export default NavButton;
