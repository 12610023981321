// Github Link to Code Based on: https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import BodyRow from './BodyRow';
import theme from '../../theme';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    backgroundColor: theme.colours.greys.lightGrey6,
  }),
});

const DraggableComponent =
  (draggableId, index, isDragDisabled = false) =>
  props =>
    (
      <Draggable
        isDragDisabled={!!isDragDisabled}
        draggableId={draggableId}
        index={index}
      >
        {(provided, snapshot) => (
          <BodyRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            {...props}
          >
            {props.children}
          </BodyRow>
        )}
      </Draggable>
    );

export default DraggableComponent;
