import { Control, FieldErrors, SubmitHandler, useForm, UseFormSetValue, FormState } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../../../api';
import { InterestedParties, RiskCategories, RiskOwnerFormInputs } from '../../../../types/risk-management';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import { useHistory, useLocation } from 'react-router-dom';
import * as uiActions from '../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { OutagePlan } from '../../../../types/outage-plans';
import { BusinessObjective } from '../../../../types/business-objectives';
import { MSMProgram } from '../../../../types/msm-programs';
import { MyElement } from '../../../../types/my';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';
import { getUnitsForFacility, Unit } from '../../../../utils/units';
import { AutocompleteOption } from '../../../../components/AutocompleteInput';
import { RadioOption } from '../../../../components/CustomRadioGroup';
import { getUserName } from '../../../../auth/utils';
import { DraftSavingStatus } from '../SaveDraftButton';
import { SubmitTextStatus } from '../SubmitStatusText';

const ENGINEERING_PERFORMANCE_CATEGORIES = {
  assessingAndDocs: 'Assessing and Docs',
  baPlantEngineering: 'BA Plant Engineering',
  balanceOfPlant: 'Balance of Plant',
  bbPlantEngineering: 'BB Plant Engineering',
  boilersAndReactorMaintenance: 'Boilers and Reactor Maintenance',
  centralMaintenance: 'Central Maintenance',
  civilSecurityDesign: 'Civil / Security Design',
  componentDesignElectrical: 'Component Design Electrical/I&C',
  componentDesignMechanical: 'Component Design Mechanical',
  computerEngineering: 'Computer Engineering',
  controlDesign: 'Control Design',
  correctiveAndDefective: 'Corrective and Defective',
  cranesAndSgs: 'Cranes and SGs',
  dccReplacement: 'DCC Replacement',
  designPrograms: 'Design Programs',
  designServices: 'Design Services',
  deterministicSafetyMoment: 'Deterministic Safety Support',
  electricalDesign: 'Electrical Design',
  electricalComponents: 'Electrical/ I&C Components',
  electricalSystems: 'Electrical/ I&C Systems',
  erPrograms: 'ER Programs',
  fitnessForServiceAssessment: 'Fitness for Service Assessment',
  fuelHandling: 'Fuel Handling',
  fuelHandlingAndToolingSystems: 'Fuel Handling and Tooling Systems',
  fuelHandlingDesign: 'Fuel Handling Design',
  fuelHandlingEngineering: 'Fuel Handling Engineering',
  fuelManagementAndReactorPhysics: 'Fuel Management and Reactor Physics',
  governanceAndOversight: 'Governance and Oversight',
  electricalDesignProjects: 'Instrumentation, Computer and Electrical Design Projects',
  lifeEngineering: 'Life-X Engineering',
  maintenanceContracts: 'Maintenance Contracts',
  majorComponentsEngineeringPrograms: 'Major Components Engineering Programs',
  mechanicalAndCivil: 'Mechanical and Civil',
  mechanicalComponents: 'Mechanical Components',
  mechanicalDesign: 'Mechanical Design',
  nuclearSafety: 'Nuclear Safety Analysis & Support',
  nuclearSystems: 'Nuclear Systems (NSSS)',
  outagesEngineering: 'Outages Engineering',
  pressureBoundaryPiping: 'Pressure Boundary Piping Engineering Programs',
  probabilisticSafetySupport: 'Probabilistic Safety Support',
  reactorDesign: 'Reactor Design',
  reactorFueling: 'Reactor Fueling',
  reactorInspectionTooling: 'Reactor Inspection Tooling',
  reactorSafetyPlantSupport: 'Reactor Safety Plant Support',
  resourcing: 'Resourcing',
  siteSupportEngineering: 'Site Support Engineering',
  switchyard: 'Switchyard',
  turbineServices: 'Turbine Services',
  reactorSafetyRisk: 'Reactor Safety Risk & Integration',
  researchAndDevelopment: 'R&D and Technical Development',
};

export const RISK_CATEGORIES: Record<keyof RiskCategories, string> = {
  mcrRelatedRisk: 'MCR Related Risk',
  environmentalSafety: 'Environmental Safety',
  industrialSafety: 'Industrial Safety',
  productionCommercialFinancial: 'Production / Commercial / Financial',
  radiologicalSafety: 'Radiological Safety',
  reactorSafety: 'Reactor Safety',
  regulatoryCompliance: 'Regulatory Compliance',
  reputationRisk: 'Reputation Risk',
  equipmentReliabilityGeneration: 'Equipment Reliability',
};

export const RISK_CATEGORIES_DEFAULT: RiskCategories = {
  mcrRelatedRisk: false,
  environmentalSafety: false,
  industrialSafety: false,
  productionCommercialFinancial: false,
  radiologicalSafety: false,
  reactorSafety: false,
  regulatoryCompliance: false,
  reputationRisk: false,
  equipmentReliabilityGeneration: false,
};

export const INTERESTED_PARTIES: Record<keyof InterestedParties, string> = {
  cnsc: 'CNSC',
  environmentRegulators: 'Environment Regulators',
  governmentOther: 'Government - Other',
  hydroOne: 'Hydro One',
  ieso: 'IESO',
  indigenousGroup: 'Indigenous Group',
  industryOther: 'Industry - Other',
  media: 'Media',
  opg: 'OPG',
  other: 'Other',
  partners: 'Partners',
  publicParty: 'Public',
};

export const INTERESTED_PARTIES_DEFAULT: InterestedParties = {
  cnsc: false,
  environmentRegulators: false,
  governmentOther: false,
  hydroOne: false,
  ieso: false,
  indigenousGroup: false,
  industryOther: false,
  media: false,
  opg: false,
  other: false,
  partners: false,
  publicParty: false,
};

export const RADIO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Not Applicable', value: 'notApplicable' },
];
export const INITIAL_RISK_OWNER_FORM_VALUES: RiskOwnerFormInputs = {
  riskTitle: '',
  elementId: 0,
  description: '',
  opportunityOrThreat: '',
  engineeringEquipmentPerformance: '',
  endOfLife: 'no',
  bridgingStrategy: 'no',
  bridgingStrategyDescription: '',
  spv: 'no',
  spvEliminationOrMitigation: '',
  spvDescription: '',
  criticalStrategicSpares: 'no',
  criticalDescription: '',
  obsolescenceIssue: 'no',
  obsolescenceDescription: '',
  changesSinceLastQ: '',
  currentStatusRating: '',
  currentStatusComment: '',
  contingencyPlanDescription: null,
  contingencyPlanningDate: null,
  contingencyPlanningStatus: '',
  businessObjectives: '',
  msmProgram: [],
  plannedOutageReference: [],
  climateChange: 'no',
  climateDescription: '',
  facility: '',
  overlifePotentialCommercialImpact: 0,
  overlifeImpactDescription: '',
  units: [],
  riskCategories: RISK_CATEGORIES_DEFAULT,
  riskImpactMcr: '',
  reputationDescription: '',
  responseType: '',
  businessPlanDescription: '',
  estimationDescription: '',
  otherInfoDescription: '',
  interestedParties: INTERESTED_PARTIES_DEFAULT,
  requestedRiskApprover: '',
};

type ApproverDiscardState = {
  discarding: boolean;
  source?: string;
};

export type RiskOwnerForm = {
  riskStatus: string;
  control: Control<RiskOwnerFormInputs>;
  handleSubmit: () => Promise<void>;
  setSubmitting: (submitting: boolean) => void;
  submitting: boolean;
  submitTextStatus: SubmitTextStatus;
  saveDraft: (source: string) => Promise<void>;
  draftSavingStatus: DraftSavingStatus;
  errors: FieldErrors<RiskOwnerFormInputs>;
  dirtyFields: FormState<RiskOwnerFormInputs>['dirtyFields'];
  watchSpv: string;
  watchElementId: number;
  watchRiskApprover: string;
  watchOpportunityOrThreat: string;
  radioOptions: RadioOption[];
  riskCategories: Record<keyof RiskCategories, string>;
  interestedParties: Record<keyof InterestedParties, string>;
  watchContingencyPlanningStatus: RiskOwnerFormInputs['contingencyPlanningStatus'];
  watchClimateChange: RiskOwnerFormInputs['climateChange'];
  watchFacility: RiskOwnerFormInputs['facility'];
  watchRiskCategory: RiskCategories;
  watchPotentialOverlife: string;
  watchBridgingStrategy: string;
  watchCritical: string;
  watchObsolescence: string;
  watchEnv: boolean;
  watchHealthScore: number | undefined;
  watchHealthScoreColor: string | undefined;
  unitsOptions: AutocompleteOption<Unit>[];
  isReadOnly: boolean;
  isApproverPage: boolean;
  approverEditing: boolean;
  setApproverEditing: (approverEditing: boolean) => void;
  approverDiscarding: ApproverDiscardState;
  setApproverDiscarding: (approverDiscarding: ApproverDiscardState) => void;
  approverSavingEdits: boolean;
  setApproverSavingEdits: (approverSavingEdits: boolean) => void;
  rsaArcherRskRecordReference: string | null;
  riskOwner: string;
  setValue: UseFormSetValue<RiskOwnerFormInputs>;
  engineeringPerformanceOptions: RadioOption[];
  reset: (values?: RiskOwnerFormInputs, options?: { keepDirty: boolean }) => void;
  getUserNameById: (userId?: string) => User | undefined;
};

export const getOutagePlansOptions = (outagePlans: OutagePlan[]) =>
  outagePlans
    .filter((plan: OutagePlan) => plan.status === 'Planned' && !plan.is_deleted)
    .map((plan: OutagePlan) => ({ label: plan.outage_code, value: plan.outage_code }));

export const getBusinessObjectivesOptions = (businessObjectives: BusinessObjective[]) =>
  businessObjectives
    .filter(obj => obj.status === 'Active' && !obj.is_deleted)
    .map((obj: BusinessObjective) => ({ label: obj.title, value: obj.title }));

export const getMSMPrograms = (msmPrograms: MSMProgram[]) =>
  msmPrograms
    .filter(obj => obj.status === 'Active' && !obj.is_deleted)
    .map((obj: MSMProgram) => ({ label: obj.msm_program, value: obj.msm_program }));

export const getUnitsOptions = (facility: RiskOwnerFormInputs['facility']): RiskOwnerForm['unitsOptions'] => {
  const applicableUnits = getUnitsForFacility(facility || 'All');
  return applicableUnits.map(unit => ({ value: unit, label: unit }));
};

export const elementsOptions = (elements: MyElement[]) =>
  elements.map(element => ({
    label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
    value: element.elementId,
  }));

export const useRiskOwnerForm = (riskId: number): RiskOwnerForm => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { users } = useUsers();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [draftSavingStatus, setDraftSavingStatus] = useState<DraftSavingStatus>('default');
  const [riskStatus, setRiskStatus] = useState<string>('');
  const [rsaArcherRskRecordReference, setRsaArcherRskRecordReference] = useState<string | null>(null);
  const [riskOwner, setRiskOwner] = useState<string>(getUserName());
  const [submitTextStatus, setSubmitTextStatus] = useState<SubmitTextStatus>('default');
  const [approverEditing, setApproverEditing] = useState<boolean>(false);
  const [approverDiscarding, setApproverDiscarding] = useState<ApproverDiscardState>({ discarding: false });
  const [approverSavingEdits, setApproverSavingEdits] = useState(false);

  const initialValues = useMemo<RiskOwnerFormInputs>(() => INITIAL_RISK_OWNER_FORM_VALUES, [riskId]);

  useEffect(() => {
    if (pathname.includes('/edit') || pathname.includes('/archive') || pathname.includes('/review')) {
      void getRiskOwnerForm();
    }
    return () => {
      reset(initialValues);
      setRsaArcherRskRecordReference(null);
      setRiskOwner(getUserName());
      setRiskStatus('');
      setSubmitting(false);
      setDraftSavingStatus('default');
      setApproverEditing(false);
      setApproverDiscarding({ discarding: false });
    };
  }, [pathname, riskId]);

  const isReadOnly = useMemo(
    () => submitting || pathname.includes('/archive') || (pathname.includes('/review') && !approverEditing),
    [pathname, approverEditing, submitting]
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields, defaultValues },
    watch,
    setValue,
    reset,
    resetField,
  } = useForm<RiskOwnerFormInputs>({
    defaultValues: initialValues,
  });

  const getUserNameById = useCallback(
    (userId?: string) => userId && users.find((user: User) => user.id === userId),
    [users]
  );

  const getRiskOwnerForm = useCallback(async () => {
    if (!riskId) return null;
    const { risk } = await api.getRiskOwnerForm(riskId);
    const generalRisk = await api.getRiskGeneralForm(riskId);
    setRiskStatus(generalRisk.status);
    reset({ ...risk, requestedRiskApprover: null }, { keepDirty: false });
    setRsaArcherRskRecordReference(risk.rsaArcherRskRecordReference || null);
    const riskOwner = getUserNameById(risk.riskOwner);
    !!riskOwner && setRiskOwner(riskOwner.name);
    return;
  }, [riskId]);

  const resetSubmitState = () => {
    setSubmitTextStatus('default');
    setSubmitting(false);
  };

  // TODO: Part of temp fix for the render "lag", remove when the issue is fixed
  const onInvalid = () => {
    setSubmitting(false);
  };

  const onSubmit: SubmitHandler<RiskOwnerFormInputs> = useCallback(
    async data => {
      setSubmitTextStatus('submitting');
      let targetPath = '/app/business-equipment-risks';

      try {
        if (pathname.includes('/review')) {
          await api.approverEditRiskOwnerForm(data, riskId);
          dispatch(uiActions.genericMessage('Risk Form Updated'));
          targetPath = '/app/business-equipment-risks/risk-review';
        } else {
          if (pathname.includes('/edit')) {
            await api.updateRiskOwnerForm(data, riskId);
            dispatch(uiActions.genericMessage('Risk Form Updated'));
          } else {
            await api.submitRiskOwnerForm(data, riskId);
            dispatch(uiActions.genericMessage('Risk Form Submitted'));
          }
        }
        setSubmitTextStatus('success');
        setTimeout(() => {
          resetSubmitState();
          push(targetPath);
        }, 3000);
      } catch (error) {
        console.error('error', error);
        dispatch(uiActions.error('', 'Error: Unable to submit or update form'));
        pathname.includes('/review') && setApproverEditing(true);
        resetSubmitState();
      }
    },
    [riskId]
  );

  const onSaveDraft = useCallback(
    async (source: string) => {
      setDraftSavingStatus('saving');

      try {
        const data = getValues();
        if (pathname.includes('/edit')) {
          await api.updateDraftRiskOwnerForm({ ...data, healthScore: undefined, healthScoreColor: undefined }, riskId);
          setDraftSavingStatus('saved');
          source === 'back' && dispatch(uiActions.genericMessage('Draft Updated'));
        } else {
          await api.saveDraftRiskOwnerForm({ ...data, healthScore: undefined, healthScoreColor: undefined }, riskId);
          setDraftSavingStatus('saved');
          source === 'back' && dispatch(uiActions.genericMessage('Draft Saved'));
          source === 'button' && push(`/app/business-equipment-risks/${riskId}/edit`);
        }
        setTimeout(() => {
          setDraftSavingStatus('default');
        }, 3000);
        reset(getValues(), { keepDirty: false });
      } catch (error) {
        console.error('error', error);
        setDraftSavingStatus('error');
        source === 'back' && dispatch(uiActions.genericMessage('Error: Unable to save or update draft'));
        setTimeout(() => setDraftSavingStatus('default'), 3000);
      }
    },
    [riskId, pathname]
  );

  const setUnitsOptions: RiskOwnerForm['unitsOptions'] = useMemo(() => {
    const unitOptions = getUnitsOptions(watch('facility'));
    const values = unitOptions.map(option => option.value);
    if (!getValues('units')?.every(v => values.includes(v))) {
      // TODO-RS: No side effects in useMemo 🙅‍♀️
      resetField('units');
    }
    return unitOptions;
  }, [watch('facility')]);

  const watchOpportunityOrThreat = watch('opportunityOrThreat');

  const watchContingencyPlanningStatus = watch('contingencyPlanningStatus');

  const engineeringPerformanceOptions = useMemo(() => {
    return Object.entries(ENGINEERING_PERFORMANCE_CATEGORIES).map(([key, value]) => {
      return { label: value, value: key };
    });
  }, []);

  // Clear dependent fields when their parent field is either changed or reset
  useEffect(() => {
    if (!watchContingencyPlanningStatus || watchContingencyPlanningStatus === 'Not Applicable') {
      // Clear dependent field values when they are hidden
      // Don't use resetField because we want to retain the original prefilled default values
      setValue('contingencyPlanningDate', INITIAL_RISK_OWNER_FORM_VALUES['contingencyPlanningDate']);
      setValue('contingencyPlanDescription', INITIAL_RISK_OWNER_FORM_VALUES['contingencyPlanDescription']);
    }
  }, [watchContingencyPlanningStatus, defaultValues]);

  return {
    riskStatus,
    control,
    handleSubmit: handleSubmit(onSubmit, onInvalid),
    setSubmitting,
    submitting,
    submitTextStatus,
    saveDraft: onSaveDraft,
    draftSavingStatus,
    errors,
    dirtyFields,
    watchSpv: watch('spv'),
    watchElementId: watch('elementId'),
    watchRiskApprover: watch('requestedRiskApprover'),
    watchOpportunityOrThreat,
    radioOptions: RADIO_OPTIONS,
    riskCategories: RISK_CATEGORIES,
    interestedParties: INTERESTED_PARTIES,
    watchContingencyPlanningStatus,
    watchClimateChange: watch('climateChange'),
    watchFacility: watch('facility'),
    watchPotentialOverlife: watch('endOfLife'),
    watchBridgingStrategy: watch('bridgingStrategy'),
    watchCritical: watch('criticalStrategicSpares'),
    watchObsolescence: watch('obsolescenceIssue'),
    watchEnv: watch('riskCategories.environmentalSafety'),
    watchHealthScore: watch('healthScore'),
    watchHealthScoreColor: watch('healthScoreColor'),
    unitsOptions: setUnitsOptions,
    watchRiskCategory: watch('riskCategories'),
    isReadOnly,
    isApproverPage: pathname.includes('/review'),
    approverEditing,
    setApproverEditing,
    approverDiscarding,
    setApproverDiscarding,
    approverSavingEdits,
    setApproverSavingEdits,
    rsaArcherRskRecordReference,
    riskOwner,
    setValue,
    engineeringPerformanceOptions,
    reset,
    getUserNameById,
  };
};
