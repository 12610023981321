import React from 'react';
import styled, { css } from 'styled-components';
import UnstyledListItem from '@material-ui/core/ListItem';
import UnstyledListItemIcon from '@material-ui/core/ListItemIcon';
import UnstyledListItemText from '@material-ui/core/ListItemText';
import isFeatureEnabled from '../../utils/feature-flags';

const ListItemIcon = styled(UnstyledListItemIcon)``;
const ListItemText = styled(UnstyledListItemText)``;

const ListItem = styled(UnstyledListItem)<{ $subMenu?: boolean; $isSelected?: boolean }>`
  background-color: ${({ $isSelected, theme }) => ($isSelected ? theme.colours.purples.purple2 : 'transparent')};

  &.active {
    background-color: ${({ theme }) => theme.colours.purples.purple2};
  }

  ${isFeatureEnabled('fixMenuHighlight')
    ? css`
        &:hover {
          background-color: ${({ theme }) => theme.colours.purples.purple3};
        }
      `
    : ''}

  padding-left: ${({ $subMenu }) => ($subMenu ? 111 : 54)}px;

  ${ListItemIcon} {
    min-width: 0px;
    margin-right: 18px;
  }

  ${ListItemText} {
    span {
      font: ${({ theme }) => theme.fonts.medium14};
    }
  }
`;

export interface MenuItemProps extends Omit<React.ComponentProps<typeof UnstyledListItem>, 'button'> {
  className?: string;
  iconComponent: React.ElementType;
  isSelected?: boolean;
  label: string;
  subMenu?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  className,
  iconComponent: IconComponent,
  isSelected,
  label,
  subMenu,
  ...props
}) => (
  <ListItem
    className={className}
    button
    disableGutters
    $isSelected={isSelected}
    $subMenu={subMenu}
    {...props}
  >
    {IconComponent && (
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
    )}
    <ListItemText>{label}</ListItemText>
  </ListItem>
);

export default MenuItem;
