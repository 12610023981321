import { useCallback, type SetStateAction, type Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../api';
import * as actionActions from '../../state/actions/actions';
import * as uiActions from '../../state/ui/actions';
import { type MyElement } from '../../types/my';
import type {
  ReorderState,
  RequireInL,
  RequireInR,
  RequireInT,
  StaleDataState,
  UpdateRequestState,
  VisibilityState,
} from './types';

type UseActionVisibilityEventHandlersParams<T, L, R, A> = {
  visibilityUpdating: VisibilityState<T>;
  setVisibilityUpdating: Dispatch<SetStateAction<VisibilityState<T>>>;
  actions: T[];
  staleDataState: StaleDataState<T[]>;
  elementId?: MyElement['elementId'] | null;
  reorderRequest: ReorderState<T, R & RequireInR>;
  setReorderRequest: Dispatch<SetStateAction<ReorderState<T, R & RequireInR>>>;
  ongoingActions: T[];
  completedActions: T[];
  isLoading: boolean;
  updateUiState: (params: Partial<L>) => void;
  riskManagementId?: number | null;
  setStaleData: Dispatch<SetStateAction<StaleDataState<T[]>>>;
  updateRequestData: UpdateRequestState<T[]>;
  setUpdateFromRequest: Dispatch<SetStateAction<UpdateRequestState<T[]>>>;
  actionState: A;
  isShowingHiddenActions: boolean;
};
export default <T, L, R, A>({
  visibilityUpdating,
  setVisibilityUpdating,
  actions,
  staleDataState,
  elementId,
  reorderRequest,
  setReorderRequest,
  ongoingActions,
  completedActions,
  isLoading,
  updateRequestData,
  updateUiState,
  riskManagementId,
  setStaleData,
  setUpdateFromRequest,
  actionState,
  isShowingHiddenActions,
}: UseActionVisibilityEventHandlersParams<T, L, R, A>) => {
  const dispatch = useDispatch();

  const onToggleShipChipRiskVisibility = useCallback(
    async (payload: any) => {
      // no need to check for is loading
      const { id: actionId, isHidden } = payload;
      const closeRequest = (isError: boolean) => {
        setVisibilityUpdating(prev => ({
          ...prev,
          [actionId]: {
            actionPromise: null,
            payload: null,
            isLoading: false,
            isError,
          },
        }));
        setUpdateFromRequest((prev: any) => ({
          ...prev,
          handledData: { ongoing: false, complete: false },
          retrievePromise: null,
          isLoading: false,
          isError: false,
        }));
      };
      const stateContext = visibilityUpdating[actionId];

      if (stateContext?.isLoading || stateContext?.actionPromise) {
        // avoid duplicate actions
        return stateContext.actionPromise;
      }
      if (!riskManagementId || !actionState) {
        // Terminate any in-flight actions around this visibility if these suddenly change to falsy
        if (stateContext && stateContext.actionPromise) {
          closeRequest(false);
        }
        return Promise.resolve();
      }

      const staleActions: T[] = actions;
      const staleIndex = staleActions.findIndex((i: T) => {
        const item = i as T & RequireInT;
        return actionId === item.id;
      });
      if (staleIndex !== -1 && staleIndex in staleActions) {
        staleActions[staleIndex] = { ...staleActions[staleIndex], isHidden: !isHidden };
        setStaleData(prev => ({
          ...prev,
          actions:
            actionState === 'ongoing'
              ? { ongoing: staleActions, complete: prev.actions.complete || completedActions }
              : { ongoing: prev.actions.ongoing || ongoingActions, complete: staleActions },
        }));
      }
      try {
        // invert the current value
        const actionPromise = api.patchRiskActionVisibility({ riskManagementId, actionId, isHidden: !isHidden });
        setVisibilityUpdating(prev => ({
          ...prev,
          [actionId]: {
            actionPromise,
            payload,
            isLoading: true,
            isError: false,
          },
        }));
        await actionPromise;
      } catch (error: any) {
        closeRequest(true);
        console.error('There was an error trying to change this actions visibility', error);
        dispatch(uiActions.error(error, 'There was an error trying to change this actions visibility'));
        return;
      }

      closeRequest(false);
    },
    [
      dispatch,
      actionState,
      riskManagementId,
      visibilityUpdating,
      setVisibilityUpdating,
      staleDataState,
      setStaleData,
      setUpdateFromRequest,
      actions,
      isLoading,
    ]
  );

  const updatedActionOrder = useCallback(
    (update: R & RequireInR) => {
      const { actionId } = update;
      const toIndex = update.toIndex + 1;
      return api.updateActionPriority(`${actionId}`, toIndex).catch((error: Error) => {
        dispatch(
          actionActions.reorderActionsFailure(error, {
            actionId,
            toIndex,
            elementId: elementId,
            riskManagementId,
          })
        );
      });
    },
    [dispatch, elementId, riskManagementId]
  );
  const onReorder = useCallback(
    async (payload: any) => {
      // block multiple re-ordering to mimic existing functionality
      if (isLoading) {
        return;
      }
      if (reorderRequest?.actionPromise) {
        return;
      }
      const closeRequest = (isError: boolean) => {
        setReorderRequest({
          actionPromise: null,
          isLoading: false,
          isError,
          payload: null,
        });
        setUpdateFromRequest((prev: any) => ({
          ...prev,
          handledData: { ongoing: false, complete: false },
          retrievePromise: null,
          isLoading: false,
          isError: false,
        }));
      };
      const { toIndex, fromIndex } = payload;
      let reorderAbleData =
        actionState === 'ongoing' ? (staleDataState.actions.ongoing as T[]) : (staleDataState.actions.complete as T[]);
      if (!reorderAbleData) {
        reorderAbleData = (actionState === 'ongoing' ? ongoingActions : completedActions) as T[];
      }
      const reorderAble = (reorderAbleData || []).reduce<T[]>((acc, r, i, arr) => {
        const row = r as T & RequireInT;
        if (i === toIndex) {
          // moved row && same index add both
          const matched = (arr as (T & RequireInT)[]).find(({ id }) => id === payload.actionId);
          if (!matched) {
            throw new Error('No Reordering Matched result found');
          }
          if (fromIndex > toIndex) {
            acc.push({ ...matched, priority: row.priority });
            acc.push({ ...row, priority: matched.priority });
          } else {
            acc.push({ ...row, priority: matched.priority });
            acc.push({ ...matched, priority: row.priority });
          }
          return acc;
        }
        if (row.id === payload.actionId) {
          // ignore the moved row
          return acc;
        }
        acc.push(row);
        return acc;
      }, []);
      setStaleData(prev => ({
        ...prev,
        actions:
          actionState === 'ongoing'
            ? { ongoing: reorderAble, complete: prev.actions.complete || completedActions }
            : { ongoing: prev.actions.ongoing || ongoingActions, complete: reorderAble },
      }));
      try {
        const actionPromise = updatedActionOrder(payload);
        setReorderRequest({
          actionPromise,
          isLoading: true,
          isError: false,
          payload,
        });
        await actionPromise;
      } catch (error: any) {
        closeRequest(true);
        console.error('There was an error trying to the display order of actions\n', error);
        dispatch(uiActions.error(error, 'There was an error trying to change this actions visibility'));
        return;
      }
      closeRequest(false);
    },
    [
      dispatch,
      setStaleData,
      staleDataState,
      actionState,
      elementId,
      riskManagementId,
      reorderRequest,
      setReorderRequest,
      updatedActionOrder,
      ongoingActions,
      completedActions,
      isLoading,
    ]
  );

  const onReloadClick = useCallback(() => {
    if (!updateRequestData.isError) {
      return;
    }
    setStaleData((prev: any) => ({
      ...prev,
      actions: { ongoing: null, complete: null },
    }));
    setUpdateFromRequest((prev: any) => ({
      ...prev,
      handledData: { ongoing: false, complete: false },
      retrievePromise: null,
      isLoading: false,
      isError: false,
    }));
  }, [updateRequestData, setUpdateFromRequest]);
  const onToggleClick = useCallback(() => {
    updateUiState({ isShowingHiddenActions: !isShowingHiddenActions } as Partial<L & RequireInL<A>>);
  }, [updateUiState, riskManagementId, isShowingHiddenActions]);
  const onClickNewMessageClose = useCallback(() => {
    const ongoingMapper =
      isShowingHiddenActions || actionState === 'ongoing' ? (row: T) => ({ ...row, isNew: false }) : (row: T) => row;
    const completeMapper =
      isShowingHiddenActions || actionState !== 'ongoing' ? (row: T) => ({ ...row, isNew: false }) : (row: T) => row;
    setStaleData((prev: any) => ({
      ...prev,
      actions: {
        ongoing: (prev.actions.ongoing || []).map(ongoingMapper),
        complete: (prev.actions.complete || []).map(completeMapper),
      },
    }));
    return;
  }, [setStaleData, setUpdateFromRequest, actionState, isShowingHiddenActions]);

  return {
    updatedActionOrder,
    onToggleShipChipRiskVisibility,
    onReorder,
    onReloadClick,
    onToggleClick,
    onClickNewMessageClose,
  };
};
