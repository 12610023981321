import { Remote } from '../types';
import actions from './actions';
import crews from './crews';
import schedule from './schedule';
import turnover from './turnover';
import users from './users';
import workorders from './workorders';
import tasks from './tasks';
import overview from './overview';
import workRequests from './workrequests';
import changeLog from './change-tracking';
import bookmarks from './bookmarks';

const cwt = (originalBasePath: (path: string) => string, remote: Remote) => {
  const cwtPath = (basePath: string) => (path: string) => `${originalBasePath(basePath)}${path}`;

  return {
    cwt: {
      ...actions(cwtPath('/actions'), remote),
      ...crews(cwtPath('/crews'), remote),
      ...changeLog(cwtPath('/change-tracking'), remote),
      ...schedule(cwtPath('/schedule'), remote),
      ...turnover(cwtPath('/turnover'), remote),
      ...users(cwtPath('/users'), remote),
      ...workorders(cwtPath('/workorders'), remote),
      ...tasks(cwtPath('/tasks'), remote),
      ...overview(cwtPath('/overview'), remote),
      ...workRequests(cwtPath('/workrequests'), remote),
      ...bookmarks(cwtPath('/bookmarks'), remote),
    },
  };
};

export default cwt;
