import ReactDataGrid from '@inovua/reactdatagrid-community';
import UnstyledSelect from '../../../../../components/Select';
import { useTPlusOne } from '../../../hooks/useTPlusOne';

import { Icon, IconButton, TextField } from '@material-ui/core';
import styled from 'styled-components';

import { useWorkorderDelayReasons } from './hook/useWorkorderDelayReasons';
import { DelayReasons } from '../../../../../types/cwt/workorder';

const gridStyle = { minWidth: '100%', padding: '1px' };
const Select = styled(UnstyledSelect)`
  .MuiSelect-root {
    margin-top: 0px;
  }
  .faded {
    color: #aaa;
  }
`;
const StyledReactDataGrid = styled(ReactDataGrid)`
  .inovua-react-scroll-container__wrapper {
    overflow: none;
  }
`;
const StyledDeleteIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colours.teals.teal1};
  cursor: pointer;
`;

export const DelayReasonsTable = ({ style }: any) => {
  const { delayReasons } = useTPlusOne();
  const { workorderDelayReasons, onEditComplete, onEditValueChange, handleDeleteWorkorderDelayReason } =
    useWorkorderDelayReasons();

  const renderDelayReason = ({ value }: any, { cellProps }: any) => {
    // we want the filter to include disabled delay reasons if they were selected
    // this is to give the user to option to see what was previously selected instead of seeing nothing
    const delayReasonOptions = delayReasons
      .filter((option: DelayReasons) => option.isEnabled || option.id === value)
      .map((option: DelayReasons) => ({ label: option.label, value: option.id }));

    return (
      <Select
        value={value}
        options={delayReasonOptions}
        onChange={(x: any) => {
          onEditValueChange({ value: x.target.value, columnId: 'delayReasonId', data: cellProps.data });
          onEditComplete({ value: x.target.value, columnId: 'delayReasonId', data: cellProps.data });
        }}
        fullWidth
        disableUnderline
        renderValue={selected => {
          if (selected.length === 0 || selected === 0) {
            return 'Select a Delay Reason';
          } else {
            return delayReasons.find(reason => reason.id === selected)?.label ?? '';
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
            },
          },
        }}
      />
    );
  };

  const renderNotes = ({ value }: any, { cellProps }: any) => {
    return (
      <TextField
        disabled={cellProps.data.id === 0}
        value={value}
        placeholder={cellProps.data.id === 0 ? 'Select a reason to enter a note' : 'Enter a note'}
        onChange={(x: any) => {
          onEditValueChange({ value: x.target.value, columnId: 'notes', data: cellProps.data });
        }}
        onBlur={(x: any) => {
          onEditComplete({ value: x.target.value, columnId: 'notes', data: cellProps.data });
        }}
        onKeyDown={(x: any) => {
          if (x.key === 'Enter') {
            x.target.blur();
          }
        }}
        fullWidth
      />
    );
  };

  const renderIcon = ({ cellProps }: any) => {
    return (
      <>
        {cellProps.data.id !== 0 && (
          <StyledDeleteIconButton
            onClick={() => {
              handleDeleteWorkorderDelayReason(cellProps.data.id);
            }}
          >
            <Icon>delete</Icon>
          </StyledDeleteIconButton>
        )}
      </>
    );
  };

  const columns: any[] = [
    {
      name: 'delayReasonId',
      header: 'Delay Reason',
      defaultWidth: 50,

      defaultFlex: 1,
      render: renderDelayReason,
    },
    {
      name: 'notes',
      header: 'Note',
      defaultWidth: 950,
      defaultFlex: 3,
      render: renderNotes,
    },
    {
      defaultWidth: 80,
      render: renderIcon,
    },
  ];

  return (
    <StyledReactDataGrid
      style={{ ...style, ...gridStyle }}
      idProperty="id"
      enableKeyboardNavigation={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuLockOptions={false}
      showColumnMenuTool={false}
      columns={columns}
      dataSource={workorderDelayReasons}
    />
  );
};
