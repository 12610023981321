import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ShipChipTable from '../../../../../components/ShipChip/ShipChipTable';
import { useShipsChips } from '../../../../live-reports/tabs/ship-chip/hooks/useShipsChips';
import ActionButton from '../../../../../components/ActionButton';
import ActionFilterPanel from '../../../../../components/ShipChip/ActionFilterPanel';
import useRiskManagement from '../../../../../context/risk-management';
import CustomTooltip from '../../../../../components/Tooltip';
import { MyElement } from '../../../../../types/my';
import useAllElements from '../../../../../hooks/all-elements';
import { EchoElement } from '../../../../../hooks/elements';
import isFeatureEnabled from '../../../../../utils/feature-flags';
import { type RenderProp } from '../../../../../context/action-visibility/types';
import { useActionVisibility } from '../../../../../context/action-visibility';
import { type ActionVisibilityContextInitalizer } from '../../../../../context/action-visibility/context';

interface Props {
  elementId: string | number;
  riskManagementId: number;
  isReadOnly: boolean;
}
type WithContextProps = Props & {
  renderPropLoadingProgress?: ActionVisibilityContextInitalizer['renderPropLoadingProgress'];
  renderPropInformationBox?: ActionVisibilityContextInitalizer['renderPropInformationBox'];
  onReorder?: ActionVisibilityContextInitalizer['onReorder'];
  passiveLoadingControl?: ActionVisibilityContextInitalizer['passiveLoadingControl'];
  actions?: ActionVisibilityContextInitalizer['actions'];
  updateUiState?: ActionVisibilityContextInitalizer['updateUiState'];
  uiState?: ActionVisibilityContextInitalizer['uiState'];
  isShowingHiddenActions?: ActionVisibilityContextInitalizer['isShowingHiddenActions'];
};
const SectionTitle = ({ renderPropInformationBox: InformationBox }: { renderPropInformationBox?: RenderProp<any> }) => (
  <>
    <Typography>Response/SHIP/CHIP/PHIP Actions</Typography>
    {InformationBox ? <InformationBox /> : null}
  </>
);
const ShipChipSection = ({
  elementId,
  riskManagementId,
  isReadOnly,
  renderPropLoadingProgress: ActionLoadingStatus,
  renderPropInformationBox: InformationBox,
  onReorder,
  passiveLoadingControl,
  actions: actionsFromContext,
  updateUiState,
  isShowingHiddenActions,
}: WithContextProps) => {
  const { myElements } = useRiskManagement();
  const { elements } = useAllElements();

  const [element, setElement] = useState<MyElement | null>(null);
  const isPreferContext = isFeatureEnabled('riskActionHiddenToggle') && riskManagementId && actionsFromContext;
  const {
    actions: actionsFromShipChip,
    clearDeleteAction,
    clearEditAction,
    clearNewAction,
    confirmActionStatusToggle,
    confirmDeleteActionToggle,
    deleteAction,
    deleteActionStatus,
    editAction,
    handleOnCancel,
    handleOnOkay,
    isLoading,
    newAction,
    reorderActions,
    saveNewAction,
    setComment,
    setState,
    source,
    setSource,
    showEditActionDialog,
    showNewActionDialog,
    state,
    statusAction,
    updateAction,
  } = useShipsChips({
    element,
    ...(isPreferContext ? { onReorder, updateUiState, actions: actionsFromContext, riskManagementId } : {}),
  });
  const actionsProp = (isPreferContext ? actionsFromContext : actionsFromShipChip) || [];

  useEffect(() => {
    let element: MyElement | null = null;
    if (isReadOnly) {
      const echoElement = elements.find((e: EchoElement) => e.id === elementId);
      if (echoElement) {
        element = {
          elementId: echoElement.id,
          elementName: echoElement.name,
          elementType: echoElement.type,
          facilityId: echoElement.facilityId,
          facilityName: echoElement.facilityName,
        } as MyElement;
      }
    } else {
      element = myElements.find(element => element.elementId === elementId) || null;
    }
    setElement(element);
  }, [elementId]);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        {ActionLoadingStatus ? (
          <ActionLoadingStatus>
            <SectionTitle />
          </ActionLoadingStatus>
        ) : (
          <SectionTitle />
        )}
        {InformationBox && <InformationBox />}
      </Grid>
      <Grid
        container
        style={{
          marginTop: '20px',
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
        >
          <Grid
            item
            container
            xs={10}
          >
            <ActionFilterPanel
              state={state}
              source={source}
              onChangeStatus={setState}
              onChangeSource={setSource}
              isShowingHiddenActions={isShowingHiddenActions}
            />
          </Grid>
          <Grid
            item
            container
            xs={2}
          >
            <CustomTooltip title="This section allows you to add health improvement plan (SHIP/CHIP/PHIP) actions associated with this risk. It will take you to the Action Item Details page where the actions are added. Once you SAVE the Action Item Details, the newly added action will appear in the Response/SHIP/CHIP/PHIP section on this RISK OWNER page.">
              <IconButton
                size="small"
                onClick={() => null}
              >
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
            <ActionButton
              color="primary"
              onClick={showNewActionDialog}
              disabled={R.isEmpty(elementId) || isReadOnly}
            >
              Add Action
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ marginTop: '20px' }}>
        <ShipChipTable
          actions={actionsProp}
          clearDeleteAction={clearDeleteAction}
          clearEditAction={clearEditAction}
          clearNewAction={clearNewAction}
          confirmActionStatusToggle={confirmActionStatusToggle}
          confirmDeleteActionToggle={confirmDeleteActionToggle}
          deleteAction={deleteAction}
          deleteActionStatus={deleteActionStatus}
          editAction={editAction}
          element={element}
          handleOnCancel={handleOnCancel}
          handleOnOkay={handleOnOkay}
          isLoading={
            isPreferContext && typeof passiveLoadingControl === 'function'
              ? passiveLoadingControl(isLoading, actionsProp)
              : isLoading
          }
          newAction={newAction}
          relatedRisk={riskManagementId}
          reorderActions={reorderActions}
          saveNewAction={saveNewAction}
          setComment={setComment}
          showEditActionDialog={showEditActionDialog}
          state={state}
          statusAction={statusAction}
          updateAction={updateAction}
          {...(isPreferContext ? { isActionVisibilityTable: true } : {})}
        />
      </Box>
    </>
  );
};
export const ShipChipSectionWithVisibilityContext = (
  props:
    | Omit<Props, 'elementId' | 'riskManagementId'> & {
        children?: React.PropsWithChildren['children'] | null;
      }
) => {
  const {
    elementId,
    riskManagementId,
    passiveLoadingControl,
    onReorder,
    renderPropSeeHidden,
    renderPropVisibilityIcon,
    renderPropLoadingProgress,
    renderPropInformationBox,
    actions,
    updateUiState,
    uiState,
    isShowingHiddenActions,
  } = useActionVisibility();
  if (typeof riskManagementId !== 'number' || !(typeof elementId === 'string' || typeof elementId === 'number')) {
    return null;
  }
  return (
    <ShipChipSection
      {...{
        ...(props || {}),
        actions,
        passiveLoadingControl,
        onReorder,
        renderPropSeeHidden,
        renderPropVisibilityIcon,
        renderPropLoadingProgress,
        renderPropInformationBox,
        elementId,
        riskManagementId,
        updateUiState,
        uiState,
        isShowingHiddenActions,
      }}
    />
  );
};
export default ShipChipSection;
