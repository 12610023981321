import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actionActions from '../state/actions/actions';
import isFeatureEnabled from '../utils/feature-flags';
import { type ActionState } from '../types/actions';

type UseReorderActions = {
  actions: any;
  state: ActionState;
  onReorder?: null | ((payload?: any) => Promise<void>);
};
const useReorderActions = ({ actions, state, onReorder }: UseReorderActions) => {
  const dispatch = useDispatch();

  const reorderActions = useCallback(
    (fromIndex: number, toIndex: number) => {
      const action = actions[fromIndex];
      const updatePayload = {
        actionId: action.id,
        fromIndex,
        toIndex,
        state,
        elementId: action.elementId,
      };
      if (isFeatureEnabled('riskActionHiddenToggle') && typeof onReorder === 'function') {
        onReorder(updatePayload);
        return;
      }
      dispatch(actionActions.reorderActionsRequest(updatePayload));
    },
    [dispatch, actions, state, onReorder]
  );
  return reorderActions;
};

export default useReorderActions;
