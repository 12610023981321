import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actionActions from './actions';

function* loadActions(action: any): any {
  const { elementId, state, riskManagementId, isAjaxRequestHandled } = action.payload;
  if (isAjaxRequestHandled) {
    return;
  }
  try {
    if (typeof riskManagementId === 'number') {
      const actions = yield call(Api.getActions, elementId, state, riskManagementId);
      yield put(actionActions.loadActionsSuccess({ elementId, state, actions, riskManagementId }));
    } else {
      const actions = yield call(Api.getActions, elementId, state);
      yield put(actionActions.loadActionsSuccess({ elementId, state, actions }));
    }
  } catch (e) {
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.loadActionsFailure(e, { elementId, state, riskManagementId })
        : actionActions.loadActionsFailure(e)
    );
  }
}

function* createAction(reduxAction: any): any {
  const { action } = reduxAction.payload;
  const { riskManagementId } = action;
  try {
    yield call(Api.createAction, action);
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.loadActionsRequest(action.elementId, action.state || 'ongoing', riskManagementId)
        : actionActions.loadActionsRequest(action.elementId, action.state || 'ongoing')
    );
  } catch (e) {
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.createActionFailure(e, {
            elementId: action.elementId,
            state: action.state || 'ongoing',
            riskManagementId,
          })
        : actionActions.createActionFailure(e)
    );
  }
}

function* updateAction(reduxAction: any): any {
  const { action } = reduxAction.payload;
  const { riskManagementId } = action;
  try {
    yield call(Api.updateAction, action);
    yield put(actionActions.updateActionSuccess());
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.loadActionsRequest(action.elementId, action.state || 'ongoing', riskManagementId)
        : actionActions.loadActionsRequest(action.elementId, action.state || 'ongoing')
    );
  } catch (e) {
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.updateActionFailure(e, {
            elementId: action.elementId,
            state: action.state || 'ongoing',
            riskManagementId,
          })
        : actionActions.updateActionFailure(e)
    );
  }
}

function* reorderActions(action: any): any {
  const { actionId, elementId, riskManagementId } = action.payload;
  const toIndex = action.payload.toIndex + 1;
  try {
    yield call(Api.updateActionPriority, actionId, toIndex);
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.loadActionsRequest(elementId, 'ongoing', riskManagementId)
        : actionActions.loadActionsRequest(elementId, 'ongoing')
    );
  } catch (e) {
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.reorderActionsFailure(e, { actionId, toIndex, elementId, riskManagementId })
        : actionActions.reorderActionsFailure(e, { actionId, toIndex, elementId })
    );
  }
}

function* updateActionStatus(action: any): any {
  const { actionId, state, elementId, comment, riskManagementId } = action.payload;
  try {
    yield call(Api.updateActionStatus, actionId, state, comment);
    yield put(
      // eslint-disable-next-line no-nested-ternary
      state === 'ongoing'
        ? actionActions.actionReopened()
        : state === 'delete'
        ? actionActions.actionDeleted()
        : actionActions.actionClosed()
    );
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.loadActionsRequest(elementId, state === 'ongoing' ? 'complete' : 'ongoing', riskManagementId)
        : actionActions.loadActionsRequest(elementId, state === 'ongoing' ? 'complete' : 'ongoing')
    );
  } catch (e) {
    yield put(
      typeof riskManagementId === 'number'
        ? actionActions.updateActionStatusFailure(e, { elementId, state, riskManagementId })
        : actionActions.updateActionStatusFailure(e)
    );
  }
}

function* root() {
  yield takeEvery(actionTypes.ACTIONS_LOAD_ALL_REQUEST, loadActions);
  yield takeEvery(actionTypes.ACTIONS_CREATE_REQUEST, createAction);
  yield takeEvery(actionTypes.ACTIONS_UPDATE_REQUEST, updateAction);
  yield takeEvery(actionTypes.ACTIONS_REORDER_REQUEST, reorderActions);
  yield takeEvery(actionTypes.ACTIONS_UPDATE_STATUS_REQUEST, updateActionStatus);
}

export default root;
