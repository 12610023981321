"use strict";
// @ts-nocheck
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withAdjustments = exports.withOverrides = exports.withOverride = void 0;
const R = __importStar(require("ramda"));
exports.withOverride = R.curry((override, scorecard) => (Object.assign(Object.assign({}, scorecard), { values: Object.assign(Object.assign({}, scorecard.values), { [override.indicatorId]: Object.assign(Object.assign({}, scorecard.values[override.indicatorId]), { [override.unitId]: Object.assign(Object.assign({}, scorecard.values[override.indicatorId][override.unitId]), { calculated: Object.assign(Object.assign({}, scorecard.values[override.indicatorId][override.unitId].calculated), { override: {
                        score: override.score,
                        comment: override.comment,
                    } }) }) }) }) })));
exports.withOverrides = R.curry((overrides, scorecard) => R.reduce((acc, override) => (0, exports.withOverride)(override, acc), scorecard, overrides));
const clampScore = (score) => R.max(0, score);
const withAdjustment = (scorecard, adjustment) => (Object.assign(Object.assign({}, scorecard), { values: Object.assign(Object.assign({}, scorecard.values), { [adjustment.indicatorId]: Object.assign(Object.assign({}, scorecard.values[adjustment.indicatorId]), { [adjustment.unitId]: Object.assign(Object.assign({}, scorecard.values[adjustment.indicatorId][adjustment.unitId]), { calculated: {
                    score: clampScore(scorecard.values[adjustment.indicatorId][adjustment.unitId].calculated.score + adjustment.value),
                    // remove any override
                } }) }) }) }));
const allCalculatedIndicatorIds = (scorecard) => R.compose(R.map(R.prop('id')), R.filter(R.propEq('type', 'calculated')), R.map((indicatorId) => scorecard.indicatorMap[indicatorId]))(scorecard.indicators);
const onlyCalculatedIndicatorAdjustments = (adjustments, calculatedIndicatorIds) => R.filter((adjustment) => R.includes(adjustment.indicatorId, calculatedIndicatorIds), adjustments);
exports.withAdjustments = R.curry((adjustments, scorecard) => R.reduce(withAdjustment, scorecard, onlyCalculatedIndicatorAdjustments(adjustments, allCalculatedIndicatorIds(scorecard))));
