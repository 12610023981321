import * as R from 'ramda';
import * as actionTypes from '../action-types';
import actionKey from './key';

const initialState = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ACTIONS_LOAD_ALL_REQUEST: {
      const { elementId, state: actionState, riskManagementId, isAjaxRequestHandled } = action.payload;
      const key = actionKey(elementId, actionState, riskManagementId);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          ...(typeof isAjaxRequestHandled === 'boolean' ? { isAjaxRequestHandled: !!isAjaxRequestHandled } : {}),
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.ACTIONS_LOAD_ALL_SUCCESS: {
      const { elementId, actions, state: actionState, riskManagementId } = action.payload;
      const key = actionKey(elementId, actionState, riskManagementId);
      return {
        ...state,
        [key]: {
          actions,
          isLoading: false,
          error: undefined,
        },
      };
    }
    case actionTypes.ACTIONS_LOAD_ALL_FAILURE: {
      const { error, elementId, state: actionState, riskManagementId } = action.payload;
      const key = actionKey(elementId, actionState, riskManagementId);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          isAjaxRequestHandled: undefined,
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.ACTIONS_REORDER_REQUEST: {
      const {
        elementId,
        state: actionState,
        fromIndex,
        toIndex,
        riskManagementId,
        isAjaxRequestHandled,
      } = action.payload;
      const key = actionKey(elementId, actionState, riskManagementId);
      const actions = R.pathOr([], [key, 'actions'], state);
      const defaultValue = R.propOr<any, any, any>({}, key, state);
      return {
        ...state,
        [key]: {
          ...defaultValue,
          ...(typeof isAjaxRequestHandled === 'boolean' ? { isAjaxRequestHandled: !!isAjaxRequestHandled } : {}),
          actions: R.insert(toIndex, R.nth(fromIndex, actions), R.remove(fromIndex, 1, actions)),
        },
      };
    }
    default:
      return state;
  }
};
