import Page from '../../../../components/Page';
import { Box, Grid, Tooltip, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import DownloadButton from '../../../../components/DownloadButton';
import WODetailsModal from '../../components/WODetailsModal';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import styled from 'styled-components';
import FilterButton from '../../../../components/FilterButton';
import WoTable from './WoTable';
import TaskTable from './TaskTable';
import { useAssessing } from '../../hooks/useAssessing';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AutocompleteInput from '../../../../components/AutocompleteInput';
import ActionButton from '../../../../components/ActionButton';
import { getOptions } from './utils';
import isFeatureEnabled from '../../../../utils/feature-flags';

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;

const WaitSwitch = styled(Switch)`
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: red;
  }
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: rgba(0, 255, 0, 0.04);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: red;
  }
`;

const AssessingHeader = () => {
  const TAB_STATES = ['wo-view', 'task-view'];

  const TITLES: Record<string, string> = {
    'wo-view': 'WORK ORDERS',
    'task-view': 'TASKS ',
  };

  const {
    workorders,
    woLoading,
    taskLoading,
    exportCSV,
    filtersWO,
    setWOFilters,
    filteredWorkorders,
    fetchWorkorders,
    fetchTasks,
    setActiveView,
    activeView,
    tasksTotalCount,
    selectedWonum,
    getWorkorderByWonum,
  } = useAssessing();

  const backlogCount = activeView === 'wo-view' ? filteredWorkorders.length : tasksTotalCount;

  return (
    <Page title="Assessing">
      <WODetailsModalContextProvider workorder={getWorkorderByWonum(selectedWonum)}>
        <Box
          display="flex"
          justifyContent="flex-start"
          marginBottom="20px"
          marginTop="10px"
          marginLeft="10px"
        >
          <Box
            width="20%"
            height="100px"
            minWidth={'120px'}
            borderRadius="5px"
            textAlign="center"
            boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
            marginRight="10px"
          >
            <Typography style={{ fontWeight: '500', fontSize: '45px' }}>
              {woLoading || taskLoading ? '...' : backlogCount}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>Current Backlog</Typography>
          </Box>
          {isFeatureEnabled('CWT_assessor_tasks') && (
            <Box
              display="flex"
              alignItems="center"
            >
              {TAB_STATES.map(tabState => (
                <Box
                  marginRight="10px"
                  key={tabState}
                >
                  <FilterButton
                    isActive={activeView === tabState}
                    onClick={() => setActiveView(tabState)}
                  >
                    {TITLES[tabState]}
                  </FilterButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Grid
          container
          spacing={2}
          style={{ marginLeft: '10px' }}
        >
          {activeView === 'wo-view' && (
            <>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Work Order"
                  options={getOptions(workorders, 'wonum')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, wonum: value });
                  }}
                  value={filtersWO.wonum}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Assigned To"
                  options={getOptions(workorders, 'plusplanner')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, assessor: value });
                  }}
                  value={filtersWO.wonum}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Priority"
                  options={getOptions(workorders, 'wopriority')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, wopriority: value });
                  }}
                  value={filtersWO.wopriority}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Work Type"
                  options={getOptions(workorders, 'worktype')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, worktype: value });
                  }}
                  value={filtersWO.worktype}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Location"
                  options={getOptions(workorders, 'location')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, location: value });
                  }}
                  value={filtersWO.location}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="Crew"
                  options={getOptions(workorders, 'crewworkgroup')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, crewworkgroup: value });
                  }}
                  value={filtersWO.crewworkgroup}
                  multiple
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <AutocompleteInput
                  label="SPM"
                  options={getOptions(workorders, 'bpspm')}
                  onChange={value => {
                    setWOFilters({ ...filtersWO, spm: value });
                  }}
                  value={filtersWO.spm}
                  multiple
                />
              </Grid>
            </>
          )}
          {activeView === 'wo-view' && (
            <Grid
              item
              xs={6}
            >
              <FormControlLabel
                control={
                  <WaitSwitch
                    checked={filtersWO.highPriority}
                    onChange={e => {
                      setWOFilters({
                        ...filtersWO,
                        highPriority: e.target.checked,
                      });
                    }}
                    name="highPriority"
                  />
                }
                label="High Priority"
              />
            </Grid>
          )}
        </Grid>
        <Box
          width={'100%'}
          justifyContent={'space-between'}
          display="flex"
          marginTop={'10px'}
        >
          {activeView === 'wo-view' && (
            <Tooltip
              title="Refresh Backlog"
              placement="right"
            >
              <IconButton onClick={fetchWorkorders}>
                <RefreshBacklogIcon />
              </IconButton>
            </Tooltip>
          )}
          {activeView === 'task-view' && (
            <Tooltip
              title="Refresh Backlog"
              placement="right"
            >
              <IconButton onClick={fetchTasks}>
                <RefreshBacklogIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title="Export CSV"
            placement="top"
          >
            <DownloadButton onClick={exportCSV}></DownloadButton>
          </Tooltip>
        </Box>
        {activeView === 'wo-view' && <WoTable />}
        {activeView === 'task-view' && <TaskTable />}
        <WODetailsModal />
      </WODetailsModalContextProvider>
      <Box
        width={'100%'}
        justifyContent={'flex-end'}
        display="flex"
        marginTop={'15px'}
      >
        <ActionButton
          color="primary"
          variant="text"
        >
          Save Entry
        </ActionButton>
      </Box>
    </Page>
  );
};

export default AssessingHeader;
