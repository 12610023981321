import { Indicator } from '@repo/echo-scorecard';
import * as R from 'ramda';

import { StaticCell } from '../StaticCell';
import { Props } from '../types';
import { IndicatorRowRoot } from './IndicatorRowRoot';
import { Tooltip } from '../StyledComponents';
import { UnitValue } from './UnitValue';

export const ReadOnlyIndicatorRow = ({ indicator, scorecard }: Pick<Props, 'scorecard'> & { indicator: Indicator }) => {
  const unitCells = scorecard.units.map((unitId: string) => (
    <StaticCell
      key={unitId}
      colour={
        scorecard.unitMap[unitId]?.mcr
          ? 'grey'
          : R.pathOr('white', ['scores', indicator.id, unitId, 'colour'], scorecard)
      }
      overridden={R.pathOr(false, ['scores', indicator.id, unitId, 'overridden'], scorecard)}
    >
      <Tooltip
        placement="top"
        arrow
        title={`Indicator weight: ${R.path(
          ['values', indicator.id, unitId, 'indicatorWeight'],
          scorecard
        )}% \n Unit Weight: ${R.path(['values', indicator.id, unitId, 'unitWeight'], scorecard)}%`}
      >
        <UnitValue
          isCalculated={indicator.type === 'calculated'}
          value={R.path(['scores', indicator.id, unitId, 'value'], scorecard)}
        />
      </Tooltip>
    </StaticCell>
  ));

  return (
    <IndicatorRowRoot
      name={indicator.name}
      id={indicator.id}
      definition={indicator.definition}
    >
      {unitCells}
    </IndicatorRowRoot>
  );
};
