import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import helpers from './helpers';
import * as actionSelectors from '../../state/actions/selectors';
import type {
  ReorderState,
  RequireInR,
  RequireInT,
  StaleDataState,
  UpdateRequestState,
  VisibilityState,
} from './types';
import { type MyElement } from '../../types/my';
const { compareObject, selectRiskActions, selectStaleActions } = helpers;
type UseActionVisibilityStaticValuesParams<T, R, A> = {
  staleDataState: StaleDataState<T[]>;
  elementId?: MyElement['elementId'] | null;
  riskManagementId?: number | null;
  reorderRequest: ReorderState<T, R & RequireInR>;
  updateRequestData: UpdateRequestState<T[]>;
  visibilityUpdating: VisibilityState<T>;
  isShowingHiddenActions: boolean;
  actionState: A;
};
export default <T extends RequireInT, R, A>({
  elementId,
  riskManagementId,
  reorderRequest,
  updateRequestData,
  visibilityUpdating,
  isShowingHiddenActions,
  actionState,
  staleDataState,
}: UseActionVisibilityStaticValuesParams<T, R, A>) => {
  const ongoingState = useSelector(state =>
    actionSelectors.getActionState(state, elementId, 'ongoing', riskManagementId)
  ) as { actions?: T[]; isLoading?: boolean };
  const completedState = useSelector(state =>
    actionSelectors.getActionState(state, elementId, 'complete', riskManagementId)
  ) as { actions?: T[]; isLoading?: boolean };
  const ongoingActions: T[] = ongoingState?.actions ? ongoingState.actions : [];
  const completedActions: T[] = completedState?.actions ? completedState.actions : [];
  const isShowingShowingNewMessage =
    [...ongoingActions, ...completedActions].filter(item => (item as T & RequireInT)?.isNew).length > 0;
  const isBackgroundLoading = !!(
    reorderRequest.isLoading ||
    Object.values(visibilityUpdating)
      .map(({ actionPromise }) => actionPromise)
      .filter(p => !!p).length > 0 ||
    updateRequestData.retrievePromise ||
    !updateRequestData.handledData.ongoing ||
    !updateRequestData.handledData.complete
  );
  const isLoading = isBackgroundLoading || updateRequestData.isLoading;
  const isError =
    updateRequestData.isError ||
    reorderRequest.isError ||
    Object.values(visibilityUpdating).filter(({ isError }) => isError).length > 0;
  const isReorderingLocked = isLoading || isError;
  // always reflect the api/redux-state
  const { actions } = selectRiskActions<RequireInT & T, A>({
    isLoading,
    isError,
    isShowingHiddenActions,
    actionState,
    actions: { complete: completedActions, ongoing: ongoingActions },
    staleData: staleDataState.actions,
  });
  // hidden items is only sourced from the api/redux-state
  const hiddenItems = useMemo(
    () =>
      selectRiskActions<RequireInT & T, A>({
        isLoading: false,
        isError: false,
        isShowingHiddenActions: true,
        // actionState, // actionState has no effect so for memo-ization make it undefined
        staleData: { complete: null, ongoing: null },
        actions: { complete: completedActions, ongoing: ongoingActions },
      }).hiddenItems,
    [ongoingActions, completedActions]
  );
  // UI-Interactable avoid flash-load-state & UI-Locking but become null if it's the same state out of sync
  let { actions: staleData } = selectStaleActions<RequireInT & T, A>({
    isLoading,
    isError,
    isShowingHiddenActions,
    actionState,
    actions: { complete: completedActions, ongoing: ongoingActions },
    staleData: staleDataState.actions,
  });
  // if the states match make the stale date irrelevant
  if (staleData && (staleData.length === 0 || compareObject(staleData, actions))) {
    staleData = null;
  }
  const actionsToBeRendered = staleData || actions || [];
  return {
    isReorderingLocked,
    ongoingState,
    completedState,
    ongoingActions,
    completedActions,
    isShowingShowingNewMessage,
    isBackgroundLoading,
    isLoading,
    isError,
    actions: actionsToBeRendered,
    hiddenItems,
    staleData,
  };
};
