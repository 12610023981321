"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWeightedScore = exports.getWeightedIndicatorScore = exports.getUnitScore = exports.getScores = exports.withScores = void 0;
const R = __importStar(require("ramda"));
const indicators_1 = require("./indicators");
const units_1 = require("./units");
const colour_map_1 = require("./colour-map");
const colour_curves_1 = require("./colour-curves");
const manual_values_1 = require("./manual-values");
const calculated_values_1 = require("./calculated-values");
const weightAsPercent = (weight) => (weight / 100.0);
const calculateWeightedIndicatorScore = (colourScore, indicator, scorecard) => colourScore * weightAsPercent((0, indicators_1.getIndicatorWeight)(indicator.id, scorecard));
const calculateWeightedScore = (colourScore, indicator, unitId, scorecard) => calculateWeightedIndicatorScore(colourScore, indicator, scorecard) * weightAsPercent((0, units_1.getUnitWeight)(unitId, scorecard));
const getCalculatedValue = R.pipe(calculated_values_1.getCalculatedScorecardValue, calculated_values_1.getOverrideOrCalculatedValue);
const buildCalculatedUnitScore = (indicator, unitId, scorecard) => {
    const value = getCalculatedValue(indicator.id, unitId, scorecard);
    const colour = (0, colour_curves_1.colourFromCurve)((0, indicators_1.getColourCurve)(indicator), value);
    const comment = (0, calculated_values_1.getOverrideComment)((0, calculated_values_1.getCalculatedScorecardValue)(indicator.id, unitId, scorecard));
    const overridden = R.complement(R.isNil)(comment);
    const colourScore = (0, colour_map_1.getColourMapScore)(colour, scorecard);
    const weightedIndicatorScore = calculateWeightedIndicatorScore(colourScore, indicator, scorecard);
    const weightedScore = calculateWeightedScore(colourScore, indicator, unitId, scorecard);
    return Object.assign(Object.assign({ value,
        colour,
        colourScore,
        weightedIndicatorScore,
        weightedScore }, (R.isNil(comment) ? {} : { comment })), (overridden ? { overridden: true } : {}));
};
const buildManualUnitScore = (indicator, unitId, scorecard) => {
    const value = (0, manual_values_1.getManualValue)((0, manual_values_1.getManualScorecardValue)(indicator, unitId, scorecard));
    const colour = (0, manual_values_1.getManualColour)((0, manual_values_1.getManualScorecardValue)(indicator, unitId, scorecard));
    const comment = (0, manual_values_1.getManualComment)((0, manual_values_1.getManualScorecardValue)(indicator, unitId, scorecard));
    const colourScore = (0, colour_map_1.getColourMapScore)(colour, scorecard);
    const weightedIndicatorScore = calculateWeightedIndicatorScore(colourScore, indicator, scorecard);
    const weightedScore = calculateWeightedScore(colourScore, indicator, unitId, scorecard);
    return {
        value,
        colour,
        colourScore,
        weightedIndicatorScore,
        weightedScore,
        comment,
    };
};
const buildUnitScores = (indicator, scorecard) => R.reduce((unitScores, unitId) => (Object.assign(Object.assign({}, unitScores), { [unitId]: indicator.type === 'calculated'
        ? buildCalculatedUnitScore(indicator, unitId, scorecard)
        : buildManualUnitScore(indicator, unitId, scorecard) })), {}, scorecard.units);
const buildScores = (scorecard) => R.reduce((scores, indicatorId) => (Object.assign(Object.assign({}, scores), { [indicatorId]: buildUnitScores((0, indicators_1.getIndicator)(indicatorId, scorecard), scorecard) })), {}, scorecard.indicators);
const withScores = (scorecard) => (Object.assign(Object.assign({}, scorecard), { scores: buildScores(scorecard) }));
exports.withScores = withScores;
const getScores = (scorecard) => scorecard.scores;
exports.getScores = getScores;
const getUnitScore = (indicatorId, unitId, scorecard) => (0, exports.getScores)(scorecard)[indicatorId][unitId];
exports.getUnitScore = getUnitScore;
const getWeightedIndicatorScore = (indicatorId, unitId, scorecard) => (0, exports.getUnitScore)(indicatorId, unitId, scorecard).weightedIndicatorScore;
exports.getWeightedIndicatorScore = getWeightedIndicatorScore;
const getWeightedScore = (indicatorId, unitId, scorecard) => (0, exports.getUnitScore)(indicatorId, unitId, scorecard).weightedScore;
exports.getWeightedScore = getWeightedScore;
