import styled from 'styled-components';
import UnstyledAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { VisibilityOff } from '@material-ui/icons';
const Alert = styled(UnstyledAlert)``;

const DefaultMessage = () => (
  <Alert severity="info">
    <AlertTitle>Disable unrelated actions for this risk</AlertTitle>
    <Typography
      variant="body2"
      component="span"
    >
      You can now toggle which actions should be enabled by selecting the{' '}
      <VisibilityOff style={{ verticalAlign: 'middle', fontSize: '1rem' }} /> icon. This disables delete, edit, and
      complete, until toggled back on.
    </Typography>
  </Alert>
);
export default DefaultMessage;
