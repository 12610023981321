import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import RestoreIcon from '@material-ui/icons/Restore';
import UnstyledEditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { RenderProp } from '../../context/action-visibility/types';

const EditIcon = styled(UnstyledEditIcon)`
  height: 21px;
  width: 21px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

interface Props {
  className?: string;
  state: string;
  action: any;
  onEdit?: any;
  onChangeStatus?: any;
  onDelete?: any;
  renderPropBefore?: RenderProp<any> | null;
  renderPropAfter?: RenderProp<any> | null;
  isReadOnly?: boolean;
}

const ActionsCell = ({
  className,
  action,
  renderPropBefore: BeforeRenderer,
  renderPropAfter: AfterRenderer,
  onEdit,
  onChangeStatus,
  onDelete,
  isReadOnly,
}: Props) => {
  const handleEdit = () => {
    onEdit(action.id);
  };

  const handleChangeStatus = () => {
    onChangeStatus(action.id);
  };

  const handleDelete = () => {
    onDelete(action.id);
  };

  return (
    <Container className={className}>
      {BeforeRenderer && (
        <BeforeRenderer
          column={{ id: 'actions' }}
          action={action}
        />
      )}
      {action.state !== 'complete' && onEdit && (
        <IconButton
          size="small"
          onClick={handleEdit}
          disabled={isReadOnly || action.isHidden}
        >
          <EditIcon color={isReadOnly || action.isHidden ? 'disabled' : 'primary'} />
        </IconButton>
      )}
      {onChangeStatus && (
        <IconButton
          size="small"
          onClick={handleChangeStatus}
          disabled={isReadOnly || action.isHidden}
        >
          {action.state === 'ongoing' || action.actionState === 'ongoing' ? (
            <CheckIcon color={isReadOnly || action.isHidden ? 'disabled' : 'primary'} />
          ) : (
            <RestoreIcon color={isReadOnly || action.isHidden ? 'disabled' : 'primary'} />
          )}
        </IconButton>
      )}
      {action.state !== 'complete' && onDelete && (
        <IconButton
          size="small"
          onClick={handleDelete}
          disabled={isReadOnly || action.isHidden}
        >
          <DeleteIcon color={isReadOnly || action.isHidden ? 'disabled' : 'primary'} />
        </IconButton>
      )}
      {AfterRenderer && (
        <AfterRenderer
          column={{ id: 'actions' }}
          action={action}
        />
      )}
    </Container>
  );
};

export default ActionsCell;
