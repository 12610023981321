import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { ActionsContextProvider } from '../hooks/useActions';
import AddNewWorkorderPage from './pages/AddNewWorkorderPage';
import DropdownMaintenancePage from './pages/DropdownMaintenancePage';
import { WorkOrderFlagsContextProvider } from '../hooks/useWorkOrderFlags';
import { TPlusOneContextProvider } from '../hooks/useTPlusOne';

const AdminPage = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-new-workorder`}>
        <AddNewWorkorderPage />
      </Route>
      <Route path={`${path}/dropdown-maintenance`}>
        <ActionsContextProvider>
          <TPlusOneContextProvider>
            <WorkOrderFlagsContextProvider>
              <DropdownMaintenancePage />
            </WorkOrderFlagsContextProvider>
          </TPlusOneContextProvider>
        </ActionsContextProvider>
      </Route>
      <Route
        exact
        path={path}
      >
        <Redirect to={`${path}/add-new-workorder`} />
      </Route>
    </Switch>
  );
};
export default AdminPage;
