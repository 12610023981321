import { Box } from '@material-ui/core';
import * as R from 'ramda';
import styled from 'styled-components';
import PowerBiReport from '../../../../../components/PowerBiReport';
import SCHoldsTable from './components/SCHoldsTable';
import { useWODetailsModal } from '../../../hooks/useWODetailsModal';

const Container = styled(Box)`
  & > :last-child {
    margin-top: 1.5em;
  }
`;

const SupplyChainTab: React.FC = () => {
  const { workorder } = useWODetailsModal();
  const reportId = R.pathOr('', ['echoConfig', 'supplyChainPowerBiReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'supplyChainPowerBiPageName'], window);
  return (
    <Container>
      {reportId && workorder && (
        <PowerBiReport
          title="Supply Chain Details"
          pageName={reportPageName}
          reportId={reportId}
          filter={`Material_Master/WOPARENT eq '${workorder.wonum}'`}
          height="50vh"
          showActionBar
        />
      )}
      <SCHoldsTable />
    </Container>
  );
};

export default SupplyChainTab;
