import { CircularProgress, Typography } from '@material-ui/core';
import { VisibilityOff, Visibility, Cancel as CancelIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import theme from '../../theme';
import DefaultMessage from '../../components/InfoBoxes/VisibilityMessage';
import {
  RenderPropChildProp,
  ReorderState,
  RequireInR,
  RequireInT,
  RowIteratorParams,
  StaleDataState,
  UpdateRequestState,
  VisibilityState,
  RowHeaderPayload,
} from './types';
import { MyElement } from '../../types/my';
import { useCallback } from 'react';

export const ReloadButtonWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 21px;
`;
export const ReloadButton = styled.button`
  border: none;
  background: none;
  color: ${theme.colours.action.primary};
  font: ${theme.fonts.normal14};
  font-weight: 700;
  line-height: normal;
`;
export const InformationBoxWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 21px;
`;
export const ActionsProgressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;
export const ActionsProgress = styled(CircularProgress)`
  margin-left: 9px;
`;
export const VisibilityToggleButtonWrapper = styled.div`
  margin-bottom: 13px;
`;
export const DotIndicator = styled.div`
  width: 4px;
  height: 4px;
  position: relative;
  background-color: ${({ theme }) => theme.colours.iconBlue};
  border-radius: 100%;
  z-index: 50;
  top: 5px;
  left: 0px;
`;
export const VisibilityToggleButton = styled.button`
  border: none;
  background: none;
  color: ${theme.colours.action.primary};
  font: ${theme.fonts.normal14};
  font-weight: 700;
  line-height: normal;
`;
export const GreySpan = styled.span`
  padding-right: 20px;
  color: ${theme.colours.notClickableGrey};
  font: ${theme.fonts.normal14};
  font-weight: 700;
  line-height: normal;
`;
export const NewMessageBoxWrpapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  margintop: 20px;
  gap: 16px;
  background-color: #fff4e5;
  border-radius: 4px;
  color: #663c00;
  width: fit-content;
  margin-top: 12px;
`;
export const NewMessageBoxButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
`;
const NewMessageCancelIcon = styled(CancelIcon)`
  margin-left: 5px;
`;
export const InformationBox = () => (
  <InformationBoxWrapper>
    <DefaultMessage />
  </InformationBoxWrapper>
);
export const EmptyRender = () => null;

type UseActionVisibilityElementsParams<T, A, R> = {
  isReadOnly?: boolean;
  isBackgroundLoading: boolean;
  onReloadClick: () => void;
  isShowingHiddenActions: boolean;
  ongoingActions: T[] | null;
  completedActions: T[] | null;
  visibilityUpdating: VisibilityState<T>;
  hiddenItems: (RequireInT & T)[];
  elementId?: MyElement['elementId'] | null;
  isShowingShowingNewMessage: boolean;
  onClickNewMessageClose: () => void;
  onToggleShipChipRiskVisibility: (payload: any) => void;
  riskManagementId?: number | null;
  actions: (RequireInT & T)[];
  actionState?: A | null;
  updateRequestData: UpdateRequestState<T[]>;
  onToggleClick: () => void;
  isReorderingLocked: boolean;
  reorderRequest: ReorderState<RequireInT, R & RequireInR>;
  staleDataState: StaleDataState<T[]>;
};
export const useActionVisibilityElements = <T, A, R>({
  isReadOnly,
  isBackgroundLoading,
  onReloadClick,
  isShowingHiddenActions,
  ongoingActions,
  completedActions,
  visibilityUpdating,
  hiddenItems,
  elementId,
  isShowingShowingNewMessage,
  onClickNewMessageClose,
  onToggleShipChipRiskVisibility,
  riskManagementId,
  actions,
  actionState,
  updateRequestData,
  onToggleClick,
  isReorderingLocked,
  reorderRequest,
  staleDataState,
}: UseActionVisibilityElementsParams<T, A, R>) => {
  const LoadingElement = useCallback(
    () => (isBackgroundLoading ? <CircularProgress size={10} /> : null),
    [isBackgroundLoading]
  );
  const ReloadMessage = useCallback(() => {
    if (!updateRequestData.isError) {
      return null;
    }
    return (
      <ReloadButtonWrapper>
        <Typography>There was an error retrieving the data. Please check your connection or hit refresh.</Typography>
        <ReloadButton onClick={onReloadClick}>Try Again</ReloadButton>
      </ReloadButtonWrapper>
    );
  }, [updateRequestData, onReloadClick]);
  const SeeHiddenLink = useCallback(() => {
    const hiddenCount: number = hiddenItems.length;
    if (!isShowingHiddenActions && hiddenCount <= 0) {
      return (
        <>
          <VisibilityToggleButtonWrapper>
            <GreySpan>View all hidden actions (0)</GreySpan>
            <LoadingElement />
          </VisibilityToggleButtonWrapper>
        </>
      );
    }

    return (
      <>
        <VisibilityToggleButtonWrapper>
          {isShowingHiddenActions ? (
            <VisibilityToggleButton onClick={onToggleClick}>
              <GreySpan>View all hidden actions ({hiddenCount.toLocaleString()})</GreySpan>
              Go back to Action table
            </VisibilityToggleButton>
          ) : (
            <VisibilityToggleButton onClick={onToggleClick}>
              View all hidden actions ({hiddenCount.toLocaleString()})
            </VisibilityToggleButton>
          )}
          <LoadingElement />
        </VisibilityToggleButtonWrapper>
      </>
    );
  }, [isShowingHiddenActions, ongoingActions, completedActions, visibilityUpdating, hiddenItems, LoadingElement]);

  const ActionLoadingStatus = useCallback(
    (renderProps: RenderPropChildProp | undefined) => {
      if (!elementId) {
        return renderProps?.children ? <>{renderProps.children}</> : null;
      }
      if (actions || isBackgroundLoading) {
        return renderProps?.children ? <>{renderProps.children}</> : null;
      }
      return (
        <ActionsProgressWrapper>
          {renderProps?.children || null}
          <ActionsProgress size={16} />
        </ActionsProgressWrapper>
      );
    },
    [elementId, isBackgroundLoading]
  );
  const NewMessageNotification = useCallback(() => {
    if (!isShowingShowingNewMessage) {
      return null;
    }
    return (
      <NewMessageBoxWrpapper>
        <span>One or more actions were added since your last session.</span>
        <NewMessageBoxButton
          color="secondary"
          style={{ color: '#663C00' }}
          onClick={() => null}
        >
          Close
          <NewMessageCancelIcon />
        </NewMessageBoxButton>
      </NewMessageBoxWrpapper>
    );
  }, [isShowingShowingNewMessage, onClickNewMessageClose]);
  const ActionVisibilityIcon = useCallback(
    // (props?: ActionVisibilityIconProps<RequireInT & T> | null) => {
    (props?: any | null) => {
      const { action } = props || {};
      const { id: actionId, isHidden } = action || {};
      const currentState = actionId && actionId in visibilityUpdating && visibilityUpdating[actionId];
      const { isLoading = false } = currentState || {};
      const iconColor = !riskManagementId || isLoading || isReadOnly ? 'disabled' : 'primary';

      if (!riskManagementId || !actionId || isLoading || isReadOnly) {
        return (
          <IconButton
            size="small"
            className={props?.className || ''}
            disabled
          >
            {isHidden ? <Visibility color={iconColor} /> : <VisibilityOff color={iconColor} />}
          </IconButton>
        );
      }

      return (
        <IconButton
          size="small"
          className={props?.className || ''}
          onClick={() => onToggleShipChipRiskVisibility({ ...(action || {}), isHidden: !!isHidden })}
        >
          {isHidden ? <Visibility color={iconColor} /> : <VisibilityOff color={iconColor} />}
        </IconButton>
      );
    },
    [visibilityUpdating, onToggleShipChipRiskVisibility, riskManagementId, isReadOnly]
  );
  const EmptyResults = useCallback(() => {
    if (actions.length > 0) {
      return null;
    }

    if (isShowingHiddenActions) {
      return (
        <>
          <Typography>No Hidden Actions found</Typography>
          <ReloadMessage />
        </>
      );
    }
    return (
      <>
        {actionState === 'ongoing' ? (
          <Typography>No Ongoing Actions found</Typography>
        ) : (
          <Typography>No Completed Actions found</Typography>
        )}
        <ReloadMessage />
      </>
    );
  }, [actions, actionState, updateRequestData, ReloadMessage]);
  const rowIterator = useCallback(
    ({ actions: actionsArr, action, i }: RowIteratorParams<RequireInT & T>) => {
      const actionId = (action as unknown as RequireInT & T).id;
      const renderPropBefore = ({ action: actionProp, column }: any) => {
        if (!column) {
          return null;
        }
        const reorderStatePayloadActionId = reorderRequest.payload?.actionId;
        if (column.id === 'displayId' && reorderStatePayloadActionId && reorderStatePayloadActionId === actionId) {
          // if reordering in-flight!
          return <DotIndicator className="border-2px-as-background-color" />;
        }
        if (column.id === 'actions') {
          return <ActionVisibilityIcon action={actionProp} />;
        }
        return null;
      };
      const isDragDisabled = isReorderingLocked || isReadOnly ? true : false;
      const dragDisabledClass = isDragDisabled ? 'dragging-is-disabled' : '';
      // using css to hide to maintain re-ordering functionality
      let visibilityClass = '';
      // non hidden view
      if (!isShowingHiddenActions && action?.isHidden) {
        visibilityClass = 'hidden-row'; // hide hidden items
      }
      //hidden view
      if (isShowingHiddenActions && !action?.isHidden) {
        visibilityClass = 'hidden-row'; //hide non hidden items
      }
      const accVisible = (actionsArr || []).filter((item, j) => {
        if (j >= i) {
          return false;
        }
        if (!item?.isHidden) {
          return true;
        }
        return false;
      }).length;
      const accHidden = (actionsArr || []).filter((item, j) => {
        if (j >= i) {
          return false;
        }
        if (item?.isHidden) {
          return true;
        }
        return false;
      }).length;
      const prommaticNthStyleClass =
        (!isShowingHiddenActions && accVisible % 2 !== 0) || (isShowingHiddenActions && accHidden % 2 !== 0)
          ? 'programmatic-odd-nth-cell-style'
          : 'programmatic-even-nth-cell-style';
      const isNewRowCss = action?.isNew ? 'highlight-row' : '';
      if (isShowingHiddenActions) {
        return {
          renderPropBefore,
          renderPropAfter: EmptyRender,
          rowCssClass: `control-disabled ${dragDisabledClass} ${visibilityClass} ${isNewRowCss} ${prommaticNthStyleClass}`,
          isDragDisabled: true,
        };
      }
      if (isBackgroundLoading) {
        return {
          renderPropBefore,
          renderPropAfter: EmptyRender,
          rowCssClass: `${dragDisabledClass} ${visibilityClass} ${isNewRowCss} ${prommaticNthStyleClass}`,
          isDragDisabled: true,
        };
      }
      return {
        renderPropBefore,
        renderPropAfter: EmptyRender,
        rowCssClass: `${dragDisabledClass} ${visibilityClass} ${isNewRowCss} ${prommaticNthStyleClass}`,
        isDragDisabled,
      };
    },
    [
      isBackgroundLoading,
      staleDataState,
      actionState,
      ongoingActions,
      completedActions,
      isShowingHiddenActions,
      isReorderingLocked,
      reorderRequest,
      ActionVisibilityIcon,
      isReadOnly,
    ]
  );
  const rowHeaderIterator = useCallback((params: RowHeaderPayload) => ({ ...params, width: '132px' }), []);

  return {
    rowHeaderIterator,
    rowIterator,
    EmptyResults,
    ActionVisibilityIcon,
    NewMessageNotification,
    ActionLoadingStatus,
    ReloadMessage,
    LoadingElement,
    SeeHiddenLink,
  };
};
