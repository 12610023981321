import Page from '../../../../components/Page';
import ScheduleCalendar from './ScheduleCalendar';
import WODetailsModal from '../../components/WODetailsModal';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import { useSchedule } from '../../hooks/useSchedule';
import { useWorkOrders } from '../../hooks/useWorkOrders';
import { Grid } from '@material-ui/core';
import {
  getPriorityOptions,
  getUnitOptions,
  getWorkGroupOptions,
  getWorkTypeOptions,
  getWorkorderOptions,
  getLocationOptions,
} from '../../wo/components/utils';
import { FilterEntityType } from '../../bookmarks/hook/useBookmarks';
import { BookmarkMenu } from '../../bookmarks/BookmarkMenu';
import isFeatureEnabled from '../../../../utils/feature-flags';
import AutocompleteFilterInput from '../../components/AutocompleteFilterInput';
interface Props {
  displayCalendar: boolean;
  setDisplayCalendar: (displayCalendar: boolean) => void;
}
const Schedule = ({ displayCalendar, setDisplayCalendar }: Props) => {
  const { filters, setFilters } = useSchedule();
  const { workorders, selectedWorkorderId, getWorkorderById } = useWorkOrders();
  return (
    <Page title="Schedule">
      <Grid
        container
        spacing={2}
        style={{ marginLeft: '10px', marginBottom: '10px' }}
      >
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Work Order"
            options={getWorkorderOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, wonum: value });
            }}
            value={filters.wonum}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Unit"
            options={getUnitOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, unit: value });
            }}
            value={filters.unit}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Priority"
            options={getPriorityOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, wopriority: value });
            }}
            value={filters.wopriority}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Work Type"
            options={getWorkTypeOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, worktype: value });
            }}
            value={filters.worktype}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Crew"
            options={getWorkGroupOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, crewworkgroup: value });
            }}
            value={filters.crewworkgroup}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteFilterInput
            label="Location"
            options={getLocationOptions(workorders)}
            onChange={value => {
              setFilters({ ...filters, location: value });
            }}
            value={filters.location}
            multiple
            selectAll
            allowInvalidValuesInOptions
          />
        </Grid>
      </Grid>
      {isFeatureEnabled('cwtBookmarks') && (
        <Grid xs={3}>
          <BookmarkMenu
            type={FilterEntityType.ScheduleFilter}
            filter={filters}
            setFilters={setFilters}
          />
        </Grid>
      )}
      <WODetailsModalContextProvider
        workorder={getWorkorderById(selectedWorkorderId)}
        onClose={() => {
          setDisplayCalendar(true);
        }}
      >
        <WODetailsModal />
        {displayCalendar && <ScheduleCalendar setDisplayCalendar={setDisplayCalendar} />}
      </WODetailsModalContextProvider>
    </Page>
  );
};
export default Schedule;
