import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Scorecard } from '@repo/echo-scorecard';
import * as scorecardActions from '../../state/scorecard/actions';
import * as scorecardSelectors from '../../state/scorecard/selectors';
import isFeatureEnabled from '../../utils/feature-flags';
import { getUserId } from '../../auth/utils';
import {
  type ApiRequestRow,
  type HttpEventDetail,
  defaultHttpResponseCompare,
  useIndexedDbCache,
  indexedDbHandler,
} from '../../client-db';

import {
  type ScorecardErrorMessage,
  type ScorecardStaleData,
  type ScorecardParams,
  type StoreListenerParams,
} from './types';
import { cleanEventUrl, getDisplayableScorecard } from './utils';
import scorecardKey from '../../state/scorecard/keys';
import { formatDistance } from 'date-fns';
import { Typography } from '@material-ui/core';

type ScorecardStatusState = {
  scorecardError: string;
  cachedValue: Scorecard | null;
};

const unitsFromScorecard = R.compose<any, any, any, any, any, any>(
  R.sort(R.comparator(R.lt)),
  R.uniq,
  R.flatten,
  R.map(R.keys),
  R.values
);
type BackgroundLoadingMessageParams = { isBackgroundLoading?: boolean | null; lastUpdatedAt?: Date | null };
const backgroundHanldedProps = ({
  isLoading,
  scorecard,
  staleData,
  scorecardStatus,
}: {
  isLoading?: boolean;
  scorecard?: Scorecard;
  staleData?: ScorecardStaleData;
  scorecardStatus: ScorecardStatusState;
}) => {
  let isBackgroundLoading = null;
  let scorecardDisplayable = null;
  if (isFeatureEnabled('scorecardOptimizations')) {
    if (isFeatureEnabled('echoClientCaching')) {
      isBackgroundLoading = false;
    }

    if (getDisplayableScorecard([scorecard])) {
      scorecardDisplayable = scorecard;
    } else if (getDisplayableScorecard([staleData])) {
      scorecardDisplayable = staleData;
    }

    if (isFeatureEnabled('echoClientCaching')) {
      const { cachedValue } = scorecardStatus;
      // can't trust isLoading as if store is undefined the selector defaults to false
      if (scorecardDisplayable && cachedValue && !isLoading) {
        isBackgroundLoading = scorecardDisplayable === cachedValue;
      }
      if (scorecardDisplayable && isLoading) {
        isBackgroundLoading = true;
      }
    }
  }
  return { scorecardDisplayable, isBackgroundLoading };
};
const RenderNull = () => null;
const BackgroundLoadingMessage = ({ isBackgroundLoading, lastUpdatedAt }: BackgroundLoadingMessageParams) =>
  isFeatureEnabled('echoClientCaching') && isBackgroundLoading && lastUpdatedAt ? (
    <Typography>
      The Scorecard Data displayed is a cached version retrieved
      {` ${formatDistance(lastUpdatedAt, new Date(), { addSuffix: true })}`}
    </Typography>
  ) : null;
const useScorecard = ({ elementId, elementName, year, quarter }: ScorecardParams) => {
  const dispatch = useDispatch();
  const updateHttpAction = useCallback(
    (detail: HttpEventDetail) => {
      const { data: scorecard } = detail;
      if (!scorecard) {
        return;
      }
      dispatch(scorecardActions.loadScorecardSuccess(elementId, `${year}`, `${quarter}`, scorecard));
    },
    [dispatch, elementId, year, quarter]
  );
  const updatedCacheAction = useCallback(
    (dbRow?: ApiRequestRow) => {
      const scorecard = dbRow?.response;
      if (!scorecard) {
        return;
      }
      dispatch(scorecardActions.loadScorecardSuccess(elementId, `${year}`, `${quarter}`, scorecard as Scorecard));
    },
    [dispatch, elementId, year, quarter]
  );
  const uriPath = `/api/v1/auth/elements/${elementId}/periods/${year}/${quarter}/scorecard`;
  const { getCache, updateCache } = useIndexedDbCache({
    cleanEventUrl,
    userId: getUserId(),
    uriPath,
    updateHttpAction,
    updatedCacheAction,
  });
  const [scorecardStatus, setScorecardStatus] = useState<ScorecardStatusState>({
    scorecardError: '',
    cachedValue: null,
  });

  useEffect(() => {
    if (!isFeatureEnabled('echoClientCaching')) {
      return;
    }
    if (scorecardStatus.cachedValue) {
      return;
    }
    getCache().then(result => {
      const { response: cachedValue } = result || {};
      if (!cachedValue) {
        return;
      }
      setScorecardStatus(prev => ({ ...prev, cachedValue }));
    });
  }, [scorecardStatus, setScorecardStatus]);

  const loadScorecard = useCallback(() => {
    dispatch(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  }, [dispatch, elementId, year, quarter]);
  const hydrateStaleData = useCallback(
    (staleData: any, cacheLastModified: Date) => {
      dispatch(scorecardActions.scorecardHydrateFromCache(elementId, year, quarter, staleData, cacheLastModified));
    },
    [dispatch, elementId, year, quarter]
  );
  const currentElementId = useSelector(state => scorecardSelectors.getCurrentElementId(state));
  const scorecardError: Error = useSelector(state => scorecardSelectors.getError(state, elementId, year, quarter));
  const scorecardErrorMessage: ScorecardErrorMessage = useSelector(state =>
    scorecardSelectors.getErrorMessage(state, elementId, year, quarter)
  );

  useEffect(() => {
    // capture feature-flag status in non-realtime here
    const isScorecardOptFlagEnabledLocal = isFeatureEnabled('scorecardOptimizations');
    if (isScorecardOptFlagEnabledLocal) {
      if (elementId === currentElementId && scorecardError && scorecardErrorMessage) {
        // UI Pop-Up Error
        dispatch(
          scorecardActions.scorecardPublishError(
            elementName,
            scorecardError,
            elementName
              ? `Error loading scorecard data for '${elementName}'\n${scorecardErrorMessage}`
              : `Error loading scorecard data\n${scorecardErrorMessage}`
          )
        );
        setScorecardStatus(prev => ({ ...prev, scorecardError: scorecardErrorMessage }));
      } else if (elementId !== currentElementId) {
        setScorecardStatus(prev => ({ ...prev, scorecardError: '' }));
      }
    }
    return () => {
      // display the error only once
      if (isScorecardOptFlagEnabledLocal && elementId === currentElementId && scorecardError && scorecardErrorMessage) {
        dispatch(scorecardActions.scorecardErrorClear(elementId, year, quarter));
      }
    };
  }, [dispatch, currentElementId, scorecardErrorMessage, scorecardError, elementId, year, quarter]);

  useEffect(() => {
    if (!isFeatureEnabled('echoClientCaching')) {
      loadScorecard();
      return;
    }
    let lastState: Scorecard | null = null;

    const storeListener = ({ storeState, cachedValue }: StoreListenerParams) => {
      const contextKey = scorecardKey(elementId, year, quarter);
      const currentScorecard = storeState?.scorecard ?? null;
      const currentState = currentScorecard && contextKey in currentScorecard ? currentScorecard[contextKey] : null;
      // object matched do nothing
      if (currentState === lastState) {
        // notify of no update - don't update if maybe the db emptied the row
        return;
      }
      lastState = currentState || lastState;
      const scorecard = getDisplayableScorecard([currentState.scorecard, currentState.staleData]);
      // Is it valid displayable?!
      if (!scorecard) {
        // notify of no update - don't update with incorrect data
        return;
      }
      const cachedValueResponse = cachedValue?.response as ApiRequestRow['response'];
      // something is displayable should be provide the update if there is no dbRow
      if (!cachedValueResponse && scorecard) {
        return { current: scorecard, update: scorecard as ApiRequestRow['response'] };
      }
      // check the stale data as the main data will be updated by the update action and/or http update
      if (defaultHttpResponseCompare(currentState.staleData, cachedValueResponse)) {
        return;
      }
      const update = getDisplayableScorecard([currentState.scorecard, currentState.staleData, cachedValueResponse]);
      // is the 'displayed data' the same as the cache we don't need an update
      if (defaultHttpResponseCompare(update, cachedValueResponse)) {
        // let notify the current data is correct but no update
        return { current: scorecard };
      }
      // update is needed against current!
      return { current: scorecard, update: update as ApiRequestRow['response'] };
    };
    const { hydrate, unlisten } = indexedDbHandler({
      getCache,
      updateCache,
      uriPath,
      storeListener,
    });

    hydrate()
      .then(result => {
        if (!result) {
          loadScorecard();
          return;
        }
        const { response: staleData, lastUpdated } = result;
        hydrateStaleData(staleData, lastUpdated);
        loadScorecard();
      })
      .catch((error: Error) => {
        console.error('Unable to load from index-db?! Error:', error);
        loadScorecard();
      });

    return () => {
      unlisten();
    };
  }, [loadScorecard, hydrateStaleData, uriPath, getCache, updateCache]);

  const scorecard: Scorecard = useSelector(state =>
    scorecardSelectors.getScorecard(state, elementId, year, quarter)
  ) as Scorecard;
  const staleData = useSelector(state =>
    scorecardSelectors.getStaleData(state, elementId, year, quarter)
  ) as ScorecardStaleData;
  const scorecardUnits = unitsFromScorecard(scorecard as any);
  const lastUpdatedAt = useSelector(state => scorecardSelectors.lastUpdatedAt(state, elementId, year, quarter));

  const isLoading = useSelector(state => scorecardSelectors.isLoading(state, elementId, year, quarter));
  const { scorecardDisplayable, isBackgroundLoading } = backgroundHanldedProps({
    isLoading,
    scorecard,
    staleData,
    scorecardStatus,
  });
  const BackgroundLoadingMessageComponent = useCallback(
    () => (
      <BackgroundLoadingMessage
        lastUpdatedAt={lastUpdatedAt}
        isBackgroundLoading={isBackgroundLoading}
      />
    ),
    [lastUpdatedAt, isBackgroundLoading]
  );
  if (isFeatureEnabled('scorecardOptimizations')) {
    const displayableScorecardErrorMessage =
      typeof scorecardErrorMessage === 'string' && scorecardErrorMessage.length > 0
        ? scorecardErrorMessage
        : scorecardStatus.scorecardError;
    return {
      scorecard,
      scorecardDisplayable,
      scorecardUnits,
      isLoading,
      isBackgroundLoading,
      lastUpdatedAt,
      loadScorecard,
      scorecardError,
      scorecardErrorMessage: displayableScorecardErrorMessage,
      staleData,
      BackgroundLoadingMessage: BackgroundLoadingMessageComponent,
    };
  }
  return {
    scorecard,
    scorecardDisplayable: scorecard && !R.isEmpty(scorecard) ? scorecard : null,
    scorecardUnits,
    isLoading,
    isBackgroundLoading,
    lastUpdatedAt,
    loadScorecard,
    BackgroundLoadingMessage: RenderNull,
  };
};

export default useScorecard;
